import { IWorkflowBillingStore, WorkflowClientActions } from "../types/stores/workflowClient";

const defaultState: IWorkflowBillingStore = {
    billingWFCode: [],
    billingJobNumber: [],
    IFRSCategories: [],
    isLoading: false,
};

export const GET_WFBILLINGWFCODE = "GET_WFBILLINGWFCODE";
export const GET_WFBILLINGJOBNUMBER = "GET_WFBILLINGJOBNUMBER";
export const GET_WFIFRSCATEGORIES = "GET_WFIFRSCATEGORIES";
export const SET_WFBILLING_IS_LOADING = "SET_WFBILLING_IS_LOADING";

function workflowBillingData(state = defaultState, action: WorkflowClientActions): IWorkflowBillingStore {
    switch (action.type) {
        case GET_WFBILLINGWFCODE:
            return Object.assign({}, state, {
                billingWFCode: action.billingWFCode,
            });
        case GET_WFBILLINGJOBNUMBER:
            return Object.assign({}, state, {
                billingJobNumber: action.billingJobNumber,
            });
        case GET_WFIFRSCATEGORIES:
            return Object.assign({}, state, {
                IFRSCategories: action.IFRSCategories,
            });
        case SET_WFBILLING_IS_LOADING:
            return Object.assign({}, state, {
                isLoading: action.isLoading,
            });
        default:
            return state;
    }
}
export default workflowBillingData;
