import { ICachedDataStore, CachedDataActions } from "../types/stores/cachedData";

export const PROCESSING_CACHED_ITEMS = "PROCESSING_CACHED_ITEMS";
export const CURRENT_CACHED_ITEMS = "CURRENT_CACHED_ITEMS";

export function cachedData(
    state: ICachedDataStore = {
        cachedDataIsThinking: false,
        currentCachedItems: [],
    },
    action: CachedDataActions
): ICachedDataStore {
    switch (action.type) {
        case PROCESSING_CACHED_ITEMS:
            return Object.assign({}, state, {
                cachedDataIsThinking: action.isThinking,
            });
        case CURRENT_CACHED_ITEMS:
            return Object.assign({}, state, {
                currentCachedItems: action.currentCachedItems,
            });
        default:
            return state;
    }
}
export default cachedData;
