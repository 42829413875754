import {
    ISetLoadingUsers,
    ISetUserIsSaving,
    IUpdateUserAdminShowInternal,
    IUpdateUserAdminCompanyIDFilter,
    IUpdateRoleListFilter,
    IGetUserRoles,
    IUserRole,
    IUpdateUserListFilter,
} from "../types/stores/userAdmin";
import {
    SET_IS_LOADING_USERS,
    SET_IS_SAVING_USER,
    UPDATE_USER_ADMIN_SHOW_INTERNAL,
    UPDATE_USER_ADMIN_COMPANYID_FILTER,
    UPDATE_ROLE_LIST_FILTER,
    GET_USER_ROLES,
    UPDATE_USER_LIST_FILTER,
} from "../reducers/userAdmin";

export const SetUsersIsLoading = (newValue: boolean): ISetLoadingUsers => ({
    type: SET_IS_LOADING_USERS,
    isLoading: newValue,
});

export const SetUserIsSaving = (newValue: boolean): ISetUserIsSaving => ({
    type: SET_IS_SAVING_USER,
    isSaving: newValue,
});

export const updateUserAdminShowInternal = (newValue: boolean): IUpdateUserAdminShowInternal => ({
    type: UPDATE_USER_ADMIN_SHOW_INTERNAL,
    showInternal: newValue,
});

export const updateUserAdminCompanyIdFilter = (newValue: number): IUpdateUserAdminCompanyIDFilter => ({
    type: UPDATE_USER_ADMIN_COMPANYID_FILTER,
    companyId: newValue,
});

export const updateRoleListFilter = (newValue: string): IUpdateRoleListFilter => ({
    type: UPDATE_ROLE_LIST_FILTER,
    role: newValue,
});

export const SetUserRoles = (newValue: Array<IUserRole>): IGetUserRoles => ({
    type: GET_USER_ROLES,
    userRoles: newValue,
});

export const updateUserListFilter = (newValue: Array<string>): IUpdateUserListFilter => ({
    type: UPDATE_USER_LIST_FILTER,
    filter: newValue,
});
