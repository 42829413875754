import toDate from "date-fns/toDate";
import parseISO from "date-fns/parseISO";
import format from "date-fns/format";
import Moment from "moment-timezone";

// Emulate the behavior of date-fns "toDate()" before version v2.0.0-alpha.27
// See http://gitlab.solutiondesign.experian.local/sdt/oap/issues/1698
// and https://github.com/date-fns/date-fns/issues/1016

export const parseDate = (input: string | Date | number): Date => {
    if (typeof input == "string") {
        return parseISO(input);
    }
    return toDate(input);
};

export const parseDateFromJson = (input: Moment.MomentInput, timeZone: string, isoFormat = false): string => {
    let localDate = dateToTimeZone(input, timeZone);

    if (typeof input == "string") {
        let date = new Date(parseInt(input.substr(6)));
        let userTimezoneOffset = date.getTimezoneOffset() * 60000;
        let dateNoTimeZone = new Date(date.getTime() - userTimezoneOffset);

        localDate = dateToTimeZone(dateNoTimeZone, timeZone);
    }

    return isoFormat ? localDate : format(parseDate(localDate), "MM/dd/yyyy hh:mm a");
};

export const parseDateToTimeZone = (input: Moment.MomentInput, timeZone: string): Date =>
    parseDate(dateToTimeZone(input, timeZone));

export const parseDateToTimeZoneWithTime = (input: Moment.MomentInput, timeZone: string): string =>
    format(parseDate(dateToTimeZone(input, timeZone)), "MM/dd/yyyy hh:mm a");

export const parseDateToTimeZoneDateOnly = (
    input: Moment.MomentInput,
    timeZone: string,
    formatTemplate = "MM/dd/yyyy"
): string => format(parseDate(dateToTimeZone(input, timeZone)), formatTemplate);

export const dateToTimeZone = (input: Moment.MomentInput, timeZone: string): string => {
    const localDate = Moment.utc(input).tz(timeZone).format("YYYY-MM-DDTHH:mm:ss");

    return localDate;
};
