import prettyCron from "prettycron";
import { ISchedule } from "../../../types/stores/schedules";
import { ScheduleType } from "../../../enums/schedules";

export const getSchedulePrettyName = (schedule: ISchedule): string => {
    let prettyName = "";

    switch (schedule.ScheduleType) {
        case ScheduleType.TableRefresh:
            prettyName = `Table Refresh`;
            break;
        case ScheduleType.ExperianAssetRefresh:
            prettyName = `Experian Assets Update`;
            break;
        case ScheduleType.TimeFrequency:
            prettyName = getSchedulePrettyNameForTimeFrequency(schedule);
            break;
        default:
            prettyName = prettyCron.toString(schedule.CronString);
            break;
    }

    if (!schedule.Name.includes(prettyName)) {
        prettyName = `${schedule.Name} (${prettyName})`;
    }
    return prettyName;
};

export const getSchedulePrettyNameForTimeFrequency = (schedule: ISchedule): string => {
    const { TimeType, TimeCount } = schedule;
    if (!TimeType || !TimeCount) {
        return "";
    }

    const typeString = ["", "day", "week", "month"][TimeType];
    const countString = TimeCount > 1 ? TimeCount : "";

    return `Every ${countString} ${typeString}${TimeCount > 1 ? "s" : ""}`;
};

export const hourOptions = Array.from({ length: 24 }, (_, i) => i.toString());

export const minuteOptions = Array.from({ length: 60 }, (_, i) => i.toString());
