import { goToUrl } from "./actionCreators";
import { FlowBaseTypes } from "../helpers/constants";

import {
    newFlow,
    newFlowItem,
    newFlowCase,
    newFlowRelation,
    updateMultipleAttribute,
    setSelectedFlowItemId,
    newFlowGuideSetting,
    deleteAllItemsInFlow,
    deleteFlow,
} from "./flowActions";
import { push } from "connected-react-router";
import { genericQbAddField } from "./fieldTreeActions";

export const ADD_TO_DATACONNECT_SELECTED_FIELDS = "ADD_TO_DATACONNECT_SELECTED_FIELDS";
export const ADD_TO_DATACONNECT_SELECTED_FIELDNAMES = "ADD_TO_DATACONNECT_SELECTED_FIELDNAMES";
export const REMOVE_ALL_FROM_DATACONNECT_SELECTED_FIELDS = "REMOVE_ALL_FROM_DATACONNECT_SELECTED_FIELDS";
export const REMOVE_ALL_FROM_DATACONNECT_SELECTED_FIELDNAMES = "REMOVE_ALL_FROM_DATACONNECT_SELECTED_FIELDNAMES";
export const REMOVE_FROM_DATACONNECT_SELECTED_FIELDS = "REMOVE_FROM_DATACONNECT_SELECTED_FIELDS";
export const REMOVE_FROM_DATACONNECT_SELECTED_FIELDNAMES = "REMOVE_FROM_DATACONNECT_SELECTED_FIELDNAMES";
export const SET_SELECTED_DATACONNECT_FIELD_ID = "SET_SELECTED_DATACONNECT_FIELD_ID";
export const SET_SELECTED_DATACONNECT_FIELD_NAME = "SET_SELECTED_DATACONNECT_FIELD_NAME";
export const SET_QUICKSTART_MODEL = "SET_QUICKSTART_MODEL";
export const SET_PRESELECTED_FILTERS = "SET_PRESELECTED_FILTERS";
export const fieldsFetchInMinutes = 30;

export const CreateFlowAndCase = () => (dispatch, getState) => {
    const state = getState();
    const parentId = 0;
    let childId = 0;
    const x = 100;
    const y = 500;

    const selectedFields = state.dataConnect.selectedFields;
    if (state.flows.byId[-1] != null) {
        dispatch(
            updateMultipleAttribute("flows", -1, {
                FlowName: "New Audience",
                FlowBaseType: FlowBaseTypes.Undecided,
                isSplitEnabled: false,
            })
        );
    } else {
        dispatch(newFlow("New Audience", FlowBaseTypes.Undecided));
    }

    if (state.flowGuideSettings.byId[-1] != null) {
        dispatch(
            updateMultipleAttribute("flowGuideSettings", -1, {
                FlowId: -1,
                IsSplitOn: false,
            })
        );
    } else {
        dispatch(newFlowGuideSetting(-1, false));
    }

    dispatch(newFlowItem((newId: number) => (childId = newId), "New Audience", "case", { x, y }));
    dispatch(newFlowCase(childId, false, false));
    dispatch(newFlowRelation(parentId, childId));

    dispatch(RemoveAllFromDataConnectSelectedFields());
    dispatch(setSelectedFlowItemId(-1));
    dispatch(push(`/flows/-1/item/-1`));
    for (const field of selectedFields) {
        dispatch(genericQbAddField(field.toString(), -1));
        // dispatch({
        //     type: "GENERIC_QB_ADD_FIELD",
        //     field,
        //     fieldData,
        //     0,
        //     0,
        // });
    }
};
export const CreateFlowAndFilter = () => (dispatch, getState) => {
    const state = getState();
    const parentId = 0;
    let childId = 0;
    const x = 100;
    const y = 500;

    const selectedFields = state.dataConnect.selectedFields;

    if (state.flows.byId[-1] != null) {
        dispatch(deleteAllItemsInFlow(-1));
        dispatch(deleteFlow(-1));
    }
    dispatch(newFlow("New Audience", FlowBaseTypes.Undecided));

    if (state.flowGuideSettings.byId[-1] != null) {
        dispatch(
            updateMultipleAttribute("flowGuideSettings", -1, {
                FlowId: -1,
                IsSplitOn: true,
            })
        );
    } else {
        dispatch(newFlowGuideSetting(-1, true));
    }

    for (const field of selectedFields) {
        dispatch(genericQbAddField(field.toString()));
    }
    dispatch(RemoveAllFromDataConnectSelectedFields());

    // NANI-83103 - Start a brand new simple flow
    setTimeout(() => {
        // Wait for dispatch(deleteAllItemsInFlow(-1));
        dispatch(newFlowItem((newId: number) => (childId = newId), "New Audience", "filter", { x, y }));
        dispatch(newFlowRelation(parentId, childId));

        dispatch(setSelectedFlowItemId(-1));
        dispatch(push(`/flows/-1/item/${childId}`));
    }, 100);
};

export const QuickStartModel = data => (dispatch, getState) => {
    const state = getState();
    const fieldsByFolder = state.fields.byFolder || {};
    const filters = Object.values(fieldsByFolder).filter(x => !x.isLeaf);
    const companyId = state.session.companyId;

    const firstParty = filters
        .filter(x => x.parent == "#" && x.companyid == companyId && x.text == "First-Party Data")
        .map(s => s.id);
    const preselectedFilters = [];
    firstParty.forEach(id => {
        const models = filters.filter(x => x.parent == id && (x.companyid == companyId && x.text) == "Models");
        models.forEach(x => preselectedFilters.push(x));
    });

    dispatch(SetPreselectedFilters(preselectedFilters));
    dispatch(SetQuickStartModel(data)); //contains the selected model's row from model status
    dispatch(goToUrl("/connect"));
};

export const SetSelectedDataConnectFieldId = fieldId => ({
    type: SET_SELECTED_DATACONNECT_FIELD_ID,
    fieldId,
});

export const SetSelectedDataConnectFieldName = fieldName => ({
    type: SET_SELECTED_DATACONNECT_FIELD_NAME,
    fieldName,
});

export const AddToDataConnectSelectedFields = fieldIdToAdd => ({
    type: ADD_TO_DATACONNECT_SELECTED_FIELDS,
    fieldIdToAdd,
});

export const AddToDataConnectSelectedFieldNames = fieldNameToAdd => ({
    type: ADD_TO_DATACONNECT_SELECTED_FIELDNAMES,
    fieldNameToAdd,
});

export const RemoveAllFromDataConnectSelectedFields = () => ({
    type: REMOVE_ALL_FROM_DATACONNECT_SELECTED_FIELDS,
});

export const RemoveAllFromDataConnectSelectedFieldNames = () => ({
    type: REMOVE_ALL_FROM_DATACONNECT_SELECTED_FIELDNAMES,
});

export const RemoveFromDataConnectSelectedFields = fieldIdToRemove => ({
    type: REMOVE_FROM_DATACONNECT_SELECTED_FIELDS,
    fieldIdToRemove,
});

export const RemoveFromDataConnectSelectedFieldNames = fieldNameToRemove => ({
    type: REMOVE_FROM_DATACONNECT_SELECTED_FIELDNAMES,
    fieldNameToRemove,
});

export const SetQuickStartModel = model => ({
    type: SET_QUICKSTART_MODEL,
    model,
});

export const SetPreselectedFilters = preselectedFilters => ({
    type: SET_PRESELECTED_FILTERS,
    preselectedFilters,
});
