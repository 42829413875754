//This is a custom material dashboard and replaces material-dashboard-react.js

// ##############################
// // // Function that converts from hex color to rgb color
// // // Example: input = #9c27b0 => output = 156, 39, 176
// // // Example: input = 9c27b0 => output = 156, 39, 176
// // // Example: input = #999 => output = 153, 153, 153
// // // Example: input = 999 => output = 153, 153, 153
// #############################
export const hexToRgb = input => {
    input = input + "";
    input = input.replace("#", "");
    let hexRegex = /[0-9A-Fa-f]/g;
    if (!hexRegex.test(input) || (input.length !== 3 && input.length !== 6)) {
        throw new Error(input + " input is not a valid hex color.");
    }
    if (input.length === 3) {
        let first = input[0];
        let second = input[1];
        let last = input[2];
        input = first + first + second + second + last + last;
    }
    input = input.toUpperCase(input);
    let first = input[0] + input[1];
    let second = input[2] + input[3];
    let last = input[4] + input[5];
    return parseInt(first, 16) + ", " + parseInt(second, 16) + ", " + parseInt(last, 16);
};

export const ColorLuminance = (hex, lum) => {
    // validate hex string
    hex = String(hex).replace(/[^0-9a-f]/gi, "");
    if (hex.length < 6) {
        hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
    }
    lum = lum || 0;

    // convert to decimal and change luminosity
    let rgb = "#",
        c,
        i;
    for (i = 0; i < 3; i++) {
        c = parseInt(hex.substr(i * 2, 2), 16);
        c = Math.round(Math.min(Math.max(0, c + c * lum), 255)).toString(16);
        rgb += ("00" + c).substr(c.length);
    }

    return rgb;
};

// ##############################
// // // Variables - Styles that are used on more than one component
// #############################

export const drawerWidth = 260;

export const transition = {
    transition: "all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1)",
};

export const container = {
    paddingRight: "15px",
    paddingLeft: "15px",
    marginRight: "auto",
    marginLeft: "auto",
};

export const defaultFont = {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontWeight: "300",
    lineHeight: "1.5em",
};

export const darkBlue = ["#26478d", "#516ca4", "#7d91bb", "#a8b6d1"];
export const lightBlue = ["#406eb3", "#668bc2", "#8ca8d1", "#b3c5e1"];
export const violet = ["#632678", "#825193", "#a17dae", "#c0a8c9"];
export const purple = ["#982881", "#ad539a", "#c17eb3", "#d6a9cd"];
export const pink = ["#ba2f7d", "#ca5997", "#d682b1", "#e3accb"];
export const grey = ["#575756", "#797978", "#9a9a9a", "#bcbcbb"];
export const red = ["#bb0048", "#c8326c", "#d66691", "#e399b5"];
export const orange = ["#e2a235", "#b4812a", "#87611f", "#5a4015"];
export const yellow = ["#fde723", "#cab81c", "#978a15", "#655c0e"];
export const green = ["#c8c922", "#a0a01b", "#8c8c17", "#787814"];
export const sapphire = ["#3d87af", "#306c8c", "#245169", "#183646"];
export const seaGreen = ["#20b2aa", "#198e88", "#136a66", "#0c4744"];
export const limeGreen = ["#74c921", "#5ca01a", "#457813", "#2e500d"];
export const grayColor = [
    "#999",
    "#777",
    "#3C4858",
    "#AAAAAA",
    "#D2D2D2",
    "#DDD",
    "#b4b4b4",
    "#555555",
    "#333",
    "#a9afbb",
    "#eee",
    "#e7e7e7",
];

export const blackColor = "#000";
export const whiteColor = "#FFF";

export const colorPicker = color => {
    switch (color) {
        case "darkBlue":
            return darkBlue[1];
        case "lightBlue":
            return lightBlue[1];
        case "violet":
            return violet[1];
        case "purple":
            return purple[1];
        case "pink":
            return pink[1];
        case "grey":
            return grey[1];
        case "red":
            return red[0];
        case "orange":
            return orange[0];
        case "yellow":
            return yellow[0];
        case "green":
            return green[0];
        case "sapphire":
            return sapphire[0];
        case "seaGreen":
            return seaGreen[0];
        case "limeGreen":
            return limeGreen[0];
        case "black":
            return blackColor;
        case "white":
            return whiteColor;
        case "lightGrey":
            return grey[3];
        default:
            return grayColor[1];
    }
};

export const boxShadow = {
    boxShadow:
        "0 10px 30px -12px rgba(" +
        hexToRgb(blackColor) +
        ", 0.42), 0 4px 25px 0px rgba(" +
        hexToRgb(blackColor) +
        ", 0.12), 0 8px 10px -5px rgba(" +
        hexToRgb(blackColor) +
        ", 0.2)",
};

export const darkBlueBoxShadow = {
    boxShadow:
        "0 4px 20px 0 rgba(" + hexToRgb(blackColor) + ",.14), 0 7px 10px -5px rgba(" + hexToRgb(darkBlue[0]) + ",.4)",
};

export const lightBlueBoxShadow = {
    boxShadow:
        "0 4px 20px 0 rgba(" + hexToRgb(blackColor) + ",.14), 0 7px 10px -5px rgba(" + hexToRgb(lightBlue[0]) + ",.4)",
};
export const violetBoxShadow = {
    boxShadow:
        "0 4px 20px 0 rgba(" + hexToRgb(blackColor) + ",.14), 0 7px 10px -5px rgba(" + hexToRgb(violet[0]) + ",.4)",
};
export const purpleBoxShadow = {
    boxShadow:
        "0 4px 20px 0 rgba(" + hexToRgb(blackColor) + ",.14), 0 7px 10px -5px rgba(" + hexToRgb(purple[0]) + ",.4)",
};
export const pinkBoxShadow = {
    boxShadow:
        "0 4px 20px 0 rgba(" + hexToRgb(blackColor) + ",.14), 0 7px 10px -5px rgba(" + hexToRgb(pink[0]) + ",.4)",
};
export const greyBoxShadow = {
    boxShadow:
        "0 4px 20px 0 rgba(" + hexToRgb(blackColor) + ",.14), 0 7px 10px -5px rgba(" + hexToRgb(grey[0]) + ",.4)",
};
export const redBoxShadow = {
    boxShadow: "0 4px 20px 0 rgba(" + hexToRgb(blackColor) + ",.14), 0 7px 10px -5px rgba(" + hexToRgb(red[0]) + ",.4)",
};
export const orangeBoxShadow = {
    boxShadow:
        "0 4px 20px 0 rgba(" + hexToRgb(blackColor) + ",.14), 0 7px 10px -5px rgba(" + hexToRgb(orange[0]) + ",.4)",
};
export const yellowBoxShadow = {
    boxShadow:
        "0 4px 20px 0 rgba(" + hexToRgb(blackColor) + ",.14), 0 7px 10px -5px rgba(" + hexToRgb(yellow[0]) + ",.4)",
};
export const greenBoxShadow = {
    boxShadow:
        "0 4px 20px 0 rgba(" + hexToRgb(blackColor) + ",.14), 0 7px 10px -5px rgba(" + hexToRgb(green[0]) + ",.4)",
};
export const sapphireBoxShadow = {
    boxShadow:
        "0 4px 20px 0 rgba(" + hexToRgb(blackColor) + ",.14), 0 7px 10px -5px rgba(" + hexToRgb(sapphire[0]) + ",.4)",
};
export const seaGreenBoxShadow = {
    boxShadow:
        "0 4px 20px 0 rgba(" + hexToRgb(blackColor) + ",.14), 0 7px 10px -5px rgba(" + hexToRgb(seaGreen[0]) + ",.4)",
};
export const limeGreenBoxShadow = {
    boxShadow:
        "0 4px 20px 0 rgba(" + hexToRgb(blackColor) + ",.14), 0 7px 10px -5px rgba(" + hexToRgb(limeGreen[0]) + ",.4)",
};

export const darkBlueCardHeader = {
    background: "linear-gradient(60deg, " + darkBlue[0] + ", " + darkBlue[1] + ")",
    ...darkBlueBoxShadow,
};
export const lightBlueCardHeader = {
    background: "linear-gradient(60deg, " + lightBlue[0] + ", " + lightBlue[1] + ")",
    ...lightBlueBoxShadow,
};
export const violetCardHeader = {
    background: "linear-gradient(60deg, " + violet[0] + ", " + violet[1] + ")",
    ...violetBoxShadow,
};
export const purpleCardHeader = {
    background: "linear-gradient(60deg, " + purple[0] + ", " + purple[1] + ")",
    ...purpleBoxShadow,
};
export const pinkCardHeader = {
    background: "linear-gradient(60deg, " + pink[0] + ", " + pink[1] + ")",
    ...pinkBoxShadow,
};
export const greyCardHeader = {
    background: "linear-gradient(60deg, " + grey[2] + ", " + grey[3] + ")",
    ...greyBoxShadow,
};
export const redCardHeader = {
    background: "linear-gradient(60deg, " + red[0] + ", " + red[1] + ")",
    ...redBoxShadow,
};
export const orangeCardHeader = {
    background: "linear-gradient(60deg, " + orange[0] + ", " + orange[1] + ")",
    ...orangeBoxShadow,
};
export const yellowCardHeader = {
    background: "linear-gradient(60deg, " + yellow[0] + ", " + yellow[1] + ")",
    ...yellowBoxShadow,
};
export const greenCardHeader = {
    background: "linear-gradient(60deg, " + green[0] + ", " + green[1] + ")",
    ...greenBoxShadow,
};
export const sapphireCardHeader = {
    background: "linear-gradient(60deg, " + sapphire[0] + ", " + sapphire[1] + ")",
    ...sapphireBoxShadow,
};
export const seaGreenCardHeader = {
    background: "linear-gradient(60deg, " + seaGreen[0] + ", " + seaGreen[1] + ")",
    ...seaGreenBoxShadow,
};
export const limeGreenCardHeader = {
    background: "linear-gradient(60deg, " + limeGreen[0] + ", " + limeGreen[1] + ")",
    ...limeGreenBoxShadow,
};

export const cardActions = {
    margin: "0 20px 10px",
    paddingTop: "10px",
    borderTop: "1px solid " + grayColor[10],
    height: "auto",
    ...defaultFont,
};

export const cardHeader = {
    margin: "-20px 15px 0",
    borderRadius: "3px",
    padding: "15px",
};

export const card = {
    display: "inline-block",
    position: "relative",
    width: "100%",
    margin: "25px 0",
    boxShadow: "0 1px 4px 0 rgba(" + hexToRgb(blackColor) + ", 0.14)",
    borderRadius: "3px",
    color: "rgba(" + hexToRgb(blackColor) + ", 0.87)",
    background: whiteColor,
};

export const defaultBoxShadow = {
    border: "0",
    borderRadius: "3px",
    boxShadow:
        "0 10px 20px -12px rgba(" +
        hexToRgb(blackColor) +
        ", 0.42), 0 3px 20px 0px rgba(" +
        hexToRgb(blackColor) +
        ", 0.12), 0 8px 10px -5px rgba(" +
        hexToRgb(blackColor) +
        ", 0.2)",
    padding: "10px 0",
    transition: "all 150ms ease 0s",
};

export const title = {
    color: grayColor[2],
    textDecoration: "none",
    fontWeight: "300",
    marginTop: "30px",
    marginBottom: "25px",
    minHeight: "32px",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    "& small": {
        color: grayColor[1],
        fontWeight: "400",
        lineHeight: "1",
    },
};

export const cardTitle = {
    ...title,
    marginTop: "0",
    marginBottom: "3px",
    minHeight: "auto",
    "& a": {
        ...title,
        marginTop: ".625rem",
        marginBottom: "0.75rem",
        minHeight: "auto",
    },
};

export const cardSubtitle = {
    marginTop: "-.375rem",
};

export const cardLink = {
    "& + $cardLink": {
        marginLeft: "1.25rem",
    },
};
