import update from "immutability-helper";
import clone from "../helpers/clone";
import { createSelector } from "reselect";
import { IFeaturesStore, FeaturesActions } from "../types/stores/feature";
import { IAppState } from "../types/stores";

export const UPDATE_FEATURES_TITLE = "UPDATE_FEATURES_TITLE";
export const UPDATE_SELECTED_FEATURE_ID = "UPDATE_SELECTED_FEATURE_ID";
export const UPDATE_FEATURES_APP_MODE = "UPDATE_FEATURES_APP_MODE";
export const FEATURE_LIST_LOAD_STARTED = "FEATURE_LIST_LOAD_STARTED";
export const FEATURE_LIST_LOAD_FINISHED = "FEATURE_LIST_LOAD_FINISHED";
export const UPDATE_FEATURE_LIST = "UPDATE_FEATURE_LIST";
export const FEATURE_USER_LIST_LOAD_STARTED = "FEATURE_USER_LIST_LOAD_STARTED";
export const FEATURE_USER_LIST_LOAD_FINISHED = "FEATURE_USER_LIST_LOAD_FINISHED";
export const UPDATE_FEATURE_USER_LIST = "UPDATE_FEATURE_USER_LIST";
export const UPDATE_FEATURE_LIST_USER_SELECTION = "UPDATE_FEATURE_LIST_USER_SELECTION";
export const SET_COMPANIES = "SET_COMPANIES";
export const SET_COMPANY_FEATURES = "SET_COMPANY_FEATURES";
export const SET_COMPANY_NEW_SELECTED_FEATURES = "SET_COMPANY_NEW_SELECTED_FEATURES";
export const SET_FEATURES = "SET_FEATURES";

function features(
    state: IFeaturesStore = {
        title: "",
        featureId: null,
        featureList: [],
        mode: "list",
        userList: [],
        isLoading: false,
        loadingUsers: false,
        companies: [],
        features: [],
        companyNewSelectedFeatures: Array<string>(),
        companyFeatures: [],
    },
    action: FeaturesActions
): IFeaturesStore {
    switch (action.type) {
        case UPDATE_FEATURES_TITLE: {
            return update(state, {
                title: { $set: action.title },
            });
        }
        case UPDATE_SELECTED_FEATURE_ID: {
            return update(state, {
                featureId: { $set: action.featureId },
            });
        }
        case UPDATE_FEATURES_APP_MODE: {
            return update(state, {
                mode: { $set: action.mode },
            });
        }
        case FEATURE_LIST_LOAD_STARTED: {
            return update(state, {
                isLoading: { $set: true },
            });
        }
        case FEATURE_LIST_LOAD_FINISHED: {
            return update(state, {
                isLoading: { $set: false },
            });
        }
        case UPDATE_FEATURE_LIST: {
            return update(state, {
                featureList: { $set: action.featureList },
            });
        }
        case FEATURE_USER_LIST_LOAD_STARTED: {
            return update(state, {
                loadingUsers: { $set: true },
            });
        }
        case FEATURE_USER_LIST_LOAD_FINISHED: {
            return update(state, {
                loadingUsers: { $set: false },
            });
        }
        case UPDATE_FEATURE_USER_LIST: {
            return update(state, {
                userList: { $set: action.userList },
            });
        }
        case UPDATE_FEATURE_LIST_USER_SELECTION: {
            const newState = clone(state);
            newState.userList = newState.userList.map(item => {
                if (item.Id != action.userId) {
                    return item;
                }
                return {
                    ...item,
                    HasFeature: action.hasFeature,
                };
            });
            return newState;
        }
        case SET_COMPANIES: {
            return update(state, {
                companies: { $set: action.companies },
            });
        }
        case SET_COMPANY_FEATURES: {
            return update(state, {
                companyFeatures: { $set: action.companyFeatures },
            });
        }
        case SET_COMPANY_NEW_SELECTED_FEATURES: {
            return update(state, {
                companyNewSelectedFeatures: { $set: action.companyNewSelectedFeatures },
            });
        }
        case SET_FEATURES: {
            return update(state, {
                features: { $set: action.features },
            });
        }
        default:
            return state;
    }
}
export default features;

export const getExternalFeatures = createSelector(
    (state: IAppState) => state.features.featureList,
    features => features.filter(f => f.IsExternal)
);

export const getInternalFeatures = createSelector(
    (state: IAppState) => state.features.featureList,
    features => features.filter(f => !f.IsExternal)
);

export const getClientFacingFeatures = createSelector(
    (state: IAppState) => state.features.featureList,
    features => features.filter(f => f.IsClientFacing)
);

export const getUserOnlyFeatures = createSelector(
    (state: IAppState) => state.features.featureList,
    features => features.filter(f => f.IsUserOnly)
);
