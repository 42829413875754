import { IWorkflowClientStore, WorkflowClientActions } from "../types/stores/workflowClient";

const defaultState: IWorkflowClientStore = {
    clientsData: [],
    isLoading: false,
};

export const SET_WORKFLOWCLIENT_IS_LOADING = "SET_WORKFLOWCLIENT_IS_LOADING";
export const GET_WORKFLOWCLIENT = "GET_WORKFLOWCLIENT";

function workflowClientData(state = defaultState, action: WorkflowClientActions): IWorkflowClientStore {
    switch (action.type) {
        case GET_WORKFLOWCLIENT:
            return Object.assign({}, state, {
                clientsData: action.clientsData,
            });
        case SET_WORKFLOWCLIENT_IS_LOADING:
            return Object.assign({}, state, {
                isLoading: action.isLoading,
            });
        default:
            return state;
    }
}
export default workflowClientData;
