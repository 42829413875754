import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { IDistributionListStore, IDistributionEmail } from "../types/stores/distributionList";

const defaultState: IDistributionListStore = {
    id: null,
    distributionList: [],
    mode: "list",
    isLoading: false,
    validEmailAddress: true,
    superAdminMode: false,
    companyId: null,
};

//refactor distributionList to use redux toolkit createSlice
export const slice = createSlice({
    name: "distributionList",
    initialState: defaultState,
    reducers: {
        updateDistributionListMode: (state, action: PayloadAction<string>) => {
            state.mode = action.payload;
        },
        distributionListLoadStarted: state => {
            state.isLoading = true;
        },
        distributionListLoadFinished: state => {
            state.isLoading = false;
        },
        updateDistributionList: (state, action: PayloadAction<Array<IDistributionEmail>>) => {
            state.distributionList = action.payload;
        },
        updateDistributionListId: (state, action: PayloadAction<number | null>) => {
            state.id = action.payload;
        },
        updateValidEmailAddress: (state, action: PayloadAction<boolean>) => {
            state.validEmailAddress = action.payload;
        },
        setSuperAdminMode: (state, action: PayloadAction<boolean>) => {
            state.superAdminMode = action.payload;
        },
        setCompanyId: (state, action: PayloadAction<number | null>) => {
            state.companyId = action.payload;
        },
    },
});

export default slice.reducer;
export const {
    updateDistributionListMode,
    distributionListLoadStarted,
    distributionListLoadFinished,
    updateDistributionList,
    updateDistributionListId,
    updateValidEmailAddress,
    setSuperAdminMode,
    setCompanyId,
} = slice.actions;
