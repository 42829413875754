import { AdminFeaturesAuditLogActions, IAdminFeaturesAuditLogStore } from "../types/stores/featureAuditLogStore";

export const GET_ADMIN_FEATURESAUDITLOG_ACTION_LIST = "GET_ADMIN_FEATURESAUDITLOG_ACTION_LIST";
export const IS_ADMIN_FEATURESAUDITLOG_ACTION_LOADING = "IS_ADMIN_FEATURESAUDITLOG_ACTION_LOADING";

const initialState: IAdminFeaturesAuditLogStore = {
    auditLogResults: [],
    isLoading: false,
};

function featuresAuditLog(state = initialState, action: AdminFeaturesAuditLogActions): IAdminFeaturesAuditLogStore {
    switch (action.type) {
        case GET_ADMIN_FEATURESAUDITLOG_ACTION_LIST:
            return Object.assign({}, state, {
                auditLogResults: action.auditLogResults,
            });
        case IS_ADMIN_FEATURESAUDITLOG_ACTION_LOADING:
            return Object.assign({}, state, {
                isLoading: action.isLoading,
            });
        default:
            return state;
    }
}

export default featuresAuditLog;
