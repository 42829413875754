export const UPDATE_COMPANY_IMAGES = "UPDATE_COMPANY_IMAGES";
export const GET_COMPANY_LOGO = "GET_COMPANY_LOGO";

export type UpdateCompanyImagesAction = {
    type: typeof UPDATE_COMPANY_IMAGES;
    hasUpdated: boolean;
};

export type GetCompanyLogoAction = {
    type: typeof GET_COMPANY_LOGO;
    logo: string;
};

export type ActionType = UpdateCompanyImagesAction | GetCompanyLogoAction;

export const NotifyUpdateCompanyImages = (hasUpdatedNew: boolean): UpdateCompanyImagesAction => ({
    type: UPDATE_COMPANY_IMAGES,
    hasUpdated: hasUpdatedNew,
});
export const GetCompanyLogo = (companyId: number): GetCompanyLogoAction => ({
    type: GET_COMPANY_LOGO,
    logo: `/Images/CompanyLogo/${companyId}`,
});
