//import update from "immutability-helper";
import { IAdminDesignerStore, AdminDesignerActions } from "../../types/stores/adminDesigner";

// States for admin pages with designer tools
export const TOGGLE_FIELD_EDIT_LISTING = "TOGGLE_FIELD_EDIT_LISTING";
export const TOGGLE_FIELD_EDIT_LISTING_PINNABLE = "TOGGLE_FIELD_EDIT_LISTING_PINNABLE";
export const SET_PROPERTY = "SET_ADMIN_DESIGNER_PROPERTY";

export const AdminDesignerProperties = {
    fieldLabels: "fieldLabels",
    fieldLabels3: "fieldLabels3",
    fieldVisibilityTypes: "fieldVisibilityTypes",
    fieldVisibilityTypesDefault: "fieldVisibilityTypesDefault",
    isLoadingFieldVisibilityTypesDefault: "isLoadingFieldVisibilityTypesDefault",
    fieldVisibilityFilter: "fieldVisibilityFilter",
    companyUsers: "companyUsers",
    isUpdateMetaData: "isUpdateMetaData",
    isUpdateFieldClassification: "isUpdateFieldClassification",
    overwriteTreeVisibility: "overwriteTreeVisibility",
    overwriteFieldLabels: "overwriteFieldLabels",
    setIsMetaDataRefresh: "setIsMetaDataRefresh",
    overwriteUserVisibility: "overwriteUserVisibility",
    visibleAllUsers: "visibleAllUsers",
    selectedFieldVisibilityTypes: "selectedFieldVisibilityTypes",
    selectedFieldVisibilityTypeNames: "selectedFieldVisibilityTypeNames",
    selectedFieldLabels: "selectedFieldLabels",
    selectedFieldLabels3: "selectedFieldLabels3",
    selectedCompanyUsers: "selectedCompanyUsers",
    selectedCompanies: "selectedCompanies",
    isSaving: "isSaving",
    isEditFolder: "isEditFolder",
    isAddField: "isAddField",
    isRestrictedField: "isRestrictedField",
    umbrellaDataLevels: "umbrellaDataLevels",
    umbrellaDataTypes: "umbrellaDataTypes",
    setFieldDataType: "setFieldDataType",
    setSubTypeDropDown: "setSubTypeDropDown",
    setDecimals: "setDecimals",
    setFieldMetaDataKey: "setFieldMetaDataKey",
    fieldMetadata: "fieldMetadata",
    importedBulkFields: "importedBulkFields",
    cloneFieldLabels: "cloneFieldLabels",
    toCompany: "toCompany",
    setFieldClassification: "setFieldClassification",
};

function adminDesigner(
    state: IAdminDesignerStore = {
        isFieldEditListingOpen: true,
        isFieldEditListingPinned: true,
        fieldLabels: [],
        fieldLabels3: [],
        fieldVisibilityTypes: [],
        fieldVisibilityTypesDefault: [],
        isLoadingFieldVisibilityTypesDefault: false,
        fieldVisibilityFilter: [1],
        companyUsers: { 0: [] },
        isUpdateMetaData: false,
        isUpdateFieldClassification: false,
        overwriteTreeVisibility: false,
        overwriteFieldLabels: false,
        setIsMetaDataRefresh: false,
        overwriteUserVisibility: false,
        visibleAllUsers: [],
        selectedFieldVisibilityTypes: { 0: [] },
        selectedFieldVisibilityTypeNames: [],
        selectedFieldLabels: [],
        selectedFieldLabels3: [],
        selectedCompanyUsers: { 0: [] },
        selectedCompanies: [],
        isSaving: false,
        isEditFolder: false,
        isAddField: false,
        isRestrictedField: false,
        umbrellaDataLevels: [],
        umbrellaDataTypes: [],
        setFieldClassification: 0,
        setFieldDataType: "S",
        setSubTypeDropDown: "",
        setDecimals: 0,
        setFieldMetaDataKey: 0,
        fieldMetadata: [],
        importedBulkFields: [],
        cloneFieldLabels: false,
        toCompany: "0",
    },
    action: AdminDesignerActions
): IAdminDesignerStore {
    switch (action.type) {
        case TOGGLE_FIELD_EDIT_LISTING:
            return {
                ...state,
                isFieldEditListingOpen: action.isOpen,
            };
        case TOGGLE_FIELD_EDIT_LISTING_PINNABLE: {
            return Object.assign({}, state, {
                isFieldEditListingPinned: action.isPinned,
            });
        }
        case SET_PROPERTY: {
            if (action.property) {
                if (action.index != undefined) {
                    let data = { ...state[action.property] };
                    data[action.index] = action.newValue;
                    return { ...state, [action.property]: data };
                }
                return { ...state, [action.property]: action.newValue };
            }
            return state;
        }
        default:
            return state;
    }
}
export default adminDesigner;
