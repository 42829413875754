import h from "../helpers";
import { request } from "../helpers/httpInterceptor";
import { Dispatch } from "../types/types";
import { IUser } from "../types/stores/userAdmin";
import {
    IFeature,
    IUpdateFeatureList,
    IFeatureListLoadStarted,
    IFeatureListLoadFinished,
    IUpdateFeaturesAppMode,
    IUpdateSelectedFeatureId,
    IFeatureUserListLoadStarted,
    IFeatureUserListLoadFinished,
    IUpdateFeatureUserList,
    IUpdateFeaturesTitle,
    IUpdateFeatureListUserSelection,
    ICompanyFeature,
    ISetCompanies,
    ISetCompanyFeatures,
    ISetFeatures,
    ISetCompanyNewSelectedFeatures,
} from "../types/stores/feature";
import {
    UPDATE_FEATURES_TITLE,
    UPDATE_SELECTED_FEATURE_ID,
    UPDATE_FEATURES_APP_MODE,
    FEATURE_LIST_LOAD_STARTED,
    FEATURE_LIST_LOAD_FINISHED,
    UPDATE_FEATURE_LIST,
    FEATURE_USER_LIST_LOAD_STARTED,
    FEATURE_USER_LIST_LOAD_FINISHED,
    UPDATE_FEATURE_USER_LIST,
    UPDATE_FEATURE_LIST_USER_SELECTION,
    SET_COMPANIES,
    SET_COMPANY_FEATURES,
    SET_COMPANY_NEW_SELECTED_FEATURES,
    SET_FEATURES,
} from "../reducers/features";
import { sortArrayBy } from "../helpers/sorting";
import { ICompany } from "../types/stores/companyTable";
import { notifyGreen } from "./notifyActions";
import { SetCompanyIsLoadingFeatures } from "./companiesActions";

if (top && !top.RDX) {
    top.RDX = {};
}

export const requestFeatureList =
    () =>
    (dispatch: Dispatch): void => {
        dispatch(featureListLoadStarted());

        fetch("/Features/GetFeatures/", { credentials: "same-origin" })
            .then(h.checkStatus)
            .then(h.toJson)
            .then(response => {
                const data = response as unknown as Array<IFeature>;
                dispatch(updateFeatureList(data));
                dispatch(featureListLoadFinished());
            })
            .catch(error => {
                dispatch(featureListLoadFinished());
                h.error("Error loading feature list.", error);
            });
    };

export const requestFeatureUserList =
    (featureId: number) =>
    (dispatch: Dispatch): void => {
        dispatch(featureUserListLoadStarted());

        fetch("/features/GetUsers?" + h.serialize({ featureId }), { credentials: "same-origin" })
            .then(h.checkStatus)
            .then(h.toJson)
            .then(response => {
                const data = response as unknown as Array<IUser>;
                dispatch(updateFeatureUserList(data));
                dispatch(featureUserListLoadFinished());
            })
            .catch(error => {
                dispatch(featureUserListLoadFinished());
                h.error("Error loading user list.", error);
            });
    };

export const updateUserSelection =
    (featureId: number, userId: number, hasFeature: boolean) =>
    (dispatch: Dispatch): void => {
        const url = "/Features/UpdateUser?" + h.serialize({ featureId, userId, hasFeature });
        fetch(url, {
            method: "POST",
            credentials: "same-origin",
        })
            .then(h.checkStatus)
            .then(h.toJson)
            .then(response => {
                const data = response as unknown as { result: string };
                if (data.result && data.result == "success") {
                    dispatch(updateFeatureListUserSelection(userId, hasFeature));
                }
            });
    };

export const getAdminFeaturesData =
    () =>
    (dispatch: Dispatch): void => {
        dispatch(featureListLoadStarted());
        fetch("/Admin/Companies/FeaturesData", { credentials: "same-origin" })
            .then(h.checkStatus)
            .then(h.toJson)
            .then(data => {
                const rawData = data as unknown as {
                    companies: Array<ICompany>;
                    featureCompanies: Array<ICompanyFeature>;
                    features: Array<IFeature>;
                };

                dispatch(setCompanies(sortArrayBy(rawData.companies, "CompanyName")));
                dispatch(setFeatureCompanies(rawData.featureCompanies));
                dispatch(setFeatures(sortArrayBy(rawData.features, "Name")));
                dispatch(featureListLoadFinished());
            })
            .catch(error => {
                h.error("Couldn't load feature data.", error);
            });
    };

export const saveCompaniesFeatures =
    (featureCompaniesClient: Array<ICompanyFeature>) =>
    (dispatch: Dispatch): void => {
        fetch("/Admin/Companies/PushFeatures", {
            credentials: "same-origin",
            method: "POST",
            headers: { Accept: "application/json", "Content-Type": "application/json" },
            body: JSON.stringify({ featureCompaniesClient }),
        })
            .then(h.checkStatus)
            .then(h.toJson)
            .then(data => {
                const response = data as unknown as { result: boolean };
                if (response.result) {
                    dispatch(notifyGreen("Features updated successfully."));
                }
            });
    };

export const GetCompanyFeatures =
    (companyId?: number) =>
    (dispatch: Dispatch): Promise<Response> => {
        dispatch(SetCompanyIsLoadingFeatures(true));

        return request(
            "/Features/GetCompanyFeatures" + (companyId ? `/${companyId}` : ""),
            { credentials: "same-origin" },
            dispatch
        )
            .then(h.checkStatus)
            .then(h.toJson)
            .then(data => {
                let featuresData = [];
                if (data && data.allFeatures && data.allFeatures.length > 0) {
                    featuresData = data.allFeatures.map(x => ({
                        Name: x.Name,
                        Description: x.Description,
                        IsExternal: x.IsExternal,
                        Selected: data.companyFeatures
                            ? data.companyFeatures.filter(y => y.Name == x.Name).length > 0
                            : false,
                        IsUserOnly: x.IsUserOnly,
                    }));
                }
                dispatch({
                    type: SET_COMPANY_FEATURES,
                    features: featuresData,
                });
                dispatch(SetCompanyIsLoadingFeatures(false));
            })
            .catch(error => {
                h.error("Error loading Features.", error);
                dispatch(SetCompanyIsLoadingFeatures(false));
            });
    };

export const setCompanies = (companies: Array<ICompany>): ISetCompanies => ({
    type: SET_COMPANIES,
    companies,
});

export const setFeatureCompanies = (companyFeatures: Array<ICompanyFeature>): ISetCompanyFeatures => ({
    type: SET_COMPANY_FEATURES,
    companyFeatures,
});

export const setCompanyNewSelectedFeatures = (
    companyNewSelectedFeatures: Array<string>
): ISetCompanyNewSelectedFeatures => ({
    type: SET_COMPANY_NEW_SELECTED_FEATURES,
    companyNewSelectedFeatures,
});

export const setFeatures = (features: Array<IFeature>): ISetFeatures => ({
    type: SET_FEATURES,
    features,
});

export const updateFeatureList = (featureList: Array<IFeature>): IUpdateFeatureList => ({
    type: UPDATE_FEATURE_LIST,
    featureList,
});

export const featureListLoadStarted = (): IFeatureListLoadStarted => ({
    type: FEATURE_LIST_LOAD_STARTED,
});

export const featureListLoadFinished = (): IFeatureListLoadFinished => ({
    type: FEATURE_LIST_LOAD_FINISHED,
});

export const updateFeaturesAppMode = (mode: string): IUpdateFeaturesAppMode => ({
    type: UPDATE_FEATURES_APP_MODE,
    mode,
});

export const updateSelectedFeatureId = (featureId: number | null): IUpdateSelectedFeatureId => ({
    type: UPDATE_SELECTED_FEATURE_ID,
    featureId,
});

export const featureUserListLoadStarted = (): IFeatureUserListLoadStarted => ({
    type: FEATURE_USER_LIST_LOAD_STARTED,
});

export const featureUserListLoadFinished = (): IFeatureUserListLoadFinished => ({
    type: FEATURE_USER_LIST_LOAD_FINISHED,
});

export const updateFeatureUserList = (userList: Array<IUser>): IUpdateFeatureUserList => ({
    type: UPDATE_FEATURE_USER_LIST,
    userList,
});

export const updateFeaturesTitle = (title: string): IUpdateFeaturesTitle => ({
    type: UPDATE_FEATURES_TITLE,
    title,
});

export const updateFeatureListUserSelection = (
    userId: number,
    hasFeature: boolean
): IUpdateFeatureListUserSelection => ({
    type: UPDATE_FEATURE_LIST_USER_SELECTION,
    userId,
    hasFeature,
});
