import React from "react";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import * as actionCreators from "../../actions/actionCreators";
import * as fieldTreeActions from "../../actions/fieldTreeActions";
import withFieldDropTarget from "../../hocs/withFieldDropTarget";
import withGenericFieldDropTarget from "../../hocs/withGenericFieldDropTarget";
import { ConnectDropTarget } from "react-dnd";
import { IAppState } from "../../types/stores/index";

interface Props {
    // injected by react-dnd
    connectDropTarget?: ConnectDropTarget;
    isOver?: boolean;
    canDrop?: boolean;
    // passed
    type: string;
    wording: string;
    fieldIdChanged: (fieldId: number | string) => void;
    isDisabled: boolean;
    isGenericQbAdd?: boolean;
    // redux
    selectedFields?: Array<number>;
    pathname?: string;
}

// Drop Target from react-dnd needs React Component class - maybe we can change when we upgrade react dnd?
class FieldDropTarget extends React.Component<Props> {
    render() {
        const { connectDropTarget, isOver, type, wording, pathname, isDisabled } = this.props;

        const wordToUse = wording !== null ? wording : type;

        let className = "hidden-print dnd-target-striped";
        if (isOver) {
            className += " is-over";
        }

        let styles: React.CSSProperties = {
            margin: "0px auto",
            padding: "10px",
            border: "1px solid #ccc",
            borderRadius: "10px",
            width: "260px",
            textAlign: "center",
        };

        if (pathname && (pathname.includes("/flows") || pathname.includes("restricted-fields"))) {
            styles = {
                margin: "30px auto",
                padding: "10px",
                border: "1px solid rgb(204, 204, 204)",
                borderRadius: "9px",
                width: "40vw",
                height: "50px",
                textAlign: "center",
            };
        }

        if (isDisabled) {
            styles["opacity"] = 0.4;
        }

        styles["textTransform"] = "capitalize";

        const element = (
            <div className={className} style={styles}>
                Drag and Drop {wordToUse}s Here
            </div>
        );

        return isDisabled ? element : connectDropTarget!(element);
    }
}

const mapStateToProps = (state: IAppState) => ({
    // used by FieldDropTarget
    pathname: state.router && state.router.location && state.router.location.pathname,
    // used in the drop target wrapper for bulk selection
    selectedFields: state.selected.selectedFields || [],
});

const mapDispatchToProps = (dispatch: Dispatch) =>
    bindActionCreators(Object.assign({}, actionCreators, fieldTreeActions), dispatch);

// This version uses a simple callback to determine behavior, props.onFieldDropped = (fieldId) => ....
let FieldDropTargetGeneric = withGenericFieldDropTarget(FieldDropTarget);
FieldDropTargetGeneric = connect(mapStateToProps, mapDispatchToProps)(FieldDropTargetGeneric);
export { FieldDropTargetGeneric };

let FieldDropTargetConnect = withFieldDropTarget(FieldDropTarget);
FieldDropTargetConnect = connect(mapStateToProps, mapDispatchToProps)(FieldDropTargetConnect);
export default FieldDropTargetConnect;
