import { AdminUpdateAssetsActions, IAdminUpdateAssetsActionStore } from "../types/stores/updateExperianAssetsStore";

export const GET_ADMIN_UPDATEASSETS_ACTION_LIST = "GET_ADMIN_UPDATEASSETS_ACTION_LIST";
export const IS_ADMIN_UPDATEASSETS_ACTION_LOADING = "IS_ADMIN_UPDATEASSETS_ACTION_LOADING";
export const GET_ADMIN_UPDATEASSETS_ACTION = "GET_ADMIN_UPDATEASSETS_ACTION";
export const IS_UPDATING_ADMIN_UPDATEASSETS_ACTION = "IS_UPDATING_ADMIN_UPDATEASSETS_ACTION";
export const SET_ADMIN_UPDATEASSETS_ACTION = "SET_ADMIN_UPDATEASSETS_ACTION";

const initialState: IAdminUpdateAssetsActionStore = {
    actionDetail: {
        ActionLogId: 0,
        SourceDB: "",
        DestinationDB: "",
        TableName: "",
        Argument1: "",
        Success: true,
        RequestedBy: "",
        RequestedOn: "",
        CompletedOn: "",
        Description: "",
        Emails: "",
    },
    actionDetails: [],
    isLoading: false,
    isUpdating: false,
};

function updateExperianAssets(state = initialState, action: AdminUpdateAssetsActions): IAdminUpdateAssetsActionStore {
    switch (action.type) {
        case GET_ADMIN_UPDATEASSETS_ACTION_LIST:
            return Object.assign({}, state, {
                actionDetails: action.actionDetails,
            });
        case IS_ADMIN_UPDATEASSETS_ACTION_LOADING:
            return Object.assign({}, state, {
                isLoading: action.isLoading,
            });
        case GET_ADMIN_UPDATEASSETS_ACTION:
            return Object.assign({}, state, {
                actionDetail: action.actionDetail,
            });
        case IS_UPDATING_ADMIN_UPDATEASSETS_ACTION:
            return Object.assign({}, state, {
                isUpdating: action.isUpdating,
            });
        case SET_ADMIN_UPDATEASSETS_ACTION:
            return Object.assign({}, state, {
                actionDetail: action.actionDetail,
            });
        default:
            return state;
    }
}

export default updateExperianAssets;
