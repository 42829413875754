import makeReducerFor from "./_genericDbReducer";
import { createSelector } from "reselect";
import type { Role } from "../types/types";

const myGenericReducer = makeReducerFor("ROLE", "Id");
export default myGenericReducer;

export const getArrayOfAllRoles = createSelector(
    state => state.roles.byId,
    (rolesById: { [number]: Role }): Array<Role> => Object.values(rolesById)
);
