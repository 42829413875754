import { push } from "connected-react-router";
import h from "../helpers";
import { requestClientVariables } from "./clientVariableActions";
import { getFlowModelsArray } from "../reducers/flowModels";
import { updateAttribute, requestFlows, requestFlowDeploymentQueue } from "./flowActions";
import { ToggleIsLayoutTreeLoading } from "./treeBehaviorActions";
import { ToggleIsFlowTreeLoading } from "./treeBehaviorActions";
import { loadFolders, requestLoadFlowFolders } from "./audienceFolderActions";
import { GetFieldLabelTypes } from "./fieldLabelsActions";
import { getAvailableFolders, getColumnsForTable, getImports, getJoins, getModes } from "./fileImportActions";
import { setAllSelectedLayoutObjects, requestAllLayoutObjects } from "./exportLayoutActions2";
import { notifyGreen } from "./notifyActions";
import { request } from "../helpers/httpInterceptor";
import { uniqArray } from "../helpers/typedHelpers";
if (!top.RDX) {
    top.RDX = {};
}

// This is a generic action to call a given uri and expect a file in return
// Used by resource center to download files and show the loading bar
export const requestDownload = (uri: string, fileName: string) => dispatch => {
    request(uri, { method: "GET", credentials: "same-origin", responseType: "blob" }, dispatch)
        .then(h.checkStatus)
        .then(response => response.blob())
        .then(blob => {
            // Create blob link to download
            const url = window.URL.createObjectURL(new Blob([blob]));
            const anchorLink = document.createElement("a");
            anchorLink.href = url;
            if (!fileName.includes(".pdf")) fileName += ".xlsx";
            anchorLink.setAttribute("download", fileName);

            // Append to html anchorLink element page
            document.body.appendChild(anchorLink);

            // Start download
            anchorLink.click();

            // Clean up and remove the anchorLink
            anchorLink.parentNode?.removeChild(anchorLink);
        })
        .catch(error => {
            h.error("Error downloading file.", error);
        });
};

// This is an action to run if the user decided to do whatever prompted
// the loss of work dialog.
// Put whatever the user was trying to do in the loss_of_work_queued_action variable
// before showing the dialog.
export const lossOfWorkRunQueuedAction = () => (dispatch, getState) => {
    const nextAction = getState().vars.loss_of_work_queued_action;
    dispatch(setVar("loss_of_work_queued_action", {}));
    if (nextAction && (nextAction.type || typeof nextAction == "function")) {
        dispatch(nextAction);
    }
};

// This is an action to run if the user decided not to go through with whatever change prompted
// the loss of work dialog.
// We use this for changing the company dropdown back to its original value if the user decides
// not to change companies.
export const lossOfWorkRunCanceledQueuedAction = () => (dispatch, getState) => {
    const nextAction = getState().vars.loss_of_work_canceled_queued_action;
    dispatch(setVar("loss_of_work_canceled_queued_action", {}));
    if (nextAction && (nextAction.type || typeof nextAction == "function")) {
        dispatch(nextAction);
    }
};

export const setPage = (page = []) => ({
    type: "SET_PAGE",
    page,
});
top.RDX.setPage = page => top.store.dispatch(setPage(page));

export const siteCustomization = (custName: string, defaultValue: string) => (dispatch, getState) => {
    const state = getState();
    let customizations = state.session.customizations;
    let output = defaultValue;
    let customValue = customizations.filter(x => x.Name == custName);
    if (customValue.length > 0 && customValue[0].Value != "") {
        output = customValue[0].Value;
    }
    return output;
};
top.RDX.siteCustomization = (custName, defaultValue) => top.store.dispatch(siteCustomization((custName, defaultValue)));

export const dateDiffMinutes = (startDate, endDate) => {
    const diff = endDate.getTime() - startDate.getTime();
    return Math.abs(diff / 60000);
};

const _premiumLastLoadedByCompany = {};

export const setPremiumFields = (premiumFields = {}) => ({
    type: "SET_PREMIUMFIELDS",
    premiumFields,
});
top.RDX.setPremiumFields = premiumFields => top.store.dispatch(setPremiumFields(premiumFields));

// Changes per company
export const requestPremiumFields = () => (dispatch, getState) => {
    const companyId = getState().session.companyId;
    if (
        _premiumLastLoadedByCompany[companyId] != null &&
        dateDiffMinutes(_premiumLastLoadedByCompany[companyId], new Date()) < 30
    ) {
        return;
    }

    dispatch(incAjaxCount());
    dispatch(ToggleIsLayoutTreeLoading(true));

    fetch("/Tree/Premium", { credentials: "same-origin" })
        .then(h.checkStatus)
        .then(h.toJson)
        .then(data => {
            dispatch(setPremiumFields(data));
            top.premiumFields = data;
            dispatch(decAjaxCount());
            _premiumLastLoadedByCompany[companyId] = new Date();
            dispatch(ToggleIsLayoutTreeLoading(false));
        })
        .catch(error => {
            dispatch(decAjaxCount());
            dispatch(ToggleIsLayoutTreeLoading(false));
            h.error("Error loading premium fields.", error);
        });
};
top.RDX.requestPremiumFields = () => top.store.dispatch(requestPremiumFields());

export const setVar = (variable = "", value = null) => ({
    type: "SET_VAR",
    variable,
    value,
});
top.RDX.setVar = (variable, value) => top.store.dispatch(setVar(variable, value));

export const setVarPersist = (variable = "", value = null) => ({
    type: "SET_VAR_PERSIST",
    variable,
    value,
});

Set.prototype.difference = function (setB) {
    // put this somewhere better?
    const difference = new Set(this);
    for (const elem of setB) {
        difference.delete(elem);
    }
    return difference;
};

//// SELECTED REPORT ////
export const setDataSource = dataSource => ({
    type: "SET_SELECTED_DATASOURCE",
    dataSource,
});
top.RDX.setDataSource = dataSource => top.store.dispatch(setDataSource(dataSource));

export const clearDataSource = () => ({
    type: "CLEAR_SELECTED_DATASOURCE",
});
top.RDX.clearDataSource = () => top.store.dispatch(clearDataSource());

export const requestQueryBuilderGroupList = (segmentKey, groupType) => dispatch => {
    request(
        "/QueryBuilder/getQueryBuilderGroups?segmentKey=" + segmentKey + "&groupType=" + groupType,
        {
            credentials: "same-origin",
        },
        dispatch
    )
        .then(h.checkStatus)
        .then(h.toJson)
        .then(data => {
            dispatch(updateQueryBuilderGroupList(data.queryBuilderGroups));
        })
        .catch(error => {
            h.error("Error loading querybuilder group list.", error);
        });
};
top.RDX.requestQueryBuilderGroupList = (segmentKey, groupType) =>
    top.store.dispatch(requestQueryBuilderGroupList(segmentKey, groupType));

export const updateQueryBuilderGroupList = queryBuilderGroups => ({
    type: "UPDATE_QUERYBUILDER_GROUP_LIST",
    queryBuilderGroups,
});
top.RDX.updateQueryBuilderGroupList = queryBuilderGroups =>
    top.store.dispatch(updateQueryBuilderGroupList(queryBuilderGroups));

export const requestUserNames =
    (userIds = [], mergeBehavior = "merge") =>
    (dispatch, getState) => {
        const userIdsSeen = new Set(userIds);

        let userIdsKnown;
        if (mergeBehavior == "overwrite") {
            userIdsKnown = new Set();
        } else {
            userIdsKnown = new Set(Object.keys(getState().varsPersist.userNameForId).map(x => parseInt(x)));
        }

        const userIdsToRequest = Array.from(userIdsSeen.difference(userIdsKnown));
        dispatch(getUserNames(userIdsToRequest));
    };

export const updateJSSourceLoadStatus = (source, status) => ({
    type: "UPDATE_JSSOURCE_LOAD_STATUS",
    source,
    status,
});

// Modals

export const showModal = (modalType, modalProps = {}) => ({
    type: "SHOW_MODAL",
    modalType,
    modalProps,
});
top.RDX.showModal = (modalType, modalProps = {}) => top.store.dispatch(showModal(modalType, modalProps));

export const hideModal = modalType => ({
    type: "HIDE_MODAL",
    modalType,
});
top.RDX.hideModal = modalType => top.store.dispatch(hideModal(modalType));

// Flows Model Edit

export const requestModelFilenames = () => dispatch => {
    const getModelFilenamesUrl = `/AudienceModel/GetModelFilenames`;
    dispatch(incAjaxCount());
    return fetch(getModelFilenamesUrl, { credentials: "same-origin" })
        .then(h.checkStatus)
        .then(h.toJson)
        .then(modelFilenames => {
            dispatch({ type: "SET_MODEL_FILENAMES", modelFilenames });
            dispatch(decAjaxCount());
        })
        .catch(error => {
            dispatch(decAjaxCount());
            h.error("Error getting Model Filenames.", error);
        });
};

export const setUpdatingModelBundles = updatingModelBundles => ({
    type: "SET_UPDATING_MODEL_BUNDLES",
    updatingModelBundles,
});

export const requestModelBundles = () => dispatch => {
    const url = "/AudienceModel/GetModelBundles" + h.serialize({});
    return fetch(url, { method: "POST", credentials: "same-origin" })
        .then(h.checkStatus)
        .then(h.toJson)
        .then(data => {
            const modelBundles = data.modelBundles.map(item => ({
                label: item.BundleName || "None",
                value: item.ModelBundleId,
                companyId: item.CompanyId,
                createdOn: item.CreatedOn,
                deletedOn: item.DeletedOn,
                CanDelete: item.CanDelete,
            }));

            dispatch({ type: "SET_MODEL_BUNDLES", modelBundles });
            dispatch(decAjaxCount());
            dispatch(setUpdatingModelBundles(false));
        })
        .catch(error => {
            h.error("Error loading Model Bundles.", error);
        });
};

export const requestModelVariables = () => dispatch => {
    const url = "/AudienceModel/GetModelVariables" + h.serialize({});
    return fetch(url, { method: "POST", credentials: "same-origin" })
        .then(h.checkStatus)
        .then(h.toJson)
        .then(data => {
            const modelVariables = data.modelVariables.map(item => ({
                label: item.FriendlyName || "None",
                value: item.VariableName,
                tooltip: item.Description,
            }));

            dispatch({ type: "SET_MODEL_VARIABLES", modelVariables });
        })
        .catch(error => {
            h.error("Error loading Model Variables.", error);
        });
};

export const requestModelExcludedVariables = () => dispatch => {
    const url = "/AudienceModel/GetModelExcludedVariables" + h.serialize({});
    return fetch(url, { method: "POST", credentials: "same-origin" })
        .then(h.checkStatus)
        .then(h.toJson)
        .then(data => {
            const modelExcludedVariables = data.modelExcludedVariables;

            dispatch({ type: "SET_MODEL_VARIABLES_EXCLUDED", modelExcludedVariables });
        })
        .catch(error => {
            h.error("Error loading Model Variables.", error);
        });
};

export const saveModelBundle = (bundleName, excludedVariables, isSetGlobal) => dispatch => {
    const url = "/AudienceModel/SaveModelBundle";
    dispatch(incAjaxCount());
    dispatch(setUpdatingModelBundles(true));

    return fetch(url, {
        credentials: "same-origin",
        method: "POST",
        headers: {
            "Content-Type": "application/json; charset=utf-8",
        },
        body: JSON.stringify({
            bundleName,
            excludedVariables,
            isSetGlobal,
        }),
    })
        .then(h.checkStatus)
        .then(h.toJson)
        .then(data => {
            if (data.success) {
                dispatch(requestModelBundles());
                dispatch(requestModelExcludedVariables());
            }
        })
        .catch(error => {
            h.error("Error saving bundle.", error);
            dispatch(decAjaxCount());
            dispatch(setUpdatingModelBundles(false));
        });
};

export const deleteModelBundle = bundleId => (dispatch, getState) => {
    const state = getState();
    const url = "/AudienceModel/DeleteModelBundle";
    dispatch(incAjaxCount());
    dispatch(setUpdatingModelBundles(true));
    return fetch(url, {
        credentials: "same-origin",
        method: "POST",
        headers: {
            "Content-Type": "application/json; charset=utf-8",
        },
        body: JSON.stringify({
            bundleId,
        }),
    })
        .then(h.checkStatus)
        .then(h.toJson)
        .then(data => {
            if (data.success) {
                dispatch(requestModelBundles());
                dispatch(requestModelExcludedVariables());

                // Reset flowModles bundle values to 0 if it was deleted
                const flowModels = getFlowModelsArray(state);
                flowModels
                    .filter(x => x.Bundle == bundleId)
                    .forEach(model => {
                        dispatch(updateAttribute("flowModels", model.FlowModelId, "Bundle", 0));
                    });
            }
        })
        .catch(error => {
            h.error("Error deleting bundle.", error);
            dispatch(decAjaxCount());
            dispatch(setUpdatingModelBundles(false));
        });
};

// Migrations

export const setMigrationThinking = isthinking => ({
    type: "SET_MIGRATION_THINKING",
    isthinking,
});

export const UpdateMigrationTables = () => dispatch => {
    const url = "/Flows/UpdateMigrationTables";
    dispatch(incAjaxCount());
    dispatch(setMigrationThinking(true));
    return fetch(url, { method: "POST", credentials: "same-origin" })
        .then(h.checkStatus)
        .then(h.toJson)
        .then(() => {
            dispatch(decAjaxCount());
            dispatch(setMigrationThinking(false));
        })
        .catch(error => {
            dispatch(decAjaxCount());
            dispatch(setMigrationThinking(false));
            h.error("Error loading Model Bundles.", error);
        });
};

export const setUserNames = map => ({
    type: "SET_USER_NAMES",
    map,
});

export const getUserNames =
    (userIds = []) =>
    dispatch => {
        if (userIds.length == 0) {
            return;
        }

        const url = "/Main/GetUserNames";
        dispatch(incAjaxCount());
        return fetch(url, {
            credentials: "same-origin",
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                userIds,
            }),
        })
            .then(h.checkStatus)
            .then(h.toJson)
            .then(data => {
                const missingIds = userIds.filter(x => !Object.keys(data).includes(x.toString())); // IDs we asked about but didn't get anything back
                for (const missingId of missingIds) {
                    data[missingId] = null;
                }
                dispatch(setUserNames(data));
                dispatch(decAjaxCount());
            })
            .catch(error => {
                dispatch(decAjaxCount());
                h.error("Error looking up user name.", error);
            });
    };

// Companies that my user has access to
// Usually shouldn't be needed - the session sends this information automatically
// on each page load.  Maybe only needed when logging in via ajax?
export const requestMyCompanies = () => dispatch => {
    dispatch(incAjaxCount());
    fetch("/Account/GetCompanyList", { credentials: "same-origin" })
        .then(h.checkStatus)
        .then(h.toJson)
        .then(data => {
            dispatch({
                type: "SET_SESSION_COMPANIES",
                companies: data,
            });
            dispatch(setVar("companies", data));
            dispatch(decAjaxCount());
        })
        .catch(error => {
            dispatch(decAjaxCount());
            h.error("Error loading companies.", error);
        });
};

export const requestMyCompaniesAndAssets = () => dispatch => {
    dispatch(incAjaxCount());
    fetch("/Account/GetCompanyList", { credentials: "same-origin" })
        .then(h.checkStatus)
        .then(h.toJson)
        .then(data => {
            dispatch({
                type: "SET_SESSION_COMPANIES",
                companies: data,
            });
            dispatch(setVar("companies", data));
            dispatch(decAjaxCount());
        })
        .catch(error => {
            dispatch(decAjaxCount());
            h.error("Error loading companies.", error);
        });
};

import { setCompanyId, requestSessionInfo } from "./sessionActions";
export const requestChangeCompany = (newCompanyId, callbackAfterChangeCompany) => dispatch => {
    // Locally
    dispatch(setCompanyId(newCompanyId));

    const url = "/Account/ChangeCompany";
    dispatch(incAjaxCount());
    fetch(url, {
        credentials: "same-origin",
        method: "POST",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
        },
        body: JSON.stringify({
            newCompanyId,
        }),
    })
        .then(h.checkStatus)
        .then(h.toJson)
        .then(() => {
            dispatch(requestSessionInfo());
            dispatch(decAjaxCount());
            if (callbackAfterChangeCompany != null && typeof callbackAfterChangeCompany == "function") {
                callbackAfterChangeCompany();
            }
        })
        .catch(error => {
            dispatch(decAjaxCount());
            h.error("Error changing company.", error);
            dispatch(requestSessionInfo());
        });
};

export const requestUpdateUserSettings = userSettings => dispatch => {
    const url = "/Manage/UpdateUserSettings";
    dispatch(incAjaxCount());
    fetch(url, {
        credentials: "same-origin",
        method: "POST",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
        },
        body: JSON.stringify({
            userSettings,
        }),
    })
        .then(h.checkStatus)
        .then(h.toJson)
        .then(() => {
            dispatch(requestSessionInfo());
            dispatch(notifyGreen("User settings updated."));
            dispatch(decAjaxCount());
        })
        .catch(error => {
            dispatch(decAjaxCount());
            h.error("Error updating user settings.", error);
        });
};

export const flowRequests = (companyId: number) => dispatch => {
    const url = "/flows/RequestAllFlowData";
    dispatch(incAjaxCount());
    fetch(url, {
        credentials: "same-origin",
        method: "POST",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
        },
    })
        .then(h.checkStatus)
        .then(h.toJson)
        .then(data => {
            if (data && data.success) {
                //GetFieldLabelTypes
                dispatch({ type: "SET_FIELD_LABEL_TYPES", labelTypes: data.fieldLabelTypes });
                //getAvailableFolders
                const availableFolders = {};

                $.each(data.availableFolders, (key, item) => {
                    availableFolders[item.id] = item;
                });

                dispatch({
                    type: "GET_AVAILABLE_FOLDERS",
                    folders: availableFolders,
                });
                //loadFlowFolders
                if (data.userNames) {
                    dispatch(setUserNames(data.userNames));
                }

                const types = uniqArray(data.flowFolders.map(x => x.Type));
                for (const folderType of types) {
                    const filteredData = data.flowFolders.filter(x => x.Type == folderType);
                    dispatch(loadFolders(folderType, filteredData));
                }
                dispatch(ToggleIsFlowTreeLoading(false));
                //premiumFields
                dispatch(setPremiumFields(data.premiumFields));
                top.premiumFields = data.premiumFields;
                dispatch(decAjaxCount());
                _premiumLastLoadedByCompany[companyId] = new Date();
                dispatch(ToggleIsLayoutTreeLoading(false));
                //requestAllLayoutObjects
                dispatch(setAllSelectedLayoutObjects(data.layoutAndObjects.items));
            }
        })
        .catch(error => {
            dispatch(decAjaxCount());
            h.error("Error updating user settings.", error);
        });
};

export const manageFileRequests = () => dispatch => {
    const url = "/manage/RequestManageData";
    dispatch(incAjaxCount());
    fetch(url, {
        credentials: "same-origin",
        method: "POST",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
        },
    })
        .then(h.checkStatus)
        .then(h.toJson)
        .then(data => {
            if (data && data.success) {
                // dispatch(getModes());
                dispatch({
                    type: "GET_MODES",
                    modes: data.modes,
                });
                //     dispatch(getImports());
                const imports = {};

                $.each(data.imports, (key, item) => {
                    imports[item.ImportId] = item;
                });

                dispatch({
                    type: "GET_IMPORTS",
                    files: imports,
                });
                //     dispatch(getAvailableFolders());
                const results = {};

                $.each(data.folders, (key, item) => {
                    results[item.id] = item;
                });

                dispatch({
                    type: "GET_AVAILABLE_FOLDERS",
                    folders: results,
                });
                //     dispatch(getJoins());
                const joins = Object.keys(data.joins).map(item => ({
                    label: data.joins[item].VisibleName,
                    value: data.joins[item].Id,
                    item: data.joins[item],
                }));

                dispatch({
                    type: "GET_JOINS",
                    joinToTables: joins,
                });
                //     dispatch(    ());
                dispatch({
                    type: "GET_COLUMNS_FOR_TABLE",
                    joinColumns: data.columns,
                });
                dispatch(decAjaxCount());
            }
        })
        .catch(error => {
            dispatch(decAjaxCount());
            h.error("Error updating user settings.", error);
        });
};

//// CHANGE COMPANY ////
import { deleteAllItemsInFlow, setSelectedFlow, setSelectedFlowNoUnsavedCheck } from "./flowActions";
// Change company, but first look for unsaved changes and present 'loss of work' screen if we need to.
export const CAPageChangeCompany = (newCompanyId, oldCompanyId) => (dispatch, getState) => {
    // Look to see if unsaved changes
    const state = getState();
    const flow = state.flows.byId[state.selected.flow] || {};
    const location = state.router.location;

    if (flow.hasUnsavedChanges && location.pathname.includes("/flows")) {
        dispatch(StartLossOfWork(newCompanyId, oldCompanyId, { flow }));
    } else {
        dispatch(setVar("loss_of_work_queued_action", {}));
        dispatch(setVar("loss_of_work_canceled_queued_action", {}));
        dispatch(CAPageChangeCompanyNoUnsavedCheck(newCompanyId));
    }
};

export const StartLossOfWork = (newCompanyId, oldCompanyId, type) => dispatch => {
    dispatch(
        setVar("loss_of_work_queued_action", () => {
            dispatch(setSelectedFlow(-1));
            dispatch(deleteAllItemsInFlow(-1));
            dispatch(CAPageChangeCompanyNoUnsavedCheck(newCompanyId));
        })
    );
    dispatch(setVar("loss_of_work_canceled_queued_action", dispatch(setCompanyId(parseInt(oldCompanyId)))));
    dispatch(showModal("LOSS_OF_WORK", type));
};
top.RDX.CAPageChangeCompany = (newCompanyId, oldCompanyId) =>
    top.store.dispatch(CAPageChangeCompany(newCompanyId, oldCompanyId));

// The user decided not to change companies after all.  Move the dropdown back to what it should be.
export const changeCompanyCanceled = oldCompanyId => () => {
    // This works for audience engine, does not work for data engine (but the data engine doesn't have a company dropdown right now..)
    if (top.setCompanyDropdownWithoutTriggeringChange) {
        top.setCompanyDropdownWithoutTriggeringChange(oldCompanyId);
    }
};

import { requestFieldTree, requestLayoutFieldTree } from "./fieldTreeActions";
import { requestEnabledFeatures } from "./sessionActions";
// Change companies - does the work of changing companies.  Perhaps should show a modal progress bar.
export const CAPageChangeCompanyNoUnsavedCheck =
    (newCompanyId, redirectToLayouts = false, redirectToRestrictedFields = false) =>
    (dispatch, getState) => {
        const hl = document.getElementById("react_header_logo");
        if (hl) {
            hl.src = "/Images/CompanyLogo/" + newCompanyId;
        }

        const state = getState();
        const features = state.session.enabledFeatures || [];
        const callbackAfterChangeCompany = () => {
            if (redirectToLayouts) {
                window.location.href = "/ae/tools/layouts";
            } else if (redirectToRestrictedFields) {
                window.location.href = "/ae/admin/restricted-fields";
            } else {
                const location = state.router.location;
                const preventReload = features.includes("prevent-page-reload-company-change");
                if (location.pathname.includes("/flows") && preventReload) {
                    dispatch(goToUrl("/flows/design"));

                    if (features.includes("company-switch-simplified")) {
                        dispatch(flowRequests(newCompanyId));
                    } else {
                        dispatch(GetFieldLabelTypes());
                        dispatch(getAvailableFolders());
                        dispatch(requestLoadFlowFolders());
                        dispatch(requestPremiumFields());
                        dispatch(requestAllLayoutObjects());
                    }

                    dispatch(requestEnabledFeatures());
                    dispatch(requestFieldTree());
                    dispatch(requestLayoutFieldTree());

                    dispatch(requestClientVariables());
                    dispatch(requestFlowDeploymentQueue("Active", 0, 0));
                    dispatch(requestFlows());
                } else if (location.pathname.includes("/manage-files") && preventReload) {
                    dispatch(goToUrl("/tools/manage-files"));

                    if (features.includes("company-switch-simplified")) {
                        dispatch(manageFileRequests());
                    } else {
                        dispatch(getModes());
                        dispatch(getImports());
                        dispatch(getAvailableFolders());
                        dispatch(getJoins());
                        dispatch(getColumnsForTable());
                    }
                } else {
                    // Lets force a reload for now
                    // other React pages will need to be accounted for
                    dispatch(setSelectedFlowNoUnsavedCheck(null));
                    window.location.reload(true);
                }
            }
        };

        /////

        dispatch(setCompanyId(parseInt(newCompanyId, 10)));
        dispatch(requestChangeCompany(parseInt(newCompanyId, 10), callbackAfterChangeCompany));

        // In most cases, the dropdown is already set correctly, but just in case..
        // ([Change company -> Loss of work -> Save -> Finished saving] needs this)
        if (top.setCompanyDropdownWithoutTriggeringChange) {
            top.setCompanyDropdownWithoutTriggeringChange(newCompanyId);
        }
    };
top.RDX.CAPageChangeCompanyNoUnsavedCheck = (companyId, redirectToLayouts) =>
    top.store.dispatch(CAPageChangeCompanyNoUnsavedCheck(companyId, redirectToLayouts));

export const incAjaxCount = () => ({
    type: "INC_AJAX_COUNT",
});

export const decAjaxCount = () => ({
    type: "DEC_AJAX_COUNT",
});

export const tableReportSetTabKey = tabKey => ({
    type: "TABLEREPORT_SET_TAB_KEY",
    tabKey,
});
top.RDX.tableReportSetTabKey = tabKey => top.store.dispatch(tableReportSetTabKey(tabKey));

export const requestCrosstabAggregationOperators = () => dispatch => {
    dispatch(incAjaxCount());

    return fetch("/TableReport/GetOperators", {
        method: "POST",
        credentials: "same-origin",
    })
        .then(h.checkStatus)
        .then(h.toJson)
        .then(data => {
            dispatch(decAjaxCount());
            const { aggregationOperators, windowOperators } = data;
            dispatch({
                type: "CROSSTAB_SET_AGGREGATION_OPERATORS",
                aggregationOperators,
            });
            dispatch({
                type: "CROSSTAB_SET_WINDOW_OPERATORS",
                windowOperators,
            });
        })
        .catch(error => {
            dispatch(decAjaxCount());
            h.error("Error finding operators", error);
        });
};

export const requestMosaicGroups = () => dispatch => {
    dispatch(incAjaxCount());
    fetch("/Mosaic/Groups", { credentials: "same-origin" })
        .then(h.checkStatus)
        .then(h.toJson)
        .then(data => {
            dispatch(setVar("mosaicGroups", data));
            dispatch(decAjaxCount());
        })
        .catch(error => {
            h.error("Error loading mosaic groups", error);
            dispatch(decAjaxCount());
        });
};

export const goToUrl = url => dispatch => {
    if (window.location.href.includes("/ae/") || window.location.href.includes("/de/")) {
        dispatch(push(url));
    } else {
        window.location.assign(url);
    }
};
