import { CannedReportDetailActions, ICannedReportDetailStore } from "../types/stores/flowCannedReportStore";

export const SET_CANNED_REPORTDETAIL = "SET_CANNED_REPORTDETAIL";
export const GET_CANNED_REPORTDETAIL = "GET_CANNED_REPORTDETAIL";
export const GET_CANNED_REPORTDETAIL_LIST = "GET_CANNED_REPORTDETAIL_LIST";
export const IS_CANNED_REPORTDETAIL_LOADING = "IS_CANNED_REPORTDETAIL_LOADING";
export const IS_UPDATING_CANNED_REPORTDETAIL = "IS_UPDATING_CANNED_REPORTDETAIL";

const initialState: ICannedReportDetailStore = {
    reportDetail: {
        ReportDetailId: 0,
        ReportName: "",
        SPContent: "",
        IsActive: true,
        Description: "",
        EnvironmentList: "",
        CompanyId: 0,
    },
    reportDetails: [],
    isLoading: false,
    isUpdating: false,
};

function cannedReportDetail(state = initialState, action: CannedReportDetailActions): ICannedReportDetailStore {
    switch (action.type) {
        case SET_CANNED_REPORTDETAIL:
            return Object.assign({}, state, {
                reportDetail: action.reportDetail,
            });
        case GET_CANNED_REPORTDETAIL:
            return Object.assign({}, state, {
                reportDetail: action.reportDetail,
            });
        case GET_CANNED_REPORTDETAIL_LIST:
            return Object.assign({}, state, {
                reportDetails: action.reportDetails,
            });
        case IS_CANNED_REPORTDETAIL_LOADING:
            return Object.assign({}, state, {
                isLoading: action.isLoading,
            });
        case IS_UPDATING_CANNED_REPORTDETAIL:
            return Object.assign({}, state, {
                isUpdating: action.isUpdating,
            });
        default:
            return state;
    }
}

export default cannedReportDetail;
