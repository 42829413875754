import { SAAAdvertiserActions, ISAAAdvertisersStore } from "../types/stores/saaAdvertisers";

export const SET_SAA_ADVERTISER_LIST = "SET_SAA_ADVERTISER_LIST";
export const SET_LOADING_SAA_ADVERTISERS = "SET_LOADING_SAA_ADVERTISERS";
export const SET_SAVING_SAA_ADVERTISER = "SET_SAVING_SAA_ADVERTISER";
export const SET_SELECTED_SAA_ADVERTISER = "SET_SELECTED_SAA_ADVERTISER";

const initialState: ISAAAdvertisersStore = {
    saaAdvertiserList: [],
    isLoadingList: false,
    isSaving: false,
    selectedId: 0,
};

function saaAdvertisers(state = initialState, action: SAAAdvertiserActions): ISAAAdvertisersStore {
    switch (action.type) {
        case SET_SAA_ADVERTISER_LIST:
            return Object.assign({}, state, {
                saaAdvertiserList: action.saaAdvertiserList,
            });
        case SET_LOADING_SAA_ADVERTISERS:
            return Object.assign({}, state, {
                isLoadingList: action.isLoadingList,
            });
        case SET_SAVING_SAA_ADVERTISER:
            return Object.assign({}, state, {
                isSaving: action.isSaving,
            });
        case SET_SELECTED_SAA_ADVERTISER:
            return Object.assign({}, state, {
                selectedId: action.selectedId,
            });
        default:
            return state;
    }
}

export default saaAdvertisers;
