import h from "../helpers";
import { request } from "../helpers/httpInterceptor";
import {
    TOGGLE_FIELD_EDIT_LISTING,
    TOGGLE_FIELD_EDIT_LISTING_PINNABLE,
    SET_PROPERTY,
    AdminDesignerProperties,
} from "../reducers/adminDesigner";
import { notifyError, notifyGreen } from "./notifyActions";
import { requestEditFieldTree, requestEnabledFieldCompanies } from "./fieldTreeActions";
import { IAdminDesignerStore, SetProperty, IImportBulkField } from "../types/stores/adminDesigner";
import { Dispatch, GetState } from "../types/types";

export const ToggleFieldEditListing = (currentValue: boolean): any => ({
    type: TOGGLE_FIELD_EDIT_LISTING,
    isOpen: !currentValue,
});

export const ToggleFieldEditPinnable = (currentValue: boolean): any => ({
    type: TOGGLE_FIELD_EDIT_LISTING_PINNABLE,
    isPinned: !currentValue,
});

export const setProperty = (
    property: string,
    newValue: IAdminDesignerStore[keyof IAdminDesignerStore],
    index?: number
): SetProperty => ({
    type: SET_PROPERTY,
    property,
    newValue,
    index,
});

export const requestFieldVisibilityTypes =
    () =>
    (dispatch: Dispatch): void => {
        const url = "/Field/GetFieldVisibilityTypes";
        return (
            request(url, { credentials: "same-origin", method: "POST" }, dispatch)
                /* eslint-disable no-console */
                .then(h.checkStatus)
                .then(console.log("GetFieldVisibilityTypes:checkStatus" + Date().toLocaleString() + ""))
                .then(h.toJson)
                .then(console.log("GetFieldVisibilityTypes:toJson" + Date().toLocaleString() + ""))
                .then(data => {
                    if (data) {
                        dispatch(setProperty(AdminDesignerProperties.fieldVisibilityTypes, data));
                    }
                })
                .catch(error => {
                    h.error("Error loading field visibility types.", error);
                })
        );
    };

export const requestFieldVisibilityTypesDefault =
    () =>
    (dispatch: Dispatch): void => {
        dispatch(setProperty(AdminDesignerProperties.isLoadingFieldVisibilityTypesDefault, true));

        const url = "/Field/GetFieldVisibilityTypesDefault";
        return (
            request(url, { credentials: "same-origin", method: "POST" }, dispatch)
                /* eslint-disable no-console */
                .then(h.checkStatus)
                .then(console.log("GetFieldVisibilityTypesDefault:checkStatus" + Date().toLocaleString() + ""))
                .then(h.toJson)
                .then(console.log("GetFieldVisibilityTypesDefault:toJson" + Date().toLocaleString() + ""))
                .then(data => {
                    if (data) {
                        dispatch(setProperty(AdminDesignerProperties.fieldVisibilityTypesDefault, data));
                    }
                })
                .catch(error => {
                    h.error("Error loading field visibility types by default.", error);
                })
                .finally(() => {
                    dispatch(setProperty(AdminDesignerProperties.isLoadingFieldVisibilityTypesDefault, false));
                })
        );
    };

export const requestFieldLabels =
    () =>
    (dispatch: Dispatch): void =>
        request("/Field/GetFieldLabels", { credentials: "same-origin", method: "POST" }, dispatch)
            /* eslint-disable no-console */
            .then(h.checkStatus)
            .then(console.log("requestFieldLabels:checkStatus" + Date().toLocaleString() + ""))
            .then(h.toJson)
            .then(console.log("requestFieldLabels:toJson" + Date().toLocaleString() + ""))
            .then(data => {
                dispatch(setProperty(AdminDesignerProperties.fieldLabels, data.results));
            })
            .catch(error => {
                h.error("Error loading field labels.", error);
            });

export const requestFieldLabels3 =
    () =>
    (dispatch: Dispatch): void =>
        request("/Field/GetFieldLabels3", { credentials: "same-origin", method: "POST" }, dispatch)
            .then(h.checkStatus)
            .then(h.toJson)
            .then(data => {
                dispatch(setProperty(AdminDesignerProperties.fieldLabels3, data.results));
            })
            .catch(error => {
                h.error("Error loading field labels2.", error);
            });

export const requestUmbrellaDataLevels =
    () =>
    (dispatch: Dispatch): void =>
        request("/Field/GetUmbrellaDataLevels", { credentials: "same-origin", method: "POST" }, dispatch)
            /* eslint-disable no-console */
            .then(h.checkStatus)
            .then(console.log("GetUmbrellaDataLevels:checkStatus" + Date().toLocaleString() + ""))
            .then(h.toJson)
            .then(console.log("GetUmbrellaDataLevels:toJson" + Date().toLocaleString() + ""))
            .then(data => {
                dispatch(setProperty(AdminDesignerProperties.umbrellaDataLevels, data.results));
            })
            .catch(error => {
                h.error("Error loading umbrella levels.", error);
            });

export const requestUmbrellaDataTypes =
    () =>
    (dispatch: Dispatch): void =>
        request("/Field/GetUmbrellaDataTypes", { credentials: "same-origin", method: "POST" }, dispatch)
            /* eslint-disable no-console */
            .then(h.checkStatus)
            .then(console.log("GetUmbrellaDataTypes:checkStatus" + Date().toLocaleString() + ""))
            .then(h.toJson)
            .then(console.log("GetUmbrellaDataTypes:toJson" + Date().toLocaleString() + ""))
            .then(data => {
                dispatch(setProperty(AdminDesignerProperties.umbrellaDataTypes, data.results));
            })
            .catch(error => {
                h.error("Error loading umbrella types.", error);
            });

export const requestUsersForCompany =
    (companyId?: number) =>
    (dispatch: Dispatch): Array<any> =>
        request(
            `/UsersAdmin/GetUsersForCompany${companyId && companyId > 0 ? "?companyId=" + companyId : ""}`,
            { credentials: "same-origin", method: "POST" },
            dispatch
        )
            .then(h.checkStatus)
            .then(h.toJson)
            .then(data => {
                dispatch(setProperty(AdminDesignerProperties.companyUsers, data.users, companyId ?? 0));
            })
            .catch(error => {
                h.error("Error loading users for company.", error);
            });

export const requestSaveBulkSettings =
    (isBulkActions: boolean) =>
    (dispatch: Dispatch, getState: GetState): void => {
        const state = getState();
        const selectedFields = state.dataConnect.selectedFields || [];

        //button should be disabled so this shouldn't happen
        if (selectedFields.length <= 0) {
            return;
        }
        const overwriteTreeVisibility = state.adminDesigner.overwriteTreeVisibility;
        const setIsMetaDataRefresh = state.adminDesigner.setIsMetaDataRefresh;
        const overwriteFieldLabels = state.adminDesigner.overwriteFieldLabels;
        const overwriteUserVisibility = state.adminDesigner.overwriteUserVisibility;
        const visibleAllUsers = state.adminDesigner.visibleAllUsers[0];
        const selectedFieldVisibilityTypes = state.adminDesigner.selectedFieldVisibilityTypes[0];
        const selectedFieldLabels = state.adminDesigner.selectedFieldLabels;
        const selectedCompanyUsers = state.adminDesigner.selectedCompanyUsers[0];
        const selectedCompanies = state.adminDesigner.selectedCompanies;
        const restrictedField = state.adminDesigner.isRestrictedField;
        const isUpdateMetaData = state.adminDesigner.isUpdateMetaData;
        const isUpdateFieldClassification = state.adminDesigner.isUpdateFieldClassification;
        const setFieldDataType = state.adminDesigner.setFieldDataType;
        const setFieldClassification = state.adminDesigner.setFieldClassification;
        const setSubTypeDropDown = state.adminDesigner.setSubTypeDropDown;
        const setDecimals = state.adminDesigner.setDecimals;
        const setFieldMetaDataKey = state.adminDesigner.setFieldMetaDataKey;
        const cloneFieldLabels = state.adminDesigner.cloneFieldLabels;
        const toCompany = state.adminDesigner.toCompany;

        dispatch(setProperty(AdminDesignerProperties.isSaving, true));
        return request(
            "/Field/SaveBulkSettings",
            {
                credentials: "same-origin",
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },

                body: JSON.stringify({
                    SelectedFields: selectedFields,
                    OverwriteTreeVisibility: overwriteTreeVisibility,
                    SetIsMetaDataRefresh: setIsMetaDataRefresh,
                    OverwriteFieldLabels: overwriteFieldLabels,
                    OverwriteUserVisibility: overwriteUserVisibility,
                    VisibleAllUsers: visibleAllUsers,
                    SelectedFieldVisibilityTypes: selectedFieldVisibilityTypes,
                    SelectedFieldLabels: selectedFieldLabels,
                    SelectedCompanyUsers: selectedCompanyUsers,
                    SelectedCompanies: selectedCompanies,
                    isRestrictedField: restrictedField,
                    IsUpdateMetaData: isUpdateMetaData,
                    IsUpdateFieldClassification: isUpdateFieldClassification,
                    SetFieldDataType: setFieldDataType,
                    setFieldClassification,
                    SetSubTypeDropDown: setSubTypeDropDown,
                    SetDecimals: setDecimals,
                    SetFieldMetaDataKey: setFieldMetaDataKey,
                    CloneFieldLabels: cloneFieldLabels,
                    toCompany,
                }),
            },
            dispatch
        )
            .then(h.checkStatus)
            .then(h.toJson)
            .then(data => {
                dispatch(setProperty(AdminDesignerProperties.isSaving, false));
                if (!data.success) {
                    if (data.message) {
                        dispatch(notifyError(data.message));
                    } else {
                        h.error("Error saving field bulk settings.");
                    }
                } else {
                    dispatch(requestEditFieldTree(isBulkActions));
                    dispatch(requestEnabledFieldCompanies());
                    //#6359 just commented this two lines , I will left them here just in case we need to go back -Marco
                    //dispatch(RemoveAllFromDataConnectSelectedFields());
                    //dispatch(clearBulkOptions());
                    dispatch(notifyGreen("Fields settings updated successfully."));
                }
            })
            .catch(error => {
                dispatch(setProperty(AdminDesignerProperties.isSaving, false));
                h.error("Error saving field bulk settings.", error);
            });
    };

export const downloadBulkSettings =
    () =>
    (dispatch: Dispatch, getState: GetState): void => {
        const state = getState();
        const selectedFields = state.dataConnect.selectedFields || [];
        const selectedFieldNames = state.dataConnect.selectedFieldNames || [];
        //button should be disabled so this shouldn't happen
        if (selectedFields.length <= 0) {
            return;
        }

        const selectedFieldVisibilityTypes = state.adminDesigner.selectedFieldVisibilityTypes[0];
        const selectedFieldVisibilityTypeNames = state.adminDesigner.selectedFieldVisibilityTypeNames[0];
        const selectedCompanies = state.adminDesigner.selectedCompanies;

        request(
            `/BulkSetting/ExportBulkSettings`,
            {
                credentials: "same-origin",
                method: "POST",
                body: JSON.stringify({
                    SelectedCompanies: selectedCompanies,
                    SelectedFields: selectedFields,
                    SelectedFieldNames: selectedFieldNames,
                    SelectedFieldVisibilityTypes: selectedFieldVisibilityTypes,
                    SelectedFieldVisibilityTypeNames: selectedFieldVisibilityTypeNames,
                }),
            },
            dispatch
        )
            .then(response => response.blob())
            .then(blob => {
                // Create blob link to download
                const url = window.URL.createObjectURL(new Blob([blob]));
                const anchorLink = document.createElement("a");
                anchorLink.href = url;
                anchorLink.setAttribute("download", `Output Default BulkSettings.csv`);

                // Append to html anchorLink element page
                document.body.appendChild(anchorLink);

                // Start download
                anchorLink.click();

                // Clean up and remove the anchorLink
                anchorLink.parentNode?.removeChild(anchorLink);
            });
    };

export const requestAddBulkFields =
    (importedBulkFields: Array<IImportBulkField>) =>
    (dispatch: Dispatch): void => {
        request(
            `/Field/ImportBulkSettings`,
            {
                credentials: "same-origin",
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    SelectedFields: importedBulkFields,
                }),
            },
            dispatch
        )
            .then(h.checkStatus)
            .then(h.toJson)
            .then(data => {
                if (data && data.success) {
                    dispatch(notifyGreen("Successfully saved your import." + data.message));
                } else if (data && !data.success) {
                    h.error("Error saving your import.", data.message);
                }
            })
            .catch(error => {
                h.error("Error saving your import.", error);
            });
    };
