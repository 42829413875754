import { normalize, schema } from "normalizr";
import { push } from "connected-react-router";

import { incAjaxCount, decAjaxCount } from "./actionCreators";
import { toJson } from "../helpers/typedHelpers";
import h, { makeNewId } from "../helpers";
import { requestSessionInfo, requestEnabledFeatures } from "./sessionActions";
import { notifyGreen } from "./notifyActions";
import { SetUsersIsLoading, SetUserIsSaving, SetUserRoles } from "./userAdminActions";
import { SetReassignItemsToUser } from "../actions/reassignedItemsToActions";

import type {
    Dispatch,
    GetState,
    User,
    Company,
    UserCompany,
    NormalizedUserData,
    NormalizedCompanyData,
    NormalizedUserCompanyData,
    Site,
    Role,
    Features,
} from "../types/types";

if (!top.RDX) {
    top.RDX = {};
}

export const requestUser = (userId: number) => (dispatch: Dispatch) => {
    const getUserListUrl = "/UsersAdmin/GetUsers?" + h.serialize({ userId });
    dispatch(incAjaxCount());
    fetch(getUserListUrl, { credentials: "same-origin" })
        .then(h.checkStatus)
        .then(toJson)
        .then(data => {
            const normalizedUserData = normalizeUserData(data.users);
            dispatch({ type: "USER_MERGEOVERWRITE_LIST", things: normalizedUserData.entities["users"] || [] });
            dispatch(decAjaxCount());
        })
        .catch(error => {
            dispatch(decAjaxCount());
            h.error("Error getting user.", error);
        });
};

export const requestUserList = () => (dispatch: Dispatch) => {
    const getUserListUrl = "/UsersAdmin/GetUsers";
    dispatch(SetUsersIsLoading(true));
    dispatch(incAjaxCount());
    fetch(getUserListUrl, { credentials: "same-origin" })
        .then(h.checkStatus)
        .then(toJson)
        .then(data => {
            const normalizedUserData = normalizeUserData(data.users);
            dispatch({ type: "USER_REPLACE_LIST", things: normalizedUserData.entities["users"] || [] });
            dispatch(SetUsersIsLoading(false));
            dispatch(decAjaxCount());
        })
        .catch(error => {
            dispatch(decAjaxCount());
            h.error("Error getting Users.", error);
        });
};

// All companies that exist in the system
export const requestCompanies = () => (dispatch: Dispatch) => {
    const getCompaniesUrl = "/UsersAdmin/GetCompanies";
    dispatch(incAjaxCount());
    fetch(getCompaniesUrl, { credentials: "same-origin" })
        .then(h.checkStatus)
        .then(toJson)
        .then(data => {
            const normalizedCompanyData = normalizeCompanyData(data.companies);
            dispatch({ type: "COMPANY_REPLACE_LIST", things: normalizedCompanyData.entities["companies"] || [] });
            dispatch(decAjaxCount());
        })
        .catch(error => {
            dispatch(decAjaxCount());
            h.error("Error getting Companies.", error);
        });
};

// Companies that users has access to
export const requestAllUserCompanies = () => (dispatch: Dispatch) => {
    const getUserCompaniesUrl = "/UsersAdmin/GetAllUserCompanies";
    dispatch(incAjaxCount());
    fetch(getUserCompaniesUrl, { credentials: "same-origin" })
        .then(h.checkStatus)
        .then(toJson)
        .then(data => {
            const normalizedUserCompaniesData = normalizeUserCompaniesData(data.userCompanies);
            dispatch({
                type: "USERCOMPANY_REPLACE_LIST",
                things: normalizedUserCompaniesData.entities["userCompanies"] || [],
            });
            dispatch(decAjaxCount());
        })
        .catch(error => {
            dispatch(decAjaxCount());
            h.error("Error getting users companies.", error);
        });
};

// Companies that a particular user has access to
export const requestUserCompanies = (userId: number) => (dispatch: Dispatch) => {
    const getUserCompaniesUrl = "/UsersAdmin/GetUserCompanies?" + h.serialize({ userId });
    dispatch(incAjaxCount());
    fetch(getUserCompaniesUrl, { credentials: "same-origin" })
        .then(h.checkStatus)
        .then(toJson)
        .then(data => {
            dispatch(cleanUpAllUserCompanies(userId));
            const normalizedUserCompaniesData = normalizeUserCompaniesData(data.userCompanies);
            dispatch({
                type: "USERCOMPANY_MERGEOVERWRITE_LIST",
                things: normalizedUserCompaniesData.entities["userCompanies"] || [],
            });
            dispatch(decAjaxCount());
        })
        .catch(error => {
            dispatch(decAjaxCount());
            h.error("Error getting users companies.", error);
        });
};

export const getUserRoles = () => (dispatch: Dispatch) => {
    const getUsersRolesUrl = "/UsersAdmin/GetAllUserRoles";
    dispatch(incAjaxCount());
    fetch(getUsersRolesUrl, { credentials: "same-origin" })
        .then(h.checkStatus)
        .then(toJson)
        .then(data => {
            dispatch(SetUserRoles(data.roles));
            dispatch(decAjaxCount());
        })
        .catch(error => {
            dispatch(decAjaxCount());
            h.error("Error getting user roles.", error);
        });
};

export const requestUserActiveUpdate = (userId: number, newStatus: boolean) => (dispatch: Dispatch) => {
    const toggleUserActiveUrl =
        "/UsersAdmin/UpdateActiveStatus?" +
        h.serialize({
            userId,
            newStatus,
        });
    dispatch(incAjaxCount());
    fetch(toggleUserActiveUrl, { method: "POST", credentials: "same-origin" })
        .then(h.checkStatus)
        .then(toJson)
        .then(() => {
            dispatch({
                type: "USER_CHANGE_ATTRIBUTE",
                id: userId,
                attribute: "UserActive",
                value: newStatus,
                meta: { doNotBatch: false },
            });
            dispatch(decAjaxCount());
            dispatch(requestReloadUser(userId));
            dispatch(notifyGreen(`User successfully set to ${newStatus ? "Active" : "Inactive"}.`));
        })
        .catch(error => {
            dispatch(decAjaxCount());
            if (error.message && error.message.includes("No seats available")) {
                h.error(error.message);
            } else {
                h.error("Error updating user. Please contact your admin for assistance.", error);
            }
        });
};

export const requestClearUserLockout = (userId: number) => (dispatch: Dispatch) => {
    const clearLockoutUrl = "/UsersAdmin/ClearUserLockout?" + h.serialize({ userId });

    dispatch(incAjaxCount());
    fetch(clearLockoutUrl, { method: "POST", credentials: "same-origin" })
        .then(h.checkStatus)
        .then(toJson)
        .then(() => {
            dispatch({
                type: "USER_CHANGE_ATTRIBUTE",
                id: userId,
                attribute: "LockoutEndDateUtc",
                value: null,
                meta: { doNotBatch: false },
            });
            dispatch(decAjaxCount());
            dispatch(notifyGreen("User successfully unlocked."));
        })
        .catch(error => {
            dispatch(decAjaxCount());
            h.error("Error updating user. Please contact your admin for assistance.", error);
        });
};

export const setSelectedUser = (userId: number) => (dispatch: Dispatch, getState: GetState) => {
    dispatch({
        type: "SET_SELECTED_USER",
        userId,
    });
    const state = getState();
    if (!state.router || !state.router.location) {
        return;
    }

    if (userId == 0) {
        dispatch(push("/admin/users"));
    } else {
        dispatch(push("/admin/users/edit/" + userId));
    }
};

export const setSelectedReassignTo = (reassiendUserId: number) => (dispatch: Dispatch) => {
    dispatch({
        type: "SET_SELECTED_REASSIGN_TO",
        reassiendUserId,
    });
};

export const requestSites = () => (dispatch: Dispatch) => {
    const getSitesUrl = "/UsersAdmin/GetSites";
    dispatch(incAjaxCount());
    fetch(getSitesUrl, { credentials: "same-origin" })
        .then(h.checkStatus)
        .then(toJson)
        .then(data => {
            dispatch({ type: "SITE_REPLACE_LIST", sites: data.sites });
            dispatch(decAjaxCount());
        })
        .catch(error => {
            dispatch(decAjaxCount());
            h.error("Error getting Sites.", error);
        });
};

export const requestCompanySeats = () => (dispatch: Dispatch) => {
    const getSeatsUrl = "/UsersAdmin/GetCompanySeats";
    dispatch(incAjaxCount());
    fetch(getSeatsUrl, { credentials: "same-origin" })
        .then(h.checkStatus)
        .then(toJson)
        .then(data => {
            dispatch({ type: "COMPANYSEAT_REPLACE_LIST", companySeats: data.companySeats });
            dispatch(decAjaxCount());
        })
        .catch(error => {
            dispatch(decAjaxCount());
            h.error("Error getting Company Seats.", error);
        });
};

export const updateUserAttribute = (userId: number, attribute: string, value: any) => (dispatch: Dispatch) => {
    dispatch({
        type: "USER_CHANGE_ATTRIBUTE",
        id: userId,
        attribute,
        value,
        meta: { doNotBatch: true },
    });
    dispatch(setUserHasUnsavedChanges(userId, true));
};

export const setUserHasUnsavedChanges = (userId: number, value: any) => ({
    type: "USER_CHANGE_ATTRIBUTE",
    id: userId,
    attribute: "hasUnsavedChanges",
    value,
});

export const addUserCompany = (userId: number, companyId: number) => (dispatch: Dispatch, getState: GetState) => {
    const state = getState();
    const newId = makeNewId(state.userCompanies.byId);

    const newUserCompany: UserCompany = {
        CompanyID: companyId,
        UserKey: userId,
        UserSecurityLevelKey: null,
        ID: newId,
        TableauAccess: null,
    };

    dispatch({
        type: "USERCOMPANY_ADD",
        id: newId,
        thing: newUserCompany,
    });
};

import { getAllUserCompanies } from "../reducers/userCompanies";
export const removeUserCompany = (userId: number, companyId: number) => (dispatch: Dispatch, getState: GetState) => {
    const state = getState();
    const userCompanies = getAllUserCompanies(state);

    const users = state.users.byId;
    const user = users[userId];
    //user access to its current company was removed
    if (user.CurrentCompanyID == companyId) {
        dispatch(updateUserAttribute(userId, "CurrentCompanyID", null));
    }
    const userCompany = userCompanies.filter(x => x.UserKey == userId && x.CompanyID == companyId)[0];
    if (userCompany) {
        dispatch({
            type: "USERCOMPANY_DELETE",
            id: userCompany.ID,
        });
    }
};

export const addNewUser = () => (dispatch: Dispatch, getState: GetState) => {
    const state = getState();

    const newId = makeNewId(state.users.byId);
    let newUser: User = {
        Id: newId,
        OriginalKey: null,
        Email: "",
        EmailConfirmed: false,
        PhoneNumber: null,
        PhoneNumberConfirmed: false,
        TwoFactorEnabled: false,
        LockOutEndDateUtc: null,
        LockOutEnabled: true,
        AccessFailedCount: 0,
        UserName: "",
        FirstName: "",
        LastName: "",
        PasswordLastChanged: null,
        UserActive: true,
        LastLoginTime: null,
        RegistrationDate: null,
        CurrentCompanyID: null,
        IsInternal: false,
        IsSSO: false,
        IsSSOB2B: false,
        IsSendWelcomeEmail: true,
        ExpireDate: null,
        LogonId: "",
        errors: null,
    };

    dispatch({
        type: "USER_ADD",
        id: newId,
        thing: newUser,
    });
    dispatch(setSelectedUser(newId));
};

export const addNewReassignedUser =
    (newEmail: string, newFirstName: string, newLastName: string, companyId: number) => (dispatch: Dispatch) => {
        const newId = makeNewId(null);
        let newUser: User = {
            Id: newId,
            OriginalKey: null,
            Email: newEmail,
            EmailConfirmed: true,
            PhoneNumber: null,
            PhoneNumberConfirmed: false,
            TwoFactorEnabled: false,
            LockOutEndDateUtc: null,
            LockOutEnabled: true,
            AccessFailedCount: 0,
            UserName: newEmail,
            FirstName: newFirstName,
            LastName: newLastName,
            PasswordLastChanged: null,
            UserActive: true,
            LastLoginTime: null,
            RegistrationDate: null,
            CurrentCompanyID: companyId,
            IsInternal: true,
            IsSSO: false,
            IsSSOB2B: false,
            IsSendWelcomeEmail: true,
            ExpireDate: null,
            LogonId: "",
            errors: null,
        };

        dispatch({
            type: "USER_ADD",
            id: newId,
            thing: newUser,
        });
        //dispatch(setSelectedReassignTo(newId));
        dispatch(SetReassignItemsToUser(newId));
    };

export const requestSendWelcomeEmail = (userId: number) => (dispatch: Dispatch) => {
    if (userId > 0) {
        const deleteUserUrl = "/UsersAdmin/SendWelcomeEmail?" + h.serialize({ userId });
        dispatch(incAjaxCount());
        fetch(deleteUserUrl, { method: "POST", credentials: "same-origin" })
            .then(h.checkStatus)
            .then(toJson)
            .then(data => {
                dispatch(decAjaxCount());
                if (data.success) {
                    dispatch(notifyGreen("Welcome email was sent."));
                } else {
                    h.error("Error sending email.");
                }
            })
            .catch(error => {
                dispatch(decAjaxCount());
                h.error("Error sending email.", error);
            });
    }
};

export const requestDeleteUser = (userId: number) => (dispatch: Dispatch) => {
    if (userId <= 0) {
        dispatch(deleteUser(userId));
    } else {
        const deleteUserUrl = "/UsersAdmin/DeleteUser?" + h.serialize({ userId });
        dispatch(incAjaxCount());
        fetch(deleteUserUrl, { method: "POST", credentials: "same-origin" })
            .then(h.checkStatus)
            .then(toJson)
            .then(data => {
                dispatch(decAjaxCount());
                if (data.success) {
                    dispatch(deleteUser(userId));
                } else {
                    h.error("Error deleting user.");
                }
            })
            .catch(error => {
                dispatch(decAjaxCount());
                h.error("Error deleting user.", error);
            });
    }
};

export const deleteUser = (userId: number) => (dispatch: Dispatch) => {
    dispatch(cleanUpAllUserCompanies(userId));
    dispatch({
        type: "USER_DELETE",
        id: userId,
    });
};

export const requestReloadUser = (userId: number) => (dispatch: Dispatch) => {
    dispatch(requestUser(userId));
    dispatch(requestUserCompanies(userId));
};

export const requestUserSave =
    (
        userId: number,
        //things kept in component state only
        userSites: Array<Site>,
        userRoles: Array<Role>,
        userFeatures: Array<Features>,
        temporaryAccount: boolean
    ) =>
    (dispatch: Dispatch, getState: GetState) => {
        const state = getState();
        const currentUser = state.session.userId;
        const users = state.users.byId;
        const user = users[userId];
        const allUserCompanies = getAllUserCompanies(state);
        const userCompanies = allUserCompanies.filter(c => c.UserKey == userId);

        const dataToSave = {
            user,
            userSites: userSites.map(s => s.Id),
            userRoles: userRoles.map(r => r.Id),
            userFeatures: userFeatures.map(f => f.Id),
            userCompanies: userCompanies.map(c => c.CompanyID),
            temporaryAccount,
        };

        dispatch(incAjaxCount());
        dispatch(updateUserAttribute(userId, "isSaving", true));
        dispatch(SetUserIsSaving(true));

        // Get the Anti-forgery Token to pass in the headers
        let token = "";
        if (document.getElementsByName("__RequestVerificationToken").length > 0) {
            let element = document.getElementsByName("__RequestVerificationToken")[0];
            if (element instanceof HTMLInputElement) {
                token = element.value;
            }
        }

        fetch("/UsersAdmin/SaveUser", {
            credentials: "same-origin",
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                __RequestVerificationToken: token,
            },
            body: JSON.stringify(dataToSave),
        })
            .then(h.checkStatus)
            .then(toJson)
            .then(data => {
                dispatch(decAjaxCount());
                dispatch(updateUserAttribute(userId, "isSaving", false));
                dispatch(SetUserIsSaving(false));

                if (data.userId != userId) {
                    dispatch({
                        type: "USER_ID_CHANGE",
                        idChanges: [[userId, data.userId]],
                    });
                    dispatch(setSelectedUser(data.userId));
                }

                if (data.userId > 0) {
                    //update data from server, properties may have changed
                    if (!data.errors) {
                        dispatch(requestUser(data.userId));
                    }
                    if (userId != data.userId) {
                        //need to user userId instead of data.userId in case it was a new user
                        dispatch(cleanUpAllUserCompanies(userId));
                    }

                    dispatch(requestUserCompanies(data.userId));
                    //if user being edited is the current user in session
                    if (currentUser == userId) {
                        dispatch(requestSessionInfo());
                        dispatch(requestUserList());
                    }
                }

                if (data.success) {
                    dispatch(notifyGreen("User saved successfully."));
                    dispatch(setSelectedUser(0));
                    dispatch(requestReloadUser(user.Id));
                } else {
                    if (data.errors && data.errors.companiesWithNoSeats) {
                        h.error("User was saved but some companies could not be added.");
                    } else {
                        h.error("Error saving user.");
                    }
                    dispatch(updateUserAttribute(data.userId || userId, "errors", data.errors || {}));
                }
                dispatch(requestCompanySeats());
            })
            .catch(error => {
                dispatch(decAjaxCount());
                dispatch(updateUserAttribute(userId, "isSaving", false));
                dispatch(SetUserIsSaving(false));
                h.error("Error saving user.", error);
            });
    };

export const requestNewUserBeforeReassign =
    (
        userId: number,
        //things kept in component state only
        userSites: Array<Site>,
        userRoles: Array<Role>,
        userFeatures: Array<Features>
    ) =>
    (dispatch: Dispatch, getState: GetState) => {
        const state = getState();

        const currentReassignedUserId = state.reassignedItemsTo.reassiendUserId;
        // const currentUser = state.session.userId;
        const users = state.users.byId;
        const user = users[currentReassignedUserId];
        const allUserCompanies = getAllUserCompanies(state);
        const userCompanies = allUserCompanies.filter(c => c.UserKey == userId);

        const dataToSave = {
            user,
            userSites: userSites.map(s => s.Id),
            userRoles: userRoles.map(r => r.Id),
            userFeatures: userFeatures.map(f => f.Id),
            userCompanies: userCompanies.map(c => c.CompanyID),
            temporaryAccount: false,
        };

        dispatch(incAjaxCount());
        dispatch(updateUserAttribute(userId, "isSaving", true));
        dispatch(SetUserIsSaving(true));

        // Get the Anti-forgery Token to pass in the headers
        let token = "";
        if (document.getElementsByName("__RequestVerificationToken").length > 0) {
            let element = document.getElementsByName("__RequestVerificationToken")[0];
            if (element instanceof HTMLInputElement) {
                token = element.value;
            }
        }

        fetch("/UsersAdmin/SaveUser", {
            credentials: "same-origin",
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                __RequestVerificationToken: token,
            },
            body: JSON.stringify(dataToSave),
        })
            .then(h.checkStatus)
            .then(toJson)
            .then(data => {
                dispatch(decAjaxCount());
                dispatch(updateUserAttribute(userId, "isSaving", false));
                dispatch(SetUserIsSaving(false));

                if (data.userId != currentReassignedUserId) {
                    dispatch({
                        type: "USER_ID_CHANGE",
                        idChanges: [[currentReassignedUserId, data.userId]],
                    });
                    //dispatch(setSelectedReassignTo(data.userId));
                    dispatch(SetReassignItemsToUser(data.userId));
                }

                if (data.success) {
                    dispatch(notifyGreen("User saved successfully."));
                } else {
                    if (data.errors && data.errors.companiesWithNoSeats) {
                        h.error("User was saved but some companies could not be added.");
                    } else {
                        h.error("Error saving user.");
                    }
                    dispatch(updateUserAttribute(data.userId || currentReassignedUserId, "errors", data.errors || {}));
                }
                dispatch(requestCompanySeats());
            })
            .catch(error => {
                dispatch(decAjaxCount());
                dispatch(updateUserAttribute(userId, "isSaving", false));
                dispatch(SetUserIsSaving(false));
                h.error("Error saving user.", error);
            });
    };

export const SaveUserFeatures = (selectedFeatures: Array<string>) => (dispatch: Dispatch) => {
    dispatch(incAjaxCount());

    fetch(
        "/UsersAdmin/SaveUserFeatures",
        {
            credentials: "same-origin",
            method: "POST",
            headers: { Accept: "application/json", "Content-Type": "application/json" },
            body: JSON.stringify(selectedFeatures),
        },
        dispatch
    )
        .then(h.checkStatus)
        .then(h.toJson)
        .then(() => {
            dispatch(decAjaxCount());
            dispatch(requestEnabledFeatures());
            dispatch(notifyGreen("User features saved successfully."));
        })
        .catch(error => {
            dispatch(decAjaxCount());
            h.error("Error saving user features.", error);
        });
};

//deletes all user companies from state
export const cleanUpAllUserCompanies = (userId: number) => (dispatch: Dispatch, getState: GetState) => {
    const state = getState();
    const allUserCompanies = getAllUserCompanies(state);
    const userCompanies = allUserCompanies.filter(c => c.UserKey == userId);

    for (const userCompany of userCompanies) {
        dispatch({
            type: "USERCOMPANY_DELETE",
            id: userCompany.ID,
        });
    }
};

export const requestReassignUserItems = (fromUser: number, toUser: number) => (dispatch: Dispatch) => {
    const reassignUserItemsUrl = "/UsersAdmin/ReassignUserItems";

    const data = {
        fromUser,
        toUser,
    };

    dispatch(incAjaxCount());
    fetch(reassignUserItemsUrl, {
        method: "POST",
        credentials: "same-origin",
        headers: { Accept: "application/json", "Content-Type": "application/json" },
        body: JSON.stringify(data),
    })
        .then(h.checkStatus)
        .then(toJson)
        .then(() => {
            dispatch(decAjaxCount());
            dispatch(notifyGreen("Reassignment processing..."));
        })
        .catch(error => {
            dispatch(decAjaxCount());
            h.error("Error reassigning user items.", error);
        });
};

export const requestReassignUserItemsThenDelete =
    (fromUser: number, toUser: number, folderName: string) => (dispatch: Dispatch) => {
        const reassignUserItemsUrl = "/UsersAdmin/ReassignUserItemsThenDelete";

        const data = {
            fromUser,
            toUser,
            folderName,
        };

        dispatch(incAjaxCount());
        fetch(reassignUserItemsUrl, {
            method: "POST",
            credentials: "same-origin",
            headers: { Accept: "application/json", "Content-Type": "application/json" },
            body: JSON.stringify(data),
        })
            .then(h.checkStatus)
            .then(toJson)
            .then(() => {
                dispatch(decAjaxCount());
                dispatch(notifyGreen("Reassignment then deleting user processing..."));
            })
            .catch(error => {
                dispatch(decAjaxCount());
                h.error("Error reassigning user items.", error);
            });
    };

export const requestUpdateUserTableauAccess =
    (userId: number, userCompanyId: number, companyId: number, oldValue: ?string, newVal: ?string) =>
    (dispatch: Dispatch) => {
        const updateTableauAccessUrl = "/UsersAdmin/UpdateUserTableauAccess";

        const data = {
            userId,
            companyId,
            newVal,
        };

        dispatch(incAjaxCount());
        //optimistic update
        dispatch({
            type: "USERCOMPANY_CHANGE_ATTRIBUTE",
            id: userCompanyId,
            attribute: "TableauAccess",
            value: newVal,
        });

        fetch(updateTableauAccessUrl, {
            method: "POST",
            credentials: "same-origin",
            headers: { Accept: "application/json", "Content-Type": "application/json" },
            body: JSON.stringify(data),
        })
            .then(h.checkStatus)
            .then(toJson)
            .then(() => {
                dispatch(decAjaxCount());
                dispatch(notifyGreen("Tableau access updated."));
            })
            .catch(error => {
                dispatch(decAjaxCount());
                //rollback the update
                dispatch({
                    type: "USERCOMPANY_CHANGE_ATTRIBUTE",
                    id: userCompanyId,
                    attribute: "TableauAccess",
                    value: oldValue,
                });
                h.error("Error updating tableau access.", error);
            });
    };

export const requestResetUserPassword = (userId: number) => (dispatch: Dispatch) => {
    const resetUserPasswordUrl = "/UsersAdmin/ResetUserPassword?" + h.serialize({ userId });
    dispatch(incAjaxCount());
    fetch(resetUserPasswordUrl, { method: "POST", credentials: "same-origin" })
        .then(h.checkStatus)
        .then(toJson)
        .then(data => {
            dispatch(decAjaxCount());
            if (data.success) {
                dispatch(notifyGreen("User password reset submitted."));
            } else {
                h.error("Error resetting user password.");
            }
        })
        .catch(error => {
            dispatch(decAjaxCount());
            h.error("Error resetting user password.", error);
        });
};

export const setLoadingCompany = (loadingCompanies: boolean) => ({
    type: "SET_LOADING_COMPANY",
    loadingCompanies,
});

const normalizeUserData = (userData: Array<User>): NormalizedUserData => {
    const user = new schema.Entity("users", {}, { idAttribute: "Id" });
    const mySchema = {
        users: [user],
    };
    return normalize({ users: userData }, mySchema);
};

const normalizeCompanyData = (companyData: Array<Company>): NormalizedCompanyData => {
    const company = new schema.Entity("companies", {}, { idAttribute: "CompanyID" });
    const mySchema = {
        companies: [company],
    };
    return normalize({ companies: companyData }, mySchema);
};
const normalizeUserCompaniesData = (userCompaniesData: Array<UserCompany>): NormalizedUserCompanyData => {
    const userCompany = new schema.Entity("userCompanies", {}, { idAttribute: "ID" });
    const mySchema = {
        userCompanies: [userCompany],
    };
    return normalize({ userCompanies: userCompaniesData }, mySchema);
};
