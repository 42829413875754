import h from "../helpers";
import { push } from "connected-react-router";
import { incAjaxCount, decAjaxCount } from "./actionCreators";
import { notifyBlack, notifyGreen } from "./notifyActions";
if (!top.RDX) {
    top.RDX = {};
}

// Set MODE - See ScheduleMain //

export const setScheduleMode = mode => ({
    type: "SET_SCHEDULE_MODE",
    mode,
});

export const setScheduleModeAndEditId = (mode, editId, isScheduleTemplate) => dispatch => {
    dispatch({
        type: "SET_SCHEDULE_EDITID",
        editId,
    });
    dispatch({
        type: "SET_SCHEDULE_MODE",
        mode,
    });

    if (!isScheduleTemplate) {
        if (editId) {
            dispatch(push("/tools/schedules/" + mode + "/" + editId));
        } else {
            dispatch(push("/tools/schedules/" + mode));
        }
    }
};
export const setScheduleEditId = editId => ({
    type: "SET_SCHEDULE_EDITID",
    editId,
});

// Load Schedules //

export const requestScheduleList =
    (companyId = null) =>
    dispatch => {
        dispatch(incAjaxCount());
        dispatch(scheduleLoadStart());
        fetch(`/schedule/list?companyId=${companyId}`, { credentials: "same-origin" })
            .then(h.checkStatus)
            .then(h.toJson)
            .then(data => {
                dispatch(updateScheduleList(data));
                dispatch(decAjaxCount());
                dispatch(scheduleLoadFinish());
            })
            .catch(error => {
                dispatch(decAjaxCount());
                dispatch(scheduleLoadFinish());
                h.error("Error loading schedules.", error);
            });
    };

export const scheduleLoadStart = () => ({
    type: "SCHEDULE_LOAD_START",
});

export const scheduleLoadFinish = () => ({
    type: "SCHEDULE_LOAD_FINISH",
});

export const updateScheduleList = schedules => ({
    type: "UPDATE_SCHEDULE_LIST",
    schedules,
});

// Load Triggers //

export const requestScheduleTriggerList = scheduleId => dispatch => {
    dispatch(incAjaxCount());
    //dispatch(scheduleLoadStart());
    const listUrl = "/ScheduleTrigger/list?" + h.serialize({ scheduleId });
    fetch(listUrl, { credentials: "same-origin" })
        .then(h.checkStatus)
        .then(h.toJson)
        .then(data => {
            dispatch(updateScheduleTriggerList(scheduleId, data));
            dispatch(decAjaxCount());
        })
        .catch(error => {
            dispatch(decAjaxCount());
            h.error("Error loading schedule triggers.", error);
        });
};

export const updateScheduleTriggerList = (scheduleId, triggers) => ({
    type: "UPDATE_SCHEDULE_TRIGGER_LIST",
    scheduleId,
    triggers,
});

export const requestDeleteScheduleTrigger = triggerId => dispatch => {
    const deleteUrl = "/ScheduleTrigger/Delete?" + h.serialize({ id: triggerId });
    dispatch(notifyGreen("Delete trigger submitted."));
    return fetch(deleteUrl, { method: "POST", credentials: "same-origin" })
        .then(h.checkStatus)
        .then(h.toJson)
        .then(data => {
            if (!data || !data.result || data.result != "success") {
                throw new Error("Unable to delete trigger.");
            }

            const scheduleId = data.scheduleId;
            dispatch(requestScheduleTriggerList(scheduleId));
            dispatch(notifyBlack("Deleted trigger."));
        })
        .catch(error => {
            h.error("Unable to delete trigger", error);
        });
};

export const requestDeleteSchedule =
    (scheduleId, companyId = null) =>
    dispatch => {
        dispatch(notifyGreen("Delete schedule submitted."));
        const deleteUrl = "/Schedule/Delete?" + h.serialize({ id: scheduleId, companyId });
        return fetch(deleteUrl, { method: "POST", credentials: "same-origin" })
            .then(h.checkStatus)
            .then(h.toJson)
            .then(data => {
                if (!data || !data.result || data.result != "success") {
                    throw new Error("Unable to delete schedule.");
                }
                dispatch(notifyBlack("Deleted schedule."));
                dispatch(requestScheduleList(companyId));
            })
            .catch(error => {
                h.error("Unable to delete schedule", error);
            });
    };

// Set a specific schedule Active/Inactive locally
export const setScheduleActive = (scheduleId, isActive) => ({
    type: "SET_SCHEDULE_ACTIVE",
    scheduleId,
    isActive,
});

// Set a specific schedule Consecutive/not Consecutive locally
export const setScheduleConsecutive = (scheduleId, isConsecutive) => ({
    type: "SET_SCHEDULE_CONSECUTIVE",
    scheduleId,
    isConsecutive,
});

// Save a schedule's active status
export const requestEditScheduleActive =
    (id, isActive, beQuiet = false, companyId = null) =>
    dispatch => {
        const editUrl =
            "/Schedule/EditActive?" +
            h.serialize({
                id,
                isActive,
                companyId,
            });

        dispatch(scheduleSaveStart());
        return fetch(editUrl, { method: "POST", credentials: "same-origin" })
            .then(h.checkStatus)
            .then(h.toJson)
            .then(data => {
                if (!data || !data.result || data.result != "success") {
                    throw new Error("Error editing schedule.");
                }
                if (!beQuiet) {
                    debouncedAlertUpdatedSchedule(dispatch);
                }
                dispatch(scheduleSaveFinish());
                // Debouncing the remote update stops weird looking behavior where
                // active switches quickly switch back and forth when updating several of them
                // in a row.
                debouncedRequestScheduleList(dispatch, companyId);
            })
            .catch(error => {
                dispatch(scheduleSaveFinish());
                h.error("Error editing schedule.", error);
            });
    };

import debounce from "debounce";
const debouncedRequestScheduleList = debounce((dispatch, companyId = null) => {
    dispatch(requestScheduleList(companyId));
}, 2200);
const debouncedRequestScheduleTriggerList = debounce((dispatch, scheduleId) => {
    dispatch(requestScheduleTriggerList(scheduleId));
}, 2200);

const debouncedAlertUpdatedSchedule = debounce(dispatch => {
    dispatch(notifyGreen("Updated schedule(s)."));
}, 800);

const debouncedAlertUpdatedTrigger = debounce(dispatch => {
    dispatch(notifyGreen("Updated trigger(s)."));
}, 800);

/*
 */

// Save New Schedule //

/*
    Schedule Types:

    Recurring = 0,
    OneTime = 1,
    NumberOfOccurrences = 2,
    EndDate = 3
*/

export const requestEditSchedule =
    (
        id,
        name, //  Name of schedule, required.
        cronString, // Cron string of when to run the schedule.  Is ignored for OneTime.(?)
        timezone, // String representing timezone that the cronString is applicable to. Unsure of format.
        scheduleType = "0", // String containing a number.  See above for list of types.
        runDate = "", // String containing a date.  Must be provided if scheduleType is OneTime.
        endDate = "", // String containing a date.  Must be provided if scheduleType is EndDate.
        nOccurrences = null, // int?.  Must be >0 if scheduleType is NumberOfOccurrences
        automationIdSelected,
        isConsecutive,
        continueOnError,
        isActive,
        isScheduleTemplate, // is an activation destination template or not
        companyId = null, // used in edit companies
        defaultTriggerType = null, // used for activation destination schedules
        emails = null,
        flowId = null, // used in the export edit schedule modal
        TimeType = null,
        TimeCount = null,
        onSaveCallback = null // Optional Function to run when adding is done, can be null.
    ) =>
    (dispatch /*, getState*/) => {
        const saveUrl =
            "/Schedule/Save?" +
            h.serialize({
                id,
                name,
                cronString,
                timezone,
                scheduleType,
                runDate,
                endDate,
                nOccurrences,
                automationIdSelected,
                isConsecutive,
                continueOnError,
                isActive,
                isScheduleTemplate,
                companyId,
                defaultTriggerType,
                emails,
                flowId,
                TimeType,
                TimeCount,
            });

        dispatch(scheduleSaveStart());
        return fetch(saveUrl, { method: "POST", credentials: "same-origin" })
            .then(h.checkStatus)
            .then(h.toJson)
            .then(data => {
                if (!data || !data.success) {
                    throw new Error("Error saving schedule.");
                } else {
                    dispatch(notifyGreen('Saved schedule "<strong>' + name + '</strong>".'));

                    if (onSaveCallback) {
                        onSaveCallback(data.id);
                    } else {
                        dispatch(setScheduleModeAndEditId("list", null, isScheduleTemplate));
                        dispatch(requestScheduleList(companyId));
                    }
                }
                dispatch(scheduleSaveFinish());
            })
            .catch(error => {
                dispatch(scheduleSaveFinish());
                h.error("Error saving schedule.", error);
            });
    };

// Are we saving?  Not very sophisticated

export const scheduleSaveStart = () => ({
    type: "SCHEDULE_SAVE_START",
});

export const scheduleSaveFinish = () => ({
    type: "SCHEDULE_SAVE_FINISH",
});

// Triggers
export const requestNewScheduleTrigger =
    (scheduleId, actionName, argument1, argument2, argument3, sort, onAddCallback) => (dispatch /*, getState*/) => {
        const saveUrl =
            "/ScheduleTrigger/Add?" + h.serialize({ scheduleId, actionName, argument1, argument2, argument3, sort });

        dispatch(scheduleSaveStart());
        return fetch(saveUrl, { method: "POST", credentials: "same-origin" })
            .then(h.checkStatus)
            .then(h.toJson)
            .then(data => {
                if (!data || !data.id) {
                    throw new Error("Error creating trigger.");
                }

                dispatch(notifyGreen("Saved new trigger"));

                dispatch(requestScheduleTriggerList(scheduleId));
                dispatch(scheduleSaveFinish());

                if (typeof onAddCallback == "function") {
                    onAddCallback(data.id);
                }
            })
            .catch(error => {
                dispatch(scheduleSaveFinish());
                h.error("Error creating trigger.", error);
            });
    };

export const requestEditScheduleTrigger =
    (id, scheduleId, actionName, argument1, argument2, argument3, sort, isActive, dateUpdated, companyId = null) =>
    (dispatch /*, getState*/) => {
        const saveUrl =
            "/ScheduleTrigger/Edit?" +
            h.serialize({ id, scheduleId, actionName, argument1, argument2, argument3, sort, isActive, dateUpdated });

        dispatch(scheduleSaveStart());
        return fetch(saveUrl, { method: "POST", credentials: "same-origin" })
            .then(h.checkStatus)
            .then(h.toJson)
            .then(data => {
                if (!data || !data.result || data.result != "success") {
                    throw new Error("Error editing trigger.");
                }
                debouncedAlertUpdatedTrigger(dispatch);
                debouncedRequestScheduleList(dispatch, companyId);
                debouncedRequestScheduleTriggerList(dispatch, scheduleId);
                dispatch(scheduleSaveFinish());
            })
            .catch(error => {
                dispatch(scheduleSaveFinish());
                h.error("Error editing trigger.", error);
            });
    };

export const executeScheduleNow = id => dispatch => {
    dispatch(notifyGreen("Execute schedule submitted."));

    fetch("/Schedule/RunScheduleNow?" + h.serialize({ id }), { method: "POST", credentials: "same-origin" })
        .then(h.checkStatus)
        .then(h.toJson)
        .then(data => {
            if (!data || !data.result || data.result != "success") {
                throw new Error("Error executing schedule.");
            }
            dispatch(notifyGreen("Schedule executed successfully."));
        })
        .catch(error => {
            dispatch(scheduleSaveFinish());
            h.error("Error executing schedule.", error);
        });
};

export const scheduleSetUnsavedChanges = value => ({
    type: "SCHEDULE_SET_UNSAVED_CHANGES",
    value,
});
