import { Dispatch } from "../types/types";
import { Destination } from "../types/types";
import {
    IUserCompany,
    IGetUserCompanies,
    ISetSelectedCompany,
    ISetLoadingCompany,
    ISetCompanyDestinations,
} from "../types/stores/adminCompanies";
import h from "../helpers";
import { request } from "../helpers/httpInterceptor";
import { notifyGreen } from "./notifyActions";
import {
    GET_USER_COMPANIES,
    EDIT_MAXIMUM_SEATS,
    SET_SELECTED_COMPANY,
    LOADING_COMPANIES,
} from "../reducers/adminCompanies";

export const GetUserCompanies = (data: Array<IUserCompany>): IGetUserCompanies => ({
    type: GET_USER_COMPANIES,
    userCompanies: data,
});

export const setSelectedCompany = (companyId: number): ISetSelectedCompany => ({
    type: SET_SELECTED_COMPANY,
    companyId,
});

export const setLoadingCompany = (loading: boolean): ISetLoadingCompany => ({
    type: LOADING_COMPANIES,
    loading,
});

export const getUserCompanies =
    () =>
    (dispatch: Dispatch): void => {
        dispatch(setLoadingCompany(true));
        return request("/Companies/GetUserCompanies", { credentials: "same-origin" }, dispatch)
            .then(h.checkStatus)
            .then(h.toJson)
            .then(data => {
                dispatch(setLoadingCompany(false));
                dispatch(GetUserCompanies(data));
            })
            .catch(error => {
                dispatch(setLoadingCompany(false));
                h.error("Error getting user companies.", error);
            });
    };
export const getUserCompaniesAndAssets =
    () =>
    (dispatch: Dispatch): void =>
        request("/Companies/GetUserCompanies?includeAssets=true", { credentials: "same-origin" }, dispatch)
            .then(h.checkStatus)
            .then(h.toJson)
            .then(data => {
                dispatch(GetUserCompanies(data));
            })
            .catch(error => {
                h.error("Error getting user companies.", error);
            });

export const editMaximumSeats =
    (companyId: number, maxSeats: number) =>
    (dispatch: Dispatch): void => {
        let token = "";
        if (document.getElementsByName("__RequestVerificationToken").length > 0) {
            token = (document.getElementsByName("__RequestVerificationToken")[0] as HTMLInputElement).value;
        }
        return request(
            "/Companies/EditMaximumSeats",
            {
                credentials: "same-origin",
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    __RequestVerificationToken: token,
                },
                body: JSON.stringify({
                    companyId,
                    maxSeats,
                }),
            },
            dispatch
        )
            .then(h.checkStatus)
            .then(h.toJson)
            .then(() => {
                dispatch({
                    type: EDIT_MAXIMUM_SEATS,
                });
            })
            .catch(error => {
                h.error("Error editing maximum seats.", error);
            });
    };

export const createTableauProject =
    (companyId: number) =>
    (dispatch: Dispatch): void => {
        request(
            `/Companies/TableauProject?companyId=${companyId}`,
            {
                method: "POST",
                credentials: "same-origin",
            },
            dispatch
        )
            .then(h.checkStatus)
            .then(h.toJson)
            .then(data => {
                if (data.success) {
                    dispatch(notifyGreen("Tableau project created successfully."));
                } else {
                    h.error("Error creating Tableau project.");
                }
            })
            .catch(error => {
                h.error("Error creating Tableau project.", error);
            });
    };

export const setCompanyDestinations = (
    companyId: number,
    destinations: Array<Destination>
): ISetCompanyDestinations => ({
    type: "SET_COMPANY_DESTINATIONS",
    companyId,
    destinations,
});

export const requestCompanyDestinations =
    (companyId: number) =>
    (dispatch: Dispatch): void => {
        request(
            `/ClientAccess/GetPartnersByCompanyId?companyId=${companyId}`,
            {
                method: "POST",
                credentials: "same-origin",
            },
            dispatch
        )
            .then(h.checkStatus)
            .then(h.toJson)
            .then(data => {
                if (data.partners && data.partners.length > 0) {
                    dispatch(setCompanyDestinations(companyId, data.partners));
                }
            })
            .catch(error => {
                h.error("Error loading partners.", error);
            });
    };
