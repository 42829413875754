import h from "../helpers";
import { ToggleIsLayoutTreeLoading } from "./treeBehaviorActions";
import { SET_DESTINATION_DEDUPE_SETTINGS } from "../reducers/vars";

if (!top.RDX) {
    top.RDX = {};
}

export const clearSelectedLayout = () => ({
    type: "CLEAR_SELECTED_LAYOUT",
});
top.RDX.clearSelectedLayout = () => top.store.dispatch(clearSelectedLayout());

export const setSelectedLayout = Id => ({
    type: "SET_SELECTED_LAYOUT",
    Id,
});
top.RDX.setSelectedLayout = Id => top.store.dispatch(setSelectedLayout(Id));

export const setSelectedGlobalVariable = Id => ({
    type: "SET_SELECTED_GLOBAL_VARIABLE",
    Id,
});
top.RDX.setSelectedGlobalVariable = Id => top.store.dispatch(setSelectedGlobalVariable(Id));

// export const setClientLayoutField = ()

export const setSelectedLayoutObjects = (objects = {}, loading = false) => ({
    type: "SET_LAYOUT_SELECTED_OBJECTS",
    loading,
    objects,
});
top.RDX.setSelectedLayoutObjects = (objects, loading) => top.store.dispatch(setSelectedLayoutObjects(objects, loading));

export const setSelectedTaxonomyLayoutObjects = (taxonomyObjects = {}, loading = false) => ({
    type: "SET_LAYOUT_SELECTED_TAXONOMY_OBJECTS",
    loading,
    taxonomyObjects,
});
top.RDX.setSelectedTaxonomyLayoutObjects = (taxonomyObjects, loading) =>
    top.store.dispatch(setSelectedTaxonomyLayoutObjects(taxonomyObjects, loading));

export const setAllSelectedLayoutObjects = (objects = {}) => ({
    type: "SET_ALL_LAYOUT_OBJECTS",
    objects,
});
top.RDX.setAllSelectedLayoutObjects = objects => top.store.dispatch(setAllSelectedLayoutObjects(objects));

export const setClientField = field => ({
    type: "SET_CLIENT_FIELD_OBJECT",
    field,
});
top.RDX.setClientField = field => top.store.dispatch(setClientField(field));
export const requestClientField = () => dispatch => {
    const url = "/LayoutsMain/GetClientField";
    fetch(url, {
        credentials: "same-origin",
        method: "POST",
        headers: {
            "Content-Type": "application/json; charset=utf-8",
        },
    })
        .then(h.checkStatus)
        .then(h.toJson)
        .then(data => {
            if (data.success) {
                dispatch(setClientField(data.field));
            }
        })
        .catch(error => {
            h.error("Error loading client field." + error, error);
        });
};

export const requestClientFieldByPartner = partnerId => dispatch => {
    const url = "/LayoutsMain/GetClientFieldByPartner?PartnerId=" + partnerId;
    fetch(url, {
        credentials: "same-origin",
        method: "POST",
        headers: {
            "Content-Type": "application/json; charset=utf-8",
        },
    })
        .then(h.checkStatus)
        .then(h.toJson)
        .then(data => {
            if (data.success) {
                dispatch(setClientField(data.field));
            }
        })
        .catch(error => {
            h.error("Error loading client field." + error, error);
        });
};

export const requestFileExtensions = () => dispatch => {
    const url = "/LayoutsMain/GetFileExtensions";
    fetch(url, {
        credentials: "same-origin",
        method: "POST",
        headers: {
            "Content-Type": "application/json; charset=utf-8",
        },
    })
        .then(h.checkStatus)
        .then(h.toJson)
        .then(data => {
            if (data.success) {
                dispatch({ type: "SET_FILE_EXTENSIONS", fileExtensions: data.extensions });
            }
        })
        .catch(error => {
            h.error("Error loading client field." + error, error);
        });
};

export const layoutClearButton = () => (dispatch, getState) => {
    // Look to see if unsaved changes
    const state = getState();
    const layout = state.layouts[state.selected.layout] || {};
    if (layout.hasUnsavedChanges) {
        dispatch(setVar("loss_of_work_queued_action", clearSelectedLayout()));
        dispatch(setVar("loss_of_work_canceled_queued_action", {}));
        dispatch(showModal("LOSS_OF_WORK", { layout }));
    } else {
        dispatch(setVar("loss_of_work_queued_action", {}));
        dispatch(setVar("loss_of_work_canceled_queued_action", {}));
        dispatch(clearSelectedLayout());
    }
};

export const setSelectedLayout2 = layoutId => (dispatch, getState) => {
    // Look to see if unsaved changes
    const state = getState();
    const layout = state.layouts[state.selected.layout] || {};
    if (layout.hasUnsavedChanges) {
        dispatch(
            setVar("loss_of_work_queued_action", () => {
                dispatch(requestSelectedLayoutObjects(layoutId));
                dispatch(updateLayout(layout.LayoutID, false));
            })
        );
        dispatch(setVar("loss_of_work_canceled_queued_action", {}));
        dispatch(showModal("LOSS_OF_WORK", { layout }));
    } else {
        dispatch(setVar("loss_of_work_queued_action", {}));
        dispatch(setVar("loss_of_work_canceled_queued_action", {}));
        dispatch(requestSelectedLayoutObjects(layoutId));
    }
};

export const updateLayoutAndPositions = selectedLayout => (dispatch, getState) => {
    const state = getState();
    let selectedLayoutObjects = state.layoutObjects.selectedLayoutObjects;
    let totalLength = 1;

    // Set width, start, end, order, sort order, and sort direction if it doesn't exits.
    selectedLayoutObjects.forEach(x => {
        x.Start = totalLength;
        totalLength += x.Width == "" ? 0 : parseInt(x.Width);
        x.End = totalLength - 1;
    });

    let selectedTaxonomyLayoutObjects = state.layoutObjects.selectedTaxonomyLayoutObjects;
    totalLength = 1;

    // Set width, start, end, order, sort order, and sort direction if it doesn't exits.
    selectedTaxonomyLayoutObjects.forEach(x => {
        x.Start = totalLength;
        totalLength += x.Width == "" ? 0 : parseInt(x.Width);
        x.End = totalLength - 1;
    });

    dispatch(updateLayout(selectedLayout, true));
};

///// MODALS ////
import { showModal, setVar, incAjaxCount, decAjaxCount } from "./actionCreators";

// requestSelectedLayoutObjects
export const requestSelectedLayoutObjects = Id => dispatch => {
    dispatch(setSelectedLayoutObjects([], true));
    dispatch({ type: "LOADING_LAYOUT_OBJECTS", isLoading: true });

    const url = "/LayoutsMain/GetAvailableObjectsForLayout";
    dispatch(setSelectedLayout(parseInt(Id)));
    fetch(url, {
        credentials: "same-origin",
        method: "POST",
        headers: {
            "Content-Type": "application/json; charset=utf-8",
        },
        body: JSON.stringify({
            layoutId: Id,
        }),
    })
        .then(h.checkStatus)
        .then(h.toJson)
        .then(data => {
            if (data.success) {
                dispatch({ type: "SET_LAYOUT_SELECTED_FILES", selectedLayoutFiles: data.layoutFiles });
                dispatch(setSelectedLayoutObjects(data.objects, false));
                dispatch(setSelectedTaxonomyLayoutObjects(data.taxonomyObjects, false));
            }
            dispatch({ type: "LOADING_LAYOUT_OBJECTS", isLoading: false });
        })
        .catch(error => {
            h.error("Error loading layout objects." + error, error);
            dispatch({ type: "LOADING_LAYOUT_OBJECTS", isLoading: false });
        });
};

import { loadSingleViewDataVars } from "./flowActions";
export const requestDestinations = () => (dispatch, getState) => {
    //updates null to [] so that other components don't do the call while this one is running!
    const url = "/ClientAccess/GetMyPartners";
    const state = getState();
    dispatch(incAjaxCount());
    dispatch(setLoadingDestinations(true));
    return fetch(url, { credentials: "same-origin", method: "POST" })
        .then(h.checkStatus)
        .then(h.toJson)
        .then(data => {
            dispatch(decAjaxCount());
            dispatch(setLoadingDestinations(false));
            if (data.partners && data.partners.length > 0) {
                dispatch(loadSingleViewDataVars(data.partners));
                if (data.partners.find(x => x.DeploySetting == DeploySettings.DeployXandrTemplate)) {
                    // if there are any Xandr deployment destinations and rate categories are not loaded, trigger the request.
                    if (!state.xandrRateCategories.categories || state.xandrRateCategories.categories.length <= 0) {
                        dispatch(requestXandrRateCategories());
                    }
                }
            }
            if (data.fieldRestrictions && data.fieldRestrictions.length > 0) {
                dispatch(loadFieldRestrictions(data.fieldRestrictions));
            }
            if (data.destinationDedupeSettings) {
                dispatch({
                    type: SET_DESTINATION_DEDUPE_SETTINGS,
                    destinationDedupeSettings: data.destinationDedupeSettings,
                });
            }
        })
        .catch(error => {
            dispatch(decAjaxCount());
            dispatch(loadSingleViewDataVars(null));
            dispatch(setLoadingDestinations(false));
            h.error("Error loading partners.", error);
        });
};

export const requestDestinationsByAccess = () => dispatch => {
    const url = "/ClientAccess/GetDestinationsByAccess";
    dispatch(incAjaxCount());
    dispatch(setLoadingDestinations(true));
    fetch(url, { credentials: "same-origin", method: "POST" })
        .then(h.checkStatus)
        .then(h.toJson)
        .then(data => {
            dispatch(decAjaxCount());
            dispatch(setLoadingDestinations(false));
            dispatch(setAvailableDestinations(data.availableDestinations || []));
            dispatch(setRequestableDestinations(data.requestableDestinations || []));
            dispatch(setDestinationGroups(data.destinationGroups || []));
        })
        .catch(error => {
            dispatch(decAjaxCount());
            dispatch(setAvailableDestinations([]));
            dispatch(setRequestableDestinations([]));
            dispatch(setDestinationGroups([]));
            dispatch(setLoadingDestinations(false));
            h.error("Error loading Destinations by Access.", error);
        });
};

export const setAvailableDestinations = (availableDestinations: boolean) => ({
    type: "SET_AVAILABLE_DESTINATIONS",
    availableDestinations,
});

export const setRequestableDestinations = (requestableDestinations: boolean) => ({
    type: "SET_REQUESTABLE_DESTINATIONS",
    requestableDestinations,
});

export const setLoadingDestinations = (loadingDestinations: boolean) => ({
    type: "SET_LOADING_DESTINATIONS",
    loadingDestinations,
});

export const setDestinationGroups = destinationGroups => ({
    type: "SET_DESTINATION_GROUPS",
    destinationGroups,
});

export const loadFieldRestrictions = fieldRestrictions => ({
    type: "LOAD_DESTINATION_FIELD_RESTRICTIONS",
    fieldRestrictions,
});
top.RDX.loadFieldRestrictions = fieldRestrictions => top.store.dispatch(loadFieldRestrictions(fieldRestrictions));

import { notifyGreen } from "./notifyActions";
import { DeploySettings } from "../types/stores/companyTable";
import { requestXandrRateCategories } from "./xandrRateCategoryActions";
export const deleteRestrictedField =
    (fieldKey, companyID = 0) =>
    dispatch => {
        let url = "";
        if (companyID == 0) {
            url = `/Field/DeleteRestrictedField?fieldKey=${fieldKey}`;
        } else {
            url = `/Field/DeleteRestrictedField?fieldKey=${fieldKey}&companyID=${companyID}`;
        }
        dispatch(incAjaxCount());
        fetch(url, { credentials: "same-origin", method: "POST" })
            .then(h.checkStatus)
            .then(h.toJson)
            .then(data => {
                dispatch(decAjaxCount());
                if (data.success) {
                    dispatch(requestDestinations());
                    dispatch(notifyGreen("Restricted Field successfully removed."));
                } else {
                    h.error("Error deleting restricted field1.", data);
                }
            })
            .catch(error => {
                dispatch(decAjaxCount());
                h.error("Error deleting restricted field.", error);
            });
    };

export const addRestrictedField =
    (fieldKey, companyID = 0) =>
    (dispatch, getState) => {
        const state = getState();
        const companyId = companyID == 0 ? state.session.companyId : companyID;
        const restrictedFields = state.vars.destinationFieldRestrictions;
        if (!restrictedFields) {
            h.error("Error checking against existing restricted fields.");
            return;
        }

        const exists = restrictedFields.filter(x => x.DestinationId == companyId && x.FieldKey == fieldKey).length > 0;
        if (exists) {
            h.error("Field already exists in the restricted list.");
        } else {
            let url = "";
            if (companyID == 0) {
                url = `/Field/AddRestrictedField?fieldKey=${fieldKey}`;
            } else {
                url = `/Field/AddRestrictedField?fieldKey=${fieldKey}&companyID=${companyId}`;
            }
            dispatch(incAjaxCount());
            fetch(url, { credentials: "same-origin", method: "POST" })
                .then(h.checkStatus)
                .then(h.toJson)
                .then(data => {
                    dispatch(decAjaxCount());
                    if (data.success) {
                        dispatch(requestDestinations());
                        dispatch(notifyGreen("Restricted Field successfully added."));
                    } else {
                        h.error("Error adding restricted field.");
                    }
                })
                .catch(error => {
                    dispatch(decAjaxCount());
                    h.error("Error adding restricted field.", error);
                });
        }
    };

// POST Discrepancy
export const requestAllLayoutObjects = () => dispatch => {
    const url = "/LayoutsMain/GetLayoutsAndObjects";

    fetch(url, {
        credentials: "same-origin",
    })
        .then(h.checkStatus)
        .then(h.toJson)
        .then(data => {
            if (data) {
                dispatch(setAllSelectedLayoutObjects(data.items));
            }
        })
        .catch(error => {
            h.error("Error loading layout objects." + error, error);
        });
};

export const updateDestinationPrimaryIdObj = (layoutId, destinationId) => (dispatch, getState) => {
    const state = getState();
    const layouts = state.layoutObjects.objects;
    const url = "/Layouts/GetDestinationMatchKey?destinationId=" + destinationId;
    let objects = [];
    fetch(url, { credentials: "same-origin", method: "POST" })
        .then(h.checkStatus)
        .then(h.toJson)
        .then(data => {
            if (layouts != null) {
                let thisLayout = layouts.filter(x => x.Layout.LayoutID == layoutId);
                if (thisLayout.length > 0) {
                    objects = thisLayout[0].LayoutObjects;
                    objects.forEach(x => {
                        if (x.ObjectType == 5) {
                            //update exportname/displayname to data.destinationKey
                            if (x.ExportName == "Primary Id") {
                                dispatch(updateThisLayoutDestination(layoutId, data.destinationKey));
                            } else {
                                dispatch(updateThisLayoutDestination(layoutId, x.ExportName));
                            }
                        }
                    });
                }
            }
        })
        .catch(error => {
            h.error("Error fetching destination match.", error);
        });
};

export const updateThisLayoutDestination = (LayoutId, ExportName) => ({
    type: "UPDATE_THIS_LAYOUT_DESTINATION",
    LayoutId,
    ExportName,
});
top.RDX.updateThisLayoutDestination = (LayoutId, ExportName) =>
    top.store.dispatch(updateThisLayoutDestination(LayoutId, ExportName));

export const updateLayout = (Id, Unsaved) => ({
    type: "UPDATE_LAYOUT",
    Id,
    Unsaved,
});
top.RDX.updateLayout = (Id, Unsaved) => top.store.dispatch(updateLayout(Id, Unsaved));

export const requestLayoutList =
    (mergeBehavior = "merge") =>
    dispatch => {
        const url = "/LayoutsMain/GetLayoutsByCompany?" + h.serialize({});

        dispatch(ToggleIsLayoutTreeLoading(true));
        return fetch(url, { method: "POST", credentials: "same-origin" })
            .then(h.checkStatus)
            .then(h.toJson)
            .then(data => {
                const layoutList = {};
                if (data.layouts.length > 0) {
                    $.each(data.layouts, (key, item) => {
                        layoutList[item.LayoutID] = item;
                    });
                    dispatch(updateLayoutList(layoutList, mergeBehavior));
                }

                const typesList = data.LayoutTypes.map(item => ({ label: item.Type || "None", value: item.TypeId }));
                dispatch(setSingleViewFields(data.svFields));
                dispatch(setLayoutTypes(typesList, mergeBehavior));
                dispatch(requestCassReports());
                dispatch(ToggleIsLayoutTreeLoading(false));
            })
            .catch(error => {
                dispatch(ToggleIsLayoutTreeLoading(false));
                h.error("Error loading layouts.", error);
            });
    };
top.RDX.requestLayoutList = (mergeBehavior = "merge") => top.store.dispatch(requestLayoutList(mergeBehavior));

export const requestLayoutTypes =
    (mergeBehavior = "merge") =>
    dispatch => {
        dispatch(incAjaxCount());
        fetch("/LayoutsMain/GetLayoutTypes", { method: "POST", credentials: "same-origin" })
            .then(h.checkStatus)
            .then(h.toJson)
            .then(data => {
                const typesList = data.LayoutTypes.map(item => ({ label: item.Type || "None", value: item.TypeId }));
                dispatch(setLayoutTypes(typesList, mergeBehavior));
                dispatch(decAjaxCount());
            })
            .catch(error => {
                h.error("Error loading file types.", error);
                dispatch(decAjaxCount());
            });
    };

export const requestCassReports =
    (mergeBehavior = "merge") =>
    dispatch => {
        dispatch(incAjaxCount());
        fetch("/LayoutsMain/GetCassReports", { method: "POST", credentials: "same-origin" })
            .then(h.checkStatus)
            .then(h.toJson)
            .then(data => {
                const cassReportsList = data.CassReports.map(item => ({
                    label: item.ReportName || "None",
                    value: item.CassReportId,
                }));
                dispatch(setCassReports(cassReportsList, mergeBehavior));
                dispatch(decAjaxCount());
            })
            .catch(error => {
                h.error("Error loading Cass Reports.", error);
                dispatch(decAjaxCount());
            });
    };
top.RDX.requestCassReports = () => top.store.dispatch(requestCassReports());

export const setSingleViewFields = (svFields = []) => ({
    type: "SET_LAYOUT_SVFIELDS",
    svFields,
});
top.RDX.setSingleViewFields = svFields => top.store.dispatch(setSingleViewFields(svFields));

export const moveLayoutToFolder = (layoutId, folderId) => ({
    type: "MOVE_LAYOUT_TO_FOLDER",
    layoutId,
    folderId,
});

export const requestMoveLayoutToFolder = (layoutId: number, folderId: number) => (dispatch, getState) => {
    const state = getState();
    if (state.session.companyId != state.layouts[layoutId].LayoutCompanyID) {
        h.error("Cannot move another company's layout.");
        return;
    }

    dispatch(moveLayoutToFolder(layoutId, folderId));
    // Then make request to server
    dispatch(incAjaxCount());
    const moveUrl =
        "/Layouts/MoveLayout?" +
        h.serialize({
            layoutId,
            folderId,
        });
    fetch(moveUrl, { method: "POST", credentials: "same-origin" })
        .then(h.checkStatus)
        .then(h.toJson)
        .then(() => {
            dispatch(decAjaxCount());
        })
        .catch(error => {
            dispatch(decAjaxCount());
            h.error("Error moving layout", error);
        });
};

export const requestGlobalVariables = () => dispatch => {
    const url = "/LayoutsMain/GetGlobalVariables?" + h.serialize({});
    return fetch(url, { credentials: "same-origin" })
        .then(h.checkStatus)
        .then(h.toJson)
        .then(data => {
            const variables = {};

            if (data.gVariables.length > 0) {
                $.each(data.gVariables, (key, item) => {
                    variables[item.Id] = item;
                });
                dispatch({ type: "SET_GLOBAL_VARIABLES", variables });
            }
        })
        .catch(error => {
            h.error("Error loading global variables.", error);
        });
};
top.RDX.requestGlobalVariables = () => top.store.dispatch(requestGlobalVariables());

export const setIsDeploySeparateFiles = layouts => ({
    type: "SET_LAYOUT_ISDEPLOYSEPARATEFILES",
    layouts,
});
top.RDX.setIsDeploySeparateFiles = layouts => top.store.dispatch(setIsDeploySeparateFiles(layouts));

export const setIsActiveDestination = layouts => ({
    type: "SET_LAYOUT_ISACTIVEDESTINATION",
    layouts,
});
top.RDX.setIsActiveDestination = layouts => top.store.dispatch(setIsActiveDestination(layouts));

export const setIsDestination = layouts => ({
    type: "SET_LAYOUT_ISDESTINATION",
    layouts,
});
top.RDX.setIsDestination = layouts => top.store.dispatch(setIsDestination(layouts));

export const setIsFixed = layouts => dispatch => {
    dispatch({
        type: "SET_LAYOUT_ISFIXED",
        layouts,
    });
};

export const updateLayoutList = (layouts, mergeBehavior = "merge") => ({
    type: "UPDATE_LAYOUT_LIST",
    layouts,
    mergeBehavior,
});
top.RDX.updateLayoutList = (layouts, mergeBehavior = "merge") =>
    top.store.dispatch(updateLayoutList(layouts, mergeBehavior));

export const setLayoutTypes = (layoutTypes = []) => ({
    type: "SET_LAYOUT_TYPES",
    layoutTypes,
});
top.RDX.setLayoutTypes = layoutTypes => top.store.dispatch(setLayoutTypes(layoutTypes));

export const setCassReports = (cassReports = []) => ({
    type: "SET_CASS_REPORTS",
    cassReports,
});
top.RDX.setCassReports = cassReports => top.store.dispatch(setCassReports(cassReports));

export const setCompressionTypes = (compressionTypes = {}) => ({
    type: "SET_COMPRESSION_TYPES",
    compressionTypes,
});
top.RDX.setCompressionTypes = compressionTypes => top.store.dispatch(setCompressionTypes(compressionTypes));

export const newLayout = () => dispatch => {
    dispatch({ type: "NEW_LAYOUT" });
    // dispatch(requestClientField());
    dispatch(setSelectedLayoutObjects([], false));
    AddFieldToLayoutObjects(null, null);
};

export const startNewLayout = name => dispatch => {
    dispatch({ type: "NEW_LAYOUT_CREATE", name });

    // nulls
    dispatch(setSelectedLayoutObjects([], false));
    dispatch(setSelectedLayout2(0));
};

top.RDX.newLayout = () => top.store.dispatch(newLayout());

// AddSegmentToLayoutObjects

export const AddFieldToLayoutObjects = (field, layoutId, isTaxonomy) => (dispatch, getState) => {
    let state = getState();
    const selectedLayout = state.selected.layout;
    let type = isTaxonomy ? "ADD_TAXONOMY_LAYOUT_OBJECT" : "ADD_LAYOUT_OBJECT";

    if (selectedLayout === null) {
        dispatch(newLayout());
        dispatch({
            type,
            object: {
                LayoutId: 0,
                ObjectType: 1,
                ObjectId: field,
                DisplayName: state.layoutfields.byId[field].text,
                ExportName: state.layoutfields.byId[field].text,
                Width:
                    state.layoutfields.byId[field].FieldSubType == "double"
                        ? state.layoutfields.byId[field].FieldSize == 0
                            ? state.layoutfields.byId[field].FieldPrec
                            : state.layoutfields.byId[field].FieldSize
                        : state.layoutfields.byId[field].FieldSize,
                DefaultValue: "",
                IsValid: true,
                IsExternal: state.layoutfields.byId[field].IsPII,
            },
        });
        state = getState();
    } else {
        dispatch({
            type,
            object: {
                LayoutId: state.selected.layout,
                ObjectType: 1,
                ObjectId: field,
                DisplayName: state.layoutfields.byId[field].text,
                ExportName: state.layoutfields.byId[field].text,
                Width:
                    state.layoutfields.byId[field].FieldSubType == "double"
                        ? state.layoutfields.byId[field].FieldSize == 0
                            ? state.layoutfields.byId[field].FieldPrec
                            : state.layoutfields.byId[field].FieldSize
                        : state.layoutfields.byId[field].FieldSize,
                DefaultValue: "",
                IsValid: true,
                IsExternal: state.layoutfields.byId[field].IsPII,
            },
        });

        dispatch({
            type: "UPDATE_LAYOUT",
            Id: layoutId == null ? 0 : layoutId,
            Unsaved: true,
        });
    }
    dispatch(updateLayoutAndPositions(layoutId));
};

export const AddClientFieldToLayoutObjects = (layoutId, isTaxonomy) => (dispatch, getState) => {
    let state = getState();
    let type = isTaxonomy ? "ADD_TAXONOMY_LAYOUT_OBJECT" : "ADD_LAYOUT_OBJECT";
    const selectedLayout = state.selected.layout;
    if (selectedLayout === null) {
        dispatch(newLayout());
        dispatch({
            type,
            object: {
                LayoutId: 0,
                ObjectType: 5,
                ObjectId: state.layoutObjects.clientFieldObject.FieldUniqueKey,
                DisplayName: "Primary Id",
                ExportName: "Primary Id",
                Width:
                    state.layoutObjects.clientFieldObject.FieldSize != null
                        ? state.layoutObjects.clientFieldObject.FieldSize
                        : 10,
                DefaultValue: "",
                IsValid: true,
            },
        });
        state = getState();
    } else {
        dispatch({
            type,
            object: {
                LayoutId: state.selected.layout,
                ObjectType: 5,
                ObjectId: state.layoutObjects.clientFieldObject.FieldUniqueKey,
                DisplayName: "Primary Id",
                ExportName: "Primary Id",
                Width:
                    state.layoutObjects.clientFieldObject.FieldSize != null
                        ? state.layoutObjects.clientFieldObject.FieldSize
                        : 10,
                DefaultValue: "",
                IsValid: true,
            },
        });

        dispatch({
            type: "UPDATE_LAYOUT",
            Id: layoutId == null ? 0 : layoutId,
            Unsaved: true,
        });
    }
};

export const AddSVFieldToLayoutObjects = (svField, layoutId, isTaxonomy) => (dispatch, getState) => {
    let state = getState();
    let type = isTaxonomy ? "ADD_TAXONOMY_LAYOUT_OBJECT" : "ADD_LAYOUT_OBJECT";
    const selectedLayout = state.selected.layout;
    if (selectedLayout === null) {
        dispatch(newLayout());
        dispatch({
            type,
            object: {
                LayoutId: 0,
                ObjectType: 11,
                ObjectId: null,
                DisplayName: svField,
                ExportName: svField,
                Width: 10,
                DefaultValue: "",
                IsValid: true,
            },
        });
        state = getState();
    } else {
        dispatch({
            type,
            object: {
                LayoutId: state.selected.layout,
                ObjectType: 11,
                ObjectId: null,
                DisplayName: svField,
                ExportName: svField,
                Width: 10,
                DefaultValue: "",
                IsValid: true,
            },
        });

        dispatch({
            type: "UPDATE_LAYOUT",
            Id: layoutId == null ? 0 : layoutId,
            Unsaved: true,
        });
    }
    dispatch(updateLayoutAndPositions(layoutId));
};

export const AddGlobalVariableToLayoutObjects = (variableId, layoutId, scope, isTaxonomy) => (dispatch, getState) => {
    let state = getState();
    let type = isTaxonomy ? "ADD_TAXONOMY_LAYOUT_OBJECT" : "ADD_LAYOUT_OBJECT";
    let objectType = 0;
    switch (scope) {
        case "OfferCode":
            objectType = 9;
            break;
        case "DestinationOffer":
            objectType = 4;
            break;
        case "CampaignFlow":
            objectType = 3;
            break;
        default:
            break;
    }

    const selectedLayout = state.selected.layout;
    if (selectedLayout === null) {
        dispatch(newLayout());
        dispatch({
            type,
            object: {
                LayoutId: 0,
                ObjectType: objectType,
                ObjectId: variableId,
                DisplayName: state.clientVariables.byId[variableId].VariableName,
                ExportName: state.clientVariables.byId[variableId].VariableName,
                Width: 10,
                DefaultValue: "",
                IsValid: true,
            },
        });
        state = getState();
    } else {
        dispatch({
            type,
            object: {
                LayoutId: state.selected.layout,
                ObjectType: objectType,
                ObjectId: variableId,
                DisplayName: state.clientVariables.byId[variableId].VariableName,
                ExportName: state.clientVariables.byId[variableId].VariableName,
                Width: 10,
                DefaultValue: "",
                IsValid: true,
            },
        });

        dispatch({
            type: "UPDATE_LAYOUT",
            Id: layoutId == null ? 0 : layoutId,
            Unsaved: true,
        });
    }
    dispatch(updateLayoutAndPositions(layoutId));
};

export const AddCampaignObjectToLayoutObjects = (objectType, layoutId, isTaxonomy) => (dispatch, getState) => {
    let state = getState();
    let displayName = "";

    switch (objectType) {
        case 6:
            displayName = "Segment Code";
            break;
        case 7:
            displayName = "Test or Control Key";
            break;
        case 12:
            displayName = "Split Segment";
            break;
        case 13:
            displayName = "Sequence";
            break;
        default:
            break;
    }
    let type = isTaxonomy ? "ADD_TAXONOMY_LAYOUT_OBJECT" : "ADD_LAYOUT_OBJECT";
    const selectedLayout = state.selected.layout;
    if (selectedLayout === null) {
        dispatch(newLayout());
        dispatch({
            type,
            object: {
                LayoutId: 0,
                ObjectType: objectType,
                ObjectId: null,
                DisplayName: displayName,
                ExportName: displayName,
                Width: 10,
                DefaultValue: "",
                IsValid: true,
            },
        });
        state = getState();
    } else {
        dispatch({
            type,
            object: {
                LayoutId: state.selected.layout,
                ObjectType: objectType,
                ObjectId: null,
                DisplayName: displayName,
                ExportName: displayName,
                Width: 10,
                DefaultValue: "",
                IsValid: true,
            },
        });

        dispatch({
            type: "UPDATE_LAYOUT",
            Id: layoutId == null ? 0 : layoutId,
            Unsaved: true,
        });
    }
    dispatch(updateLayoutAndPositions(layoutId));
};

export const AddObjectToLayoutObjects = (objectType, layoutId, isTaxonomy) => (dispatch, getState) => {
    let state = getState();
    let displayName = "";
    let type = isTaxonomy ? "ADD_TAXONOMY_LAYOUT_OBJECT" : "ADD_LAYOUT_OBJECT";
    switch (objectType) {
        case 6:
            displayName = "Segment Code";
            break;
        case 7:
            displayName = "Test or Control Key";
            break;
        case 13:
            displayName = "Sequence";
            break;
        default:
            break;
    }
    const selectedLayout = state.selected.layout;
    if (selectedLayout === null) {
        dispatch(newLayout());
        dispatch({
            type,
            object: {
                LayoutId: 0,
                ObjectType: objectType,
                ObjectId: null,
                DisplayName: displayName,
                ExportName: displayName,
                Width: 10,
                DefaultValue: "",
                IsValid: true,
            },
        });
        state = getState();
    } else {
        dispatch({
            type,
            object: {
                LayoutId: state.selected.layout,
                ObjectType: objectType,
                ObjectId: null,
                DisplayName: displayName,
                ExportName: displayName,
                Width: 10,
                DefaultValue: "",
                IsValid: true,
            },
        });

        dispatch({
            type: "UPDATE_LAYOUT",
            Id: layoutId == null ? 0 : layoutId,
            Unsaved: true,
        });
    }
    dispatch(updateLayoutAndPositions(layoutId));
};

export const AddPlaceholderToLayoutObjects = (counter, layoutId, isTaxonomy) => (dispatch, getState) => {
    let state = getState();
    let type = isTaxonomy ? "ADD_TAXONOMY_LAYOUT_OBJECT" : "ADD_LAYOUT_OBJECT";
    const selectedLayout = state.selected.layout;
    if (selectedLayout === null) {
        dispatch(newLayout());
        dispatch({
            type,
            object: {
                LayoutId: 0,
                ObjectType: 2,
                ObjectId: null,
                DisplayName: "Placeholder" + counter,
                ExportName: "Placeholder" + counter,
                Width: 100,
                DefaultValue: "",
                IsValid: true,
            },
        });
        state = getState();
    } else {
        dispatch({
            type,
            object: {
                LayoutId: state.selected.layout,
                ObjectType: 2,
                ObjectId: null,
                DisplayName: "Placeholder" + counter,
                ExportName: "Placeholder" + counter,
                Width: 100,
                DefaultValue: "",
                IsValid: true,
            },
        });

        dispatch({
            type: "UPDATE_LAYOUT",
            Id: layoutId == null ? 0 : layoutId,
            Unsaved: true,
        });
    }
    dispatch(updateLayoutAndPositions(layoutId));
};

// Archive Layout -- Locally only
export const archiveLayout = (layoutID, folderId) => ({
    type: "ARCHIVE_LAYOUT",
    layoutID,
    folderId,
});

// Archive Layout -- Does API request
export const requestArchiveLayout = (layoutID, folderId) => dispatch => {
    dispatch(archiveLayout(layoutID, folderId));
    dispatch(setSelectedLayoutObjects([], false));
    dispatch(setSelectedLayout(0));
    dispatch(newLayout());

    return fetch("/LayoutsMain/ArchiveLayout", {
        method: "POST",
        credentials: "same-origin",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
        },
        body: JSON.stringify(h.addRequestVerificationToken({ layoutID })),
    })
        .then(h.checkStatus)
        .then(h.toJson)
        .then(data => {
            if (!data.success) {
                throw new Error("Error archiving layout.");
            }
        })
        .catch(error => {
            h.error("Error archiving layout.", error);
        });
};

export const recoverLayout = (layoutID, folderId) => ({
    type: "RECOVER_LAYOUT",
    layoutID,
    folderId,
});

export const recoverArchiveLayout = (layoutID, isDestination, folderId) => dispatch => {
    dispatch(recoverLayout(layoutID, folderId));
    dispatch(setSelectedLayoutObjects([], true));
    dispatch(setSelectedLayout(layoutID));

    return fetch("/LayoutsMain/RecoverLayout", {
        method: "POST",
        credentials: "same-origin",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
        },
        body: JSON.stringify(h.addRequestVerificationToken({ layoutID, isDestination })),
    })
        .then(h.checkStatus)
        .then(h.toJson)
        .then(data => {
            if (!data.success) {
                throw new Error("Error restoring layout.");
            }
        })
        .catch(error => {
            h.error("Error restoring layout.", error);
        });
};

// Delete Layout -- Locally only
export const deleteLayout2 = layoutID => ({
    type: "DELETE_LAYOUT",
    layoutID,
});

export const requestLayoutDelete2 = layoutID => dispatch => {
    dispatch(deleteLayout2(layoutID));
    dispatch(clearSelectedLayout());
    dispatch(setSelectedLayoutObjects([], false));

    return fetch("/LayoutsMain/DeleteLayout", {
        method: "POST",
        credentials: "same-origin",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
        },
        body: JSON.stringify(h.addRequestVerificationToken({ layoutID })),
    })
        .then(h.checkStatus)
        .then(h.toJson)
        .then(data => {
            if (!data.success) {
                throw new Error("Error deleting layout.");
            }
        });
};

export const requestLayoutFields = layoutId => dispatch => {
    dispatch(incAjaxCount());
    fetch("/LayoutsMain/GetLayoutFields", {
        method: "POST",
        credentials: "same-origin",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
        },
        body: JSON.stringify(h.addRequestVerificationToken({ layoutId })),
    })
        .then(h.checkStatus)
        .then(h.toJson)
        .then(data => {
            dispatch(decAjaxCount());
            if (!data.success) {
                throw new Error("Error loading layout fields.");
            }
            dispatch(updateLayoutFields(layoutId, data.layoutFields));
        })
        .catch(error => {
            dispatch(decAjaxCount());
            h.error("Error loading layout fields.", error);
        });
};

export const updateLayoutFields = (layoutId, layoutFields) => ({
    type: "UPDATE_LAYOUT_FIELDS",
    layoutId,
    layoutFields,
});
