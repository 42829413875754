import { FieldLabelsActions3, IFieldLabelsStore3 } from "../types/stores/fieldLabels3";

export const SET_FIELD_LABELS_FIELDS3 = "SET_FIELD_LABELS_FIELDS3";
export const SET_LOADING_FIELD_LABELS3 = "SET_LOADING_FIELD_LABELS3";

function fieldLabels3(
    state: IFieldLabelsStore3 = {
        labels: [],
        isLoading: false,
    },
    action: FieldLabelsActions3
): IFieldLabelsStore3 {
    switch (action.type) {
        case SET_FIELD_LABELS_FIELDS3:
            return Object.assign({}, state, {
                labels: action.labels,
            });
        case SET_LOADING_FIELD_LABELS3:
            return Object.assign({}, state, {
                isLoading: action.isLoading,
            });
        default:
            return state;
    }
}

export default fieldLabels3;
