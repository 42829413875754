import h from "../helpers";
import { getFileMasterLog } from "../actions/dashboardWidgetActions";
import { incAjaxCount, decAjaxCount } from "./actionCreators";

export const GET_MODES = "GET_MODES";
export const GET_COLUMNS_FOR_TABLE = "GET_COLUMNS_FOR_TABLE";
export const GET_AVAILABLE_TABLE = "GET_AVAILABLE_TABLE";
export const GET_COLUMNS_FOR_TABLE_KEY = "GET_COLUMNS_FOR_TABLE_KEY";
export const GET_AVAILABLE_MODELS = "GET_AVAILABLE_MODELS";
export const GET_JOINS = "GET_JOINS";
export const GET_AVAILABLE_FIELDS = "GET_AVAILABLE_FIELDS";
export const GET_AVAILABLE_FOLDERS = "GET_AVAILABLE_FOLDERS";
export const GET_IMPORTS = "GET_IMPORTS";
export const GET_TABLE_TABLES_FOR_DELETION = "GET_TABLE_TABLES_FOR_DELETION";
export const GET_EXPERIAN_TABLES_ASSETS = "GET_EXPERIAN_TABLES_ASSETS";
export const DELETE_IMPORT = "DELETE_IMPORT";
export const PROCESS_MODEL = "PROCESS_MODEL";
export const PROCESS_TABLE = "PROCESS_TABLE";
export const CHECK_COL_FOR_PII = "CHECK_COL_FOR_PII";
export const SET_TABLES_TO_SHARE = "SET_TABLES_TO_SHARE";
export const SET_ACTIVE_COMPANIES = "SET_ACTIVE_COMPANIES";
export const FILES_WAITING_UPLOAD = "FILES_WAITING_UPLOAD";

export const getModes = () => dispatch =>
    fetch("/FileImport/GetModes", {
        credentials: "same-origin",
        method: "POST",
    })
        .then(h.checkStatus)
        .then(h.toJson)
        .then(data => {
            dispatch({
                type: GET_MODES,
                modes: data.modes,
            });
        })
        .catch(error => {
            h.error("Error loading modes.", error);
        });

export const clearJoinColumns = () => dispatch => {
    dispatch({
        type: GET_COLUMNS_FOR_TABLE_KEY,
        joinColumns: [],
    });
};

export const getColumnsForTable = () => dispatch => {
    dispatch(incAjaxCount());

    fetch("/FileImport/GetColumnsForTable", {
        credentials: "same-origin",
        method: "POST",
    })
        .then(h.checkStatus)
        .then(h.toJson)
        .then(data => {
            dispatch({
                type: GET_COLUMNS_FOR_TABLE,
                joinColumns: data.columns,
            });
        })
        .catch(error => {
            h.error("Error loading table columns.", error);
        })
        .finally(() => {
            dispatch(decAjaxCount());
        });
};

export const getColumnsForTableKey = tableKey => dispatch => {
    dispatch(incAjaxCount());

    fetch("/FileImport/GetColumnsForTable?tableKey=" + tableKey, {
        credentials: "same-origin",
        method: "POST",
    })
        .then(h.checkStatus)
        .then(h.toJson)
        .then(data => {
            dispatch({
                type: GET_COLUMNS_FOR_TABLE_KEY,
                joinColumns: data.columns,
            });
        })
        .catch(error => {
            h.error("Error loading table columns.", error);
        })
        .finally(() => {
            dispatch(decAjaxCount());
        });
};

export const getColumnsForDestinationTable = companyId => dispatch => {
    dispatch(incAjaxCount());

    fetch("/FileImport/GetColumnsForDestinationTable?companyId=" + companyId, {
        credentials: "same-origin",
        method: "POST",
    })
        .then(h.checkStatus)
        .then(h.toJson)
        .then(data => {
            dispatch({
                type: GET_COLUMNS_FOR_TABLE_KEY,
                joinColumns: data.columns,
            });
        })
        .catch(error => {
            h.error("Error loading table columns.", error);
        })
        .finally(() => {
            dispatch(decAjaxCount());
        });
};

export const getAvailableTables = () => dispatch => {
    dispatch(incAjaxCount());

    fetch("/FileImport/GetAvailableTables", {
        credentials: "same-origin",
        method: "POST",
    })
        .then(h.checkStatus)
        .then(h.toJson)
        .then(data => {
            dispatch({
                type: GET_AVAILABLE_TABLE,
                processTables: data.tables,
            });
        })
        .catch(error => {
            h.error("Error loading tables for process.", error);
        })
        .finally(() => {
            dispatch(decAjaxCount());
        });
};

export const getJoins = () => dispatch => {
    dispatch(incAjaxCount());

    fetch("/FileImport/GetJoins", {
        credentials: "same-origin",
        method: "POST",
    })
        .then(h.checkStatus)
        .then(h.toJson)
        .then(data => {
            const joins = Object.keys(data.joins).map(item => ({
                label: data.joins[item].VisibleName,
                value: data.joins[item].Id,
                item: data.joins[item],
            }));

            dispatch({
                type: GET_JOINS,
                joinToTables: joins,
            });
            // dispatch({ joinToTables: data.joins, selectedJoinTo: data.crosswalk });
        })
        .catch(error => {
            h.error("Error loading joins.", error);
        })
        .finally(() => {
            dispatch(decAjaxCount());
        });
};

export const getAvailableFields = (objectId, tableName, isReport) => dispatch =>
    fetch("/FileImport/GetAvailableFields", {
        credentials: "same-origin",
        method: "POST",
        headers: {
            "Content-Type": "application/json; charset=utf-8",
        },
        body: JSON.stringify({
            objectId,
            tableName,
            isReport,
        }),
    })
        .then(h.checkStatus)
        .then(h.toJson)
        .then(data => {
            const newColumns = [];
            data.fields.forEach(col => {
                newColumns.push({
                    ColumnName: col.label,
                });
            });
            dispatch({
                type: GET_AVAILABLE_FIELDS,
                processColumns: newColumns,
            });
        })
        .catch(error => {
            h.error("Error retrieving fields.", error);
        });

export const getAvailableFolders = (companyid: ?number) => dispatch => {
    dispatch(incAjaxCount());

    fetch("/FileImport/GetAvailableFolders", {
        credentials: "same-origin",
        method: "POST",
        headers: {
            "Content-Type": "application/json; charset=utf-8",
        },
        body: JSON.stringify({
            companyid,
        }),
    })
        /* eslint-disable no-console */
        .then(h.checkStatus)
        .then(console.log("GetAvailableFolders:checkStatus" + Date().toLocaleString() + ""))
        .then(h.toJson)
        .then(console.log("GetAvailableFolders:toJson" + Date().toLocaleString() + ""))
        .then(data => {
            const results = {};

            $.each(data.results, (key, item) => {
                results[item.id] = item;
            });

            dispatch({
                type: GET_AVAILABLE_FOLDERS,
                folders: results,
            });
        })
        .catch(error => {
            h.error("Error loading folders.", error);
        })
        .finally(() => {
            dispatch(decAjaxCount());
        });
};

export const getImports = () => dispatch => {
    dispatch(incAjaxCount());

    fetch("/FileImport/GetImports", {
        credentials: "same-origin",
        method: "POST",
    })
        .then(h.checkStatus)
        .then(h.toJson)
        .then(data => {
            const imports = {};

            $.each(data.imports, (key, item) => {
                imports[item.ImportId] = item;
            });

            dispatch({
                type: GET_IMPORTS,
                files: imports,
            });
        })
        .catch(error => {
            h.error("Error loading imports.", error);
        })
        .then(() => {
            dispatch(decAjaxCount());
        });
};

export const getTableTablesForDeletion = () => dispatch =>
    fetch("/FileImport/GetTableTablesForDeletion", {
        credentials: "same-origin",
        method: "POST",
    })
        .then(h.checkStatus)
        .then(h.toJson)
        .then(data => {
            dispatch({
                type: GET_TABLE_TABLES_FOR_DELETION,
                tabletables: data.tables,
            });
        })
        .catch(error => {
            h.error("Error loading tables.", error);
        });

export const getExperianTablesAssets = () => dispatch =>
    fetch("/FileImport/GetExperianTablesAssets", {
        credentials: "same-origin",
        method: "POST",
    })
        .then(h.checkStatus)
        .then(h.toJson)
        .then(data => {
            dispatch({
                type: GET_EXPERIAN_TABLES_ASSETS,
                experianTablesAssets: data.tables,
            });
        })
        .catch(error => {
            h.error("Error loading Experian tables assets.", error);
        });

export const deleteImport =
    (id, companyId, deletePhysicalTable, deleteFromTable = false) =>
    dispatch => {
        dispatch(incAjaxCount());
        dispatch({
            type: DELETE_IMPORT,
            deletingFile: true,
        });

        fetch("/FileImport/DeleteImport", {
            credentials: "same-origin",
            method: "POST",
            headers: {
                "Content-Type": "application/json; charset=utf-8",
            },
            body: JSON.stringify({
                //add type and compressionIDS
                id,
                companyId,
                deletePhysicalTable,
            }),
        })
            .then(h.checkStatus)
            .then(h.toJson)
            .then(data => {
                if (data.success) {
                    if (deleteFromTable) {
                        if (companyId == 0) {
                            dispatch(getExperianTablesAssets());
                        } else {
                            dispatch(getTableTablesForDeletion());
                        }

                        dispatch(getFileMasterLog());
                    }
                }
            })
            .catch(error => {
                h.error("Error deleting.", error);
            })
            .then(() => {
                dispatch({
                    type: DELETE_IMPORT,
                    deletingFile: false,
                });

                dispatch(decAjaxCount());
            });
    };

export const clearDataDashboardRow = fileId => dispatch => {
    dispatch({
        type: DELETE_IMPORT,
        deletingFile: true,
    });
    fetch("/FileImport/ClearDataDashboardRow", {
        credentials: "same-origin",
        method: "POST",
        headers: {
            "Content-Type": "application/json; charset=utf-8",
        },
        body: JSON.stringify({
            //add type and compressionIDS
            fileId,
        }),
    })
        .then(h.checkStatus)
        .then(h.toJson)
        .then(data => {
            if (data.success) {
                dispatch({
                    type: DELETE_IMPORT,
                    deletingFile: false,
                });
                dispatch(getFileMasterLog());
            }
        })
        .catch(error => {
            h.error("Error deleting.", error);
        });
};

export const processTable =
    (
        folderId,
        folderName,
        newFolderName,
        companyId,
        columns,
        parentJoinId,
        masterTableKey,
        tableName,
        isReport,
        retentionDays,
        experianAssets,
        excludeBlankMetaData
    ) =>
    dispatch => {
        dispatch({
            type: PROCESS_TABLE,
            processingTable: true,
        });
        fetch("/FileImport/ProcessTable", {
            credentials: "same-origin",
            method: "POST",
            headers: {
                "Content-Type": "application/json; charset=utf-8",
            },
            body: JSON.stringify({
                folderId,
                folderName,
                newFolderName,
                companyId,
                columns,
                parentJoinId,
                masterTableKey,
                tableName,
                isReport,
                retentionDays,
                experianAssets,
                excludeBlankMetaData,
            }),
        })
            .then(h.checkStatus)
            .then(h.toJson)
            .then(() => {
                dispatch({
                    type: PROCESS_TABLE,
                    processColumns: [],
                    processingTable: false,
                });
            })
            .catch(error => {
                h.error("Error processing table.", error);
                dispatch({
                    type: PROCESS_TABLE,
                    processColumns: [],
                    processingTable: false,
                });
            });
    };

export const setForbidenColsForPII = invalidColumns => ({
    type: CHECK_COL_FOR_PII,
    forbiddenColumns: invalidColumns,
});

export const checkColForPII = cols => dispatch =>
    fetch("/FileImport/CheckColForPII", {
        credentials: "same-origin",
        method: "POST",
        headers: {
            "Content-Type": "application/json; charset=utf-8",
        },
        body: JSON.stringify({
            //add type and compressionIDS
            cols,
        }),
    })
        .then(h.checkStatus)
        .then(h.toJson)
        .then(data => {
            if (data.success) {
                if (Object.keys(data.invalidColumns).length != 0) {
                    dispatch({
                        type: CHECK_COL_FOR_PII,
                        forbiddenColumns: data.invalidColumns,
                    });
                } else {
                    dispatch({
                        type: CHECK_COL_FOR_PII,
                        forbiddenColumns: [],
                    });
                }
            }
        })
        .catch(() => {
            dispatch({
                type: CHECK_COL_FOR_PII,
                forbiddenColumns: null,
            });
        })
        .finally(() => {
            dispatch(decAjaxCount());
        });

export const requestTablesToShare = () => dispatch => {
    dispatch(incAjaxCount());
    fetch("/FileImport/GetTablesToShare", {
        credentials: "same-origin",
        method: "POST",
        headers: {
            "Content-Type": "application/json; charset=utf-8",
        },
    })
        .then(h.checkStatus)
        .then(h.toJson)
        .then(data => {
            if (data.tables) {
                dispatch({ type: SET_TABLES_TO_SHARE, tables: data.tables });
            }
        })
        .catch(() => {
            h.error("Error loading tables to share.");
        })
        .finally(() => {
            dispatch(decAjaxCount());
        });
};

export const requestActiveCompanies = () => dispatch => {
    dispatch(incAjaxCount());
    fetch("/FileImport/GetActiveCompanies", {
        credentials: "same-origin",
        method: "POST",
        headers: {
            "Content-Type": "application/json; charset=utf-8",
        },
    })
        .then(h.checkStatus)
        .then(h.toJson)
        .then(data => {
            if (data.companies) {
                dispatch({ type: SET_ACTIVE_COMPANIES, companies: data.companies });
            }
        })
        .catch(() => {
            h.error("Error loading active companies.");
        })
        .finally(() => {
            dispatch(decAjaxCount());
        });
};
