import { createSelector } from "reselect";
import { IAppState } from "../types/stores";
import {
    DeploymentDestinationActions,
    IDeploymentDestinationServer,
    IDeploymentDestinationStore,
} from "../types/stores/deploymentDestination";
/***************************/
/* DEPLOYMENT DESTINATIONS */
/***************************/
export const SET_DEPLOYMENT_DESTINATION = "SET_DEPLOYMENT_DESTINATION";
export const GET_DEPLOYMENT_DESTINATION = "GET_DEPLOYMENT_DESTINATION";
export const GET_DEPLOYMENT_DESTINATION_LIST = "GET_DEPLOYMENT_DESTINATION_LIST";
export const IS_DEPLOYMENT_DESTINATION_LOADING = "IS_DEPLOYMENT_DESTINATION_LOADING";
export const IS_UPDATING_DEPLOYMENT_DESTINATION = "IS_UPDATING_DEPLOYMENT_DESTINATION";
export const GET_DEPLOY_DESTINATION_DIFF_COMPANY_LIST = "GET_DEPLOY_DESTINATION_DIFF_COMPANY_LIST";
export const IS_UPDATING_DEPLOY_DESTINATION = "IS_UPDATING_DEPLOY_DESTINATION";

/**********************************/
/* DEPLOYMENT DESTINATION SERVERS */
/**********************************/
export const SET_DEPLOYMENT_DESTINATION_SERVERS = "SET_DEPLOYMENT_DESTINATION_SERVERS";
export const SET_DEPLOYMENT_DESTINATION_SERVER = "SET_DEPLOYMENT_DESTINATION_SERVER";
export const SET_DEPLOYMENT_DESTINATION_SERVERS_LOADING = "SET_DEPLOYMENT_DESTINATION_SERVERS_LOADING";
export const SET_DEPLOYMENT_DESTINATION_SERVERS_SAVING = "SET_DEPLOYMENT_DESTINATION_SERVERS_SAVING";

const initialState: IDeploymentDestinationStore = {
    destination: {
        Id: -1,
        CompanyId: "",
        DestinationType: "",
        DeploymentOrder: "",
        ServerDestination: "",
        Folder: "",
        SFTPProfile: "",
        DestinationDescription: "",
        ShortenName: false,
        IsDestinationDisabled: false,
        DeploymentDestinationServerId: null,
    },
    destinations: [],
    destinationsDiffCompany: [],
    isLoading: false,
    isUpdating: false,
    server: null,
    servers: [],
    serversLoading: false,
    serversSaving: false,
};

function deploymentDestination(
    state = initialState,
    action: DeploymentDestinationActions
): IDeploymentDestinationStore {
    switch (action.type) {
        case SET_DEPLOYMENT_DESTINATION:
            return Object.assign({}, state, {
                destination: action.destination,
            });
        case GET_DEPLOYMENT_DESTINATION:
            return Object.assign({}, state, {
                destination: action.destination,
            });
        case GET_DEPLOYMENT_DESTINATION_LIST:
            return Object.assign({}, state, {
                destinations: action.destinations,
            });
        case IS_DEPLOYMENT_DESTINATION_LOADING:
            return Object.assign({}, state, {
                isLoading: action.isLoading,
            });
        case IS_UPDATING_DEPLOYMENT_DESTINATION:
            return Object.assign({}, state, {
                isUpdating: action.isUpdating,
            });
        case GET_DEPLOY_DESTINATION_DIFF_COMPANY_LIST:
            return Object.assign({}, state, {
                destinationsDiffCompany: action.destinationsDiffCompany,
            });
        case SET_DEPLOYMENT_DESTINATION_SERVERS:
            return { ...state, servers: action.servers };
        case SET_DEPLOYMENT_DESTINATION_SERVER:
            return { ...state, server: action.server };
        case SET_DEPLOYMENT_DESTINATION_SERVERS_LOADING:
            return { ...state, serversLoading: action.serversLoading };
        case SET_DEPLOYMENT_DESTINATION_SERVERS_SAVING:
            return { ...state, serversSaving: action.serversSaving };
        default:
            return state;
    }
}

export default deploymentDestination;

export const getActiveServers = createSelector(
    (state: IAppState) => state.deploymentDestination.servers,
    (servers): Array<IDeploymentDestinationServer> => servers.filter(x => x.IsActive)
);
