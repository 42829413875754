import h from "../helpers";
import { request } from "../helpers/httpInterceptor";
import { flowRelatedRequestStart, flowRelatedRequestCompleted } from "./loadingActions";
import { ToggleIsFlowTreeLoading } from "./treeBehaviorActions";
import { folderReducerKeyForType } from "../helpers/folder";
import { uniqArray } from "../helpers/typedHelpers";
import { requestUserNames, setUserNames } from "./actionCreators";
import { findMinimumFolderId, pickNewFolderName, fixNameIfDuplicate } from "../reducers/_folderGeneral";
import { deleteUser } from "./userActions";

if (!top.RDX) {
    top.RDX = {};
}

export const loadFolders = (folderType, data) => ({
    type: "LOAD_FOLDERS",
    folderType,
    data,
});
top.RDX.loadFolders = (folderType, data) => top.store.dispatch(loadFolders(folderType, data));

// Ask server for folder data, then load it
// Can be a CSV of folderTypes
let overwroteUserNamesOnce = false;
export const requestLoadFolders =
    (folderType = "W,L") =>
    dispatch => {
        const folderUrl = "/Folders/GetRaw?" + h.serialize({ typeCsv: folderType.toUpperCase() });
        dispatch(flowRelatedRequestStart());

        request(folderUrl, { credentials: "same-origin" }, dispatch)
            .then(h.checkStatus)
            .then(h.toJson)
            .then(data => {
                dispatch(flowRelatedRequestCompleted());
                const userIds = [...new Set(data.map(item => item.UserKey))];
                dispatch(requestUserNames(userIds, overwroteUserNamesOnce ? "merge" : "overwrite"));
                overwroteUserNamesOnce = true;

                const types = uniqArray(data.map(x => x.Type));
                for (const folderType of types) {
                    const filteredData = data.filter(x => x.Type == folderType);
                    dispatch(loadFolders(folderType, filteredData));
                }
            })
            .catch(() => {
                dispatch(flowRelatedRequestCompleted());
            });
    };
top.RDX.requestLoadFolders = (folderType = "W,L") => top.store.dispatch(requestLoadFolders(folderType));

export const requestAdminUsers = deleteduserId => dispatch => {
    dispatch(deleteUser(deleteduserId));
};
top.RDX.requestAdminUsers = deleteduserId => top.store.dispatch(requestAdminUsers(deleteduserId));

export const requestLoadFlowFolders = () => dispatch => {
    const folderUrl = "/FlowFolder/GetFolders";
    dispatch(ToggleIsFlowTreeLoading(true));
    request(folderUrl, { method: "POST", credentials: "same-origin" }, dispatch)
        .then(h.checkStatus)
        .then(h.toJson)
        .then(data => {
            if (data.userNames) {
                dispatch(setUserNames(data.userNames));
            }

            const types = uniqArray(data.fullFolders.map(x => x.Type));
            for (const folderType of types) {
                const filteredData = data.fullFolders.filter(x => x.Type == folderType);
                dispatch(loadFolders(folderType, filteredData));
            }
            dispatch(ToggleIsFlowTreeLoading(false));
        })
        .catch(() => {
            dispatch(ToggleIsFlowTreeLoading(false));
        });
};
top.RDX.requestLoadFlowFolders = () => top.store.dispatch(requestLoadFlowFolders());

// Move Folder to Folder -- locally only
export const moveFolderToFolder = (folderType, folderId, parentFolderId) => ({
    type: "MOVE_FOLDER_TO_FOLDER",
    folderType,
    folderId,
    parentFolderId,
});

// Move Folder to Folder -- API request
export const requestMoveFolderToFolder =
    (folderType, folderId, parentFolderId, loadFlowFolders = false) =>
    (dispatch, getState) => {
        // Optimistic update, move folder on client side
        const oldState = JSON.stringify(getState());
        dispatch(moveFolderToFolder(folderType, folderId, parentFolderId));
        const newState = JSON.stringify(getState());

        // See if it changed anything, if no, the folder move wasn't allowed and we don't make API request
        if (oldState == newState) {
            return;
        }

        // Good to make API request
        const saveUrl =
            "/Folders/MoveFolder?" +
            h.serialize({
                folderId,
                newParentFolderId: parentFolderId,
            });
        return fetch(saveUrl, { method: "POST", credentials: "same-origin" })
            .then(h.checkStatus)
            .then(h.toJson)
            .then(data => {
                if (!data || !data.result || data.result != 1) {
                    throw new Error("Error moving folder.");
                }
            })
            .catch(error => {
                h.error("Error moving folder", error);
                if (loadFlowFolders) {
                    dispatch(requestLoadFlowFolders());
                } else {
                    dispatch(requestLoadFolders(folderType));
                }
            });
    };

// In the UI, mark a folder as being renamed
export const startRenamingFolder = (folderType, folderId) => ({
    type: "START_RENAMING_FOLDER",
    folderType,
    folderId,
});

// Stop renaming any folder
export const stopRenamingFolder = folderType => ({
    type: "STOP_RENAMING_FOLDER",
    folderType,
});

// Rename a folder in the local store only
export const renameFolder = (folderType, folderId, folderNewName) => ({
    type: "RENAME_FOLDER",
    folderType,
    folderId,
    folderNewName,
});

// Send a request to the server to rename a folder
export const requestRenameFolder =
    (folderType, folderId, folderNewName, loadFlowFolders = false) =>
    (dispatch, getState) => {
        const folderReducerKey = folderReducerKeyForType(folderType);
        // Currently, we can't have duplicate folder names
        folderNewName = fixNameIfDuplicate(getState().folders[folderReducerKey], folderNewName, folderId);
        // Local
        dispatch(renameFolder(folderType, folderId, folderNewName));
        dispatch(stopRenamingFolder(folderType));

        // Skip API call if folderId is negative (this means the server doesn't know about it yet)
        if (folderId < 0) {
            return;
        }

        // Now API
        const saveUrl =
            "/Folders/RenameFolder?" +
            h.serialize({
                folderId,
                newFolderName: folderNewName,
                folderType,
            });
        return fetch(saveUrl, { method: "POST", credentials: "same-origin" })
            .then(h.checkStatus)
            .then(h.toJson)
            .then(data => {
                // We actually expect the API to return [1] to mean success because of a bug
                if (data.result !== 1 && JSON.stringify(data.result) !== "[1]") {
                    h.error("Error renaming folder.");
                }
            })
            .catch(error => {
                h.error("Error renaming folder.", error);
                if (loadFlowFolders) {
                    dispatch(requestLoadFlowFolders());
                } else {
                    dispatch(requestLoadFolders(folderType));
                }
            });
    };

// Delete locally only
export const deleteFolder = (folderType, folderId) => ({
    type: "DELETE_FOLDER",
    folderType,
    folderId,
});

// Send request to the server to delete a folder
export const requestDeleteFolder = (folderType, folderId) => dispatch => {
    // Local
    dispatch(deleteFolder(folderType, folderId));

    // Now API
    const saveUrl =
        "/Folders/DeleteFolder?" +
        h.serialize({
            folderId,
        });
    return fetch(saveUrl, { method: "POST", credentials: "same-origin" })
        .then(h.checkStatus)
        .then(h.toJson)
        .then(data => {
            if (!data || !data.result || data.result != 1) {
                throw new Error("Error deleting folder.");
            }
        })
        .catch(error => {
            h.error("Error deleting folder.", error);
            dispatch(requestLoadFolders);
        });
};

// newFolder just calls createFolder but looks in the store to find the current userId
export const newFolder = (folderType, parentFolderId, newFolderName) => (dispatch, getState) => {
    const { userId, companyId } = getState().session;
    dispatch(createFolder(folderType, parentFolderId, newFolderName, userId, companyId));
};
// Create a new folder, locally
export const createFolder = (folderType, parentFolderId, newFolderName, userId, companyId) => ({
    type: "CREATE_FOLDER",
    folderType,
    parentFolderId,
    newFolderName,
    userId,
    companyId,
});

export const assignNewFolderId = (folderType, newFolderId) => ({
    type: "ASSIGN_NEW_FOLDER_ID",
    folderType,
    newFolderId,
});

// Send request to the server to create a new folder
export const requestNewFolder = (folderType, parentFolderId) => (dispatch, getState) => {
    const folderReducerKey = folderReducerKeyForType(folderType);
    // Locally
    const newFolderName = pickNewFolderName(getState().folders[folderReducerKey]);
    //let newFolderName = "New Folder" + Math.floor(Math.random() * 1000);
    dispatch(newFolder(folderType, parentFolderId, newFolderName));

    // Let the user start renaming it
    const tempFolderId = findMinimumFolderId(getState().folders[folderReducerKey]);

    dispatch(startRenamingFolder(folderType, tempFolderId));

    // API
    const saveUrl =
        "/Folders/CreateFolder?" +
        h.serialize({
            parentFolderId,
            folderName: newFolderName,
            folderType,
        });
    return fetch(saveUrl, { method: "POST", credentials: "same-origin" })
        .then(h.checkStatus)
        .then(h.toJson)
        .then(data => {
            if (!data || !data.FolderId) {
                throw new Error("Error creating folder.");
            }
            const newFolderId = data.FolderId;
            dispatch(assignNewFolderId(folderType, newFolderId));
        })
        .catch(error => {
            h.error("Error creating folder.", error);
        });
};
