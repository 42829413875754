import { notifyError } from "./actions/notifyActions";

const helpers = {
    checkStatus(response) {
        if (response.status >= 200 && response.status < 300) {
            return response;
        }
        const error = new Error(response.statusText);
        error.response = response;
        throw error;
    },
    toJson(response) {
        return response.json();
    },
    serialize(data) {
        return Object.keys(data)
            .map(keyName => encodeURIComponent(keyName) + "=" + encodeURIComponent(data[keyName]))
            .join("&");
    },
    error(message, error = null, keepDisplayed = false) {
        /* eslint-disable no-console */
        console.log(message);
        top.store.dispatch(notifyError(message, keepDisplayed));
        if (error) {
            console.log(error);
        }
        if (top.JL) {
            top.JL("React").error(message + " " + JSON.stringify(error));
        }

        if (error) {
            console.log(error);
        }
        if (top.JL) {
            top.JL("React").error(message + " " + JSON.stringify(error));
        }
    },
    throwGTMTrigger(eventName, eventCategory, eventAction, eventLabel, eventValue) {
        window.dataLayer.push({
            event: eventName,
            eventProps: {
                category: eventCategory,
                action: eventAction,
                label: eventLabel,
                value: eventValue,
            },
        });
    },
    errorSilent(message, error = null) {
        /* eslint-disable no-console */
        console.log(message);

        if (error) {
            console.log(error);
        }
        if (top.JL) {
            top.JL("React").error(message + " " + JSON.stringify(error));
        }
        /* eslint-enable no-console */
    },
    info(message) {
        if (top.JL) {
            top.JL("React").info(message);
        }
    },
    replaceAll(o, str1, str2, ignore) {
        // replaceAll: Using string o, find str1 and replace with str2, optionally ignore case
        return o.replace(
            new RegExp(str1.replace(/([\/\,\!\\\^\$\{\}\[\]\(\)\.\*\+\?\|\<\>\-\&])/g, "\\$&"), ignore ? "gi" : "g"),
            typeof str2 == "string" ? str2.replace(/\$/g, "$$$$") : str2
        );
    },
    addDiffDividersToStringDoubleQuoteIsWordBoundary(o, str1, dividerBegin, dividerEnd, ignore) {
        if (!o || !str1 || !dividerBegin || !dividerEnd || typeof o != "string") {
            return o;
        }
        // using string o, find str1 and place "divider" text before and after occurrences, optionally ignore case
        // all " marks will be transformed into \b
        dividerBegin = dividerBegin.replace(/\$/g, "$$$$");
        dividerEnd = dividerEnd.replace(/\$/g, "$$$$");

        if (!Array.isArray(str1)) {
            str1 = [str1];
        }

        const regExpElements = [];
        for (const str2 of str1) {
            let regExpText = str2.replace(/([\/\,\!\\\^\$\{\}\[\]\(\)\.\*\+\?\|\<\>\-\&])/g, "\\$&");
            regExpText = regExpText.replace(new RegExp(/"/, "g"), "\\b");
            regExpElements.push(regExpText);
        }
        const regExpText = "(" + regExpElements.join("|") + ")";

        return o.replace(new RegExp(regExpText, ignore ? "gi" : "g"), dividerBegin + "$&" + dividerEnd);
    },
    addRequestVerificationToken(object) {
        /* This doesn't work.  See
            http://stackoverflow.com/questions/2906754/how-can-i-supply-an-antiforgerytoken-when-posting-json-data-using-ajax
            Not sure I want to add the extension/override to our MVC app yet - what else might it break?
        */
        if (document.getElementsByName("__RequestVerificationToken").length > 0) {
            object.__RequestVerificationToken = document.getElementsByName("__RequestVerificationToken")[0].value;
        }
        return object;
    },
    htmlEncode(htmlvalue) {
        return htmlvalue.replace(/&/g, "&amp;").replace(/>/g, "&gt;").replace(/</g, "&lt;").replace(/"/g, "&quot;");
    },
};
export default helpers;

export const getSelectedFlowItemFromRouterProps = props =>
    props.match &&
    props.match.params &&
    props.match.params.selectedFlowItem &&
    parseInt(props.match.params.selectedFlowItem, 10);
export const getSelectedFlowFromRouterProps = props =>
    props.match &&
    props.match.params &&
    props.match.params.selectedFlow &&
    parseInt(props.match.params.selectedFlow, 10);

export const getSelectedUserFromRouterProps = match =>
    match && match.params && match.params.userId && parseInt(match.params.userId, 10);

export const fixReactSelectValue = x => (typeof x == "object" && x !== null && x.value ? x.value : x);

// shouldCancelStart - to be used with react-sortable-hoc, allows you to
// click through to <Select elements without starting sorting
export const shouldCancelStart = e => {
    // Cancel sorting if the event target is an `input`, `textarea`, `select` or `option`
    const disabledElements = ["input", "textarea", "select", "option", "button"];

    if (disabledElements.indexOf(e.target.tagName.toLowerCase()) !== -1) {
        return true; // Return true to cancel sorting
    }

    // Stock function is above
    // Below - we are letting clicks on "React-Select" pass through
    const disabledClasses = [
        "Select-arrow",
        "Select-arrow-zone",
        "Select-clear",
        "Select-clear-zone",
        "Select-value",
        "Select-value-label",
        "Select-control",
        "Select-option",
    ];

    // Also, we want to allow a 'remove' icon to be clicked
    disabledClasses.push("glyphicon-remove");

    const targetClasses = e.target.className && e.target.className.split(/\s+/);
    for (const targetClass of targetClasses) {
        if (disabledClasses.indexOf(targetClass) !== -1) {
            return true;
        }
    }

    return false;
};

//wants state by Id, will return a new id: decreasing below cero
export const makeNewId = stateById => {
    if (!stateById) {
        return 0;
    }

    const ids = Object.keys(stateById).map(x => parseInt(x, 10));
    return Math.min.apply(null, ids.concat(0)) - 1;
};

export const formatName = text => {
    // Remove all numbers and spaces at start. Trim end.
    let newValue = text.replace(/^[^a-zA-Z]+/, "").replace(/[\s]+$/, "");

    // Remove all except alphanumeric
    newValue = newValue.replace(/[^\w\s]/gi, "");

    return newValue;
};

export const formatNameMagnite = text => {
    // Only allowed numbers and letters and these 11 special characters _ > ,  /  ' . $ - ( ) %
    // All characters will be removed except for the above
    let newValue = text
        .replace(/[^0-9A-Za-z/.,'>_%()\s\-\$\?]/g, "")
        .replace(/[\s]+$/, "")
        .replace(/[\t]/, "");

    return newValue;
};
