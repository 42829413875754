import loadjs from "loadjs";

export const loadBootstrapSelect = successCallback => {
    if (!loadjs.isDefined("bootstrapSelect")) {
        loadjs(["/Content/ca/bootstrap-select.min.css", "/Content/ca/bootstrap-select.min.js"], "bootstrapSelect", {
            success: successCallback,
        });
    }
};

export const loadQueryBuilderJs = successCallback => {
    if (!loadjs.isDefined("queryBuilderJS")) {
        //commenting out the original call since group description will be the default now
        //let filesToLoad = ['/Scripts/query-builder.v2.4.1.standalone.js', '/Content/ca/interact.min.js', '/Content/query-builder.2.4.1.min.css'];
        const filesToLoad = [
            "/Scripts/query-builder.v2.4.1.standalone-with-group-description.js",
            "/Content/ca/interact.min.js",
            "/Content/query-builder.2.4.1.min.css",
        ];

        loadjs(filesToLoad, "queryBuilderJS", {
            success: () => {
                // Customization to change the "Add Group" button in query builder to NOT automatically add rules.
                // override addGroup to make addRule=undefined behaves like addRule=false
                const _addGroup = $.fn.queryBuilder.constructor.prototype.addGroup;

                $.fn.queryBuilder.constructor.prototype.addGroup = function (parent, addRule, data, flags) {
                    return _addGroup.call(this, parent, addRule === true, data, flags);
                };
                // End Customization

                if (successCallback) {
                    successCallback();
                }
            },
        });
    }
};

export const loadPlotlyJs = successCallback => {
    if (!loadjs.isDefined("plotlyJs")) {
        loadjs(["/Scripts/plotlyjs/plotly.min.js"], "plotlyJs", {
            success: successCallback,
        });
    }
};

export const loadjQueryCron = successCallback => {
    if (!loadjs.isDefined("jQueryCron")) {
        loadjs(
            [
                "/Content/jquery-cron/cron/jquery-cron.css",
                "/Content/jquery-cron/cron/jquery-cron-min.js",
                "/Content/ca/bootstrap-multiselect.min.css",
                "/Content/ca/bootstrap-multiselect.min.js",
                "/Content/ca/bootstrap-select.min.css",
                "/Content/ca/bootstrap-select.min.js",
            ],
            "jQueryCron",
            {
                success: successCallback,
            }
        );
    }
};
