import update from "immutability-helper";
import badWords from "../helpers/badWords";
import { singular } from "../helpers/pluralize";
import { createSelector } from "reselect";

const defaultState = {
    treeSearch: "",
    willClearTree: false,
    wordVecs: {},
    wordVecIsSearchingCount: 0 /* How many outstanding requests we have */,
    wordVecIsSearching: false,
    wordVecHasError: false,
    conceptualTurnedOn: true /* Don't worry, they also need the feature for this to be active */,

    // Modal Tree Search
    modalTreeSearch: "",
    modalWillClearTree: false,
    modalWordVecs: {},
    modalWordVecIsSearchingCount: 0,
    modalWordVecIsSearching: false,
    modalWordVecHasError: false,
    modalConceptualTurnedOn: true,

    discoverySearch: "",
    discoveryLastSearchedFor: "",

    discoveryAllResults: {},
    discoveryResultIdsBySearch: {},

    discoveryConceptualSearchMatches: {},
    discoveryIsSearchingCount: 0,
    discoveryIsSearching: false,
    discoveryIsErrored: false,
    discoveryIsConceptualErrored: false,
    discoveryDidYouMeans: {},
    discoverySelectedResults: new Set(),
    discoveryFlagFilters: {},
    discoveryBundleFilter: [],

    discoveryConceptualScoreThreshold: 40,
    discoveryConceptualMaxMatches: 45,

    selectedFieldLabels: [],
    selectedFieldLabels3: [],
};

function search(state = defaultState, action) {
    switch (action.type) {
        case "SET_TREESEARCH":
            return { ...state, willClearTree: false, treeSearch: action.text.trim() };
        case "SET_MODAL_TREESEARCH":
            return { ...state, modalWillClearTree: false, modalTreeSearch: action.text.trim() };
        case "CLEAR_TREESEARCH":
            return { ...state, willClearTree: true, treeSearch: "" };
        case "CLEAR_MODAL_TREESEARCH":
            return { ...state, modalWillClearTree: true, modalTreeSearch: "" };
        case "CLEAR_SEARCH_REQUEST":
            return { ...state, willClearTree: true };
        case "WORDVEC_SEARCHING": {
            const oldSearchingCount = state.wordVecIsSearchingCount;
            return update(state, {
                wordVecIsSearching: { $set: true },
                wordVecIsSearchingCount: { $set: oldSearchingCount + 1 },
            });
        }
        case "MODAL_WORDVEC_SEARCHING": {
            const oldSearchingCount = state.modalWordVecIsSearchingCount;
            return update(state, {
                modalWordVecIsSearching: { $set: true },
                modalWordVecIsSearchingCount: { $set: oldSearchingCount + 1 },
            });
        }
        case "CONCEPTUAL_TOGGLE": {
            const oldValue = state.conceptualTurnedOn;
            return update(state, {
                conceptualTurnedOn: { $set: !oldValue },
            });
        }
        case "MODAL_CONCEPTUAL_TOGGLE": {
            const oldValue = state.modalConceptualTurnedOn;
            return update(state, {
                modalConceptualTurnedOn: { $set: !oldValue },
            });
        }
        case "WORDVEC_RESULTS": {
            const oldSearchingCount = state.wordVecIsSearchingCount;
            const newSearchingCount = oldSearchingCount - 1;

            if (action.error) {
                return update(state, {
                    wordVecIsSearching: { $set: newSearchingCount > 0 },
                    wordVecIsSearchingCount: { $set: newSearchingCount },
                    wordVecHasError: { $set: true },
                });
            }

            const matches = action.matches.map(x => x[0]).filter(x => !badWords.includes(x) && x.length >= 3);
            return update(state, {
                wordVecs: {
                    [action.text]: { $set: matches },
                },
                wordVecIsSearching: { $set: newSearchingCount > 0 },
                wordVecIsSearchingCount: { $set: newSearchingCount },
                wordVecHasError: { $set: false },
            });
        }
        case "MODAL_WORDVEC_RESULTS": {
            const oldSearchingCount = state.modalWordVecIsSearchingCount;
            const newSearchingCount = oldSearchingCount - 1;

            if (action.error) {
                return update(state, {
                    modalWordVecIsSearching: { $set: newSearchingCount > 0 },
                    modalWordVecIsSearchingCount: { $set: newSearchingCount },
                    modalWordVecHasError: { $set: true },
                });
            }

            const matches = action.matches.map(x => x[0]).filter(x => !badWords.includes(x) && x.length >= 3);
            return update(state, {
                modalWordVecs: {
                    [action.text]: { $set: matches },
                },
                modalWordVecIsSearching: { $set: newSearchingCount > 0 },
                modalWordVecIsSearchingCount: { $set: newSearchingCount },
                modalWordVecHasError: { $set: false },
            });
        }
        case "WORDVEC_ERROR": {
            const oldSearchingCount = state.wordVecIsSearchingCount;
            const newSearchingCount = oldSearchingCount - 1;

            return update(state, {
                wordVecIsSearching: { $set: newSearchingCount > 0 },
                wordVecIsSearchingCount: { $set: newSearchingCount },
                wordVecHasError: { $set: true },
            });
        }
        case "MODAL_WORDVEC_ERROR": {
            const oldSearchingCount = state.modalWordVecIsSearchingCount;
            const newSearchingCount = oldSearchingCount - 1;

            return update(state, {
                modalWordVecIsSearching: { $set: newSearchingCount > 0 },
                modalWordVecIsSearchingCount: { $set: newSearchingCount },
                modalWordVecHasError: { $set: true },
            });
        }

        case "SUBMIT_DISCOVERYSEARCH": {
            return { ...state, discoveryLastSearchedFor: action.text };
        }
        case "DISCOVERYSEARCH_RESULTS": {
            const oldSearchingCount = state.discoveryIsSearchingCount;
            const newSearchingCount = oldSearchingCount - 1;

            if (action.error) {
                return update(state, {
                    discoveryIsSearching: { $set: newSearchingCount > 0 },
                    discoveryIsSearchingCount: { $set: newSearchingCount },
                    discoveryIsErrored: { $set: true },
                });
            }

            //let matches = action.matches.map(x => x[0]).filter(x => !badWords.includes(x) && x.length >= 3);
            const fixedResult = transformDataDiscoveryResult(action.result);
            const keyedResult = keyFixedDataDiscoveryResult(fixedResult);
            const resultIDs = fixedResult.map(x => x.ID);

            return update(state, {
                discoveryAllResults: { $merge: keyedResult },
                discoveryResultIdsBySearch: {
                    [action.text]: { $set: resultIDs },
                },
                discoveryConceptualSearchMatches: {
                    [action.text]: { $set: action.conceptualSearchMatches },
                },
                discoveryIsSearching: { $set: newSearchingCount > 0 },
                discoveryIsSearchingCount: { $set: newSearchingCount },
                discoveryIsErrored: { $set: false },
                discoveryIsConceptualErrored: { $set: !action.conceptualActive },
                discoveryDidYouMeans: { $set: action.didYouMeans },
            });
        }
        case "DISCOVERYSEARCH_SEARCHING": {
            const oldSearchingCount = state.discoveryIsSearchingCount;
            return update(state, {
                discoveryIsSearching: { $set: true },
                discoveryIsSearchingCount: { $set: oldSearchingCount + 1 },
            });
        }
        case "DISCOVERYSEARCH_ERROR": {
            const oldSearchingCount = state.discoveryIsSearchingCount;
            const newSearchingCount = oldSearchingCount - 1;

            return update(state, {
                discoveryIsSearching: { $set: newSearchingCount > 0 },
                discoveryIsSearchingCount: { $set: newSearchingCount },
                discoveryIsErrored: { $set: true },
            });
        }
        case "TOGGLE_SELECTED_DISCOVERY_RESULT": {
            const discoverySelectedResults = new Set(state.discoverySelectedResults);
            if (discoverySelectedResults.has(action.id)) {
                discoverySelectedResults.delete(action.id);
            } else {
                discoverySelectedResults.add(action.id);
            }
            return { ...state, discoverySelectedResults };
        }
        case "CLEAR_SELECTED_DISCOVERY_RESULT":
            return { ...state, discoverySelectedResults: new Set() };
        case "UNSELECT_DISCOVERY_RESULT_SET":
            return {
                ...state,
                discoverySelectedResults: new Set(state.discoverySelectedResults).difference(action.setOfIdsToRemove),
            };
        case "SET_DISCOVERYSEARCH":
            return { ...state, discoverySearch: action.text };
        case "SET_DISCOVERY_FLAG_FILTER":
            return update(state, {
                discoveryFlagFilters: {
                    [action.flagName]: { $set: action.value },
                },
            });
        case "SET_DISCOVERY_BUNDLE_FILTER":
            return update(state, {
                discoveryBundleFilter: { $set: action.value },
            });
        case "SET_DISCOVERY_CONCEPTUAL_MAX_MATCHES":
            return { ...state, discoveryConceptualMaxMatches: action.value };
        case "SET_DISCOVERY_CONCEPTUAL_SCORE":
            return { ...state, discoveryConceptualScoreThreshold: action.value };
        case "SET_SEARCH_LABELS": {
            return { ...state, selectedFieldLabels: action.newValue };
        }
        case "SET_SEARCH_LABELS3": {
            return { ...state, selectedFieldLabels3: action.newValue };
        }
        default:
            return state;
    }
}

export const discoveryResultsForTerm = (state, searchTerm) => {
    const ids = state.discoveryResultIdsBySearch[searchTerm];
    if (!ids) {
        return null;
    }
    let results = ids.map(x => state.discoveryAllResults[x]);

    const activeFilters = Object.keys(state.discoveryFlagFilters).filter(x => state.discoveryFlagFilters[x]);
    for (const flag of activeFilters) {
        results = results.filter(
            row =>
                row[flag + "_Flag"] &&
                row[flag + "_Flag"] != "" &&
                row[flag + "_Flag"] != "n" &&
                row[flag + "_Flag"] != "N"
        );
    }

    if (activeFilters.includes("Bundle") && state.discoveryBundleFilter) {
        const filteredBundles = state.discoveryBundleFilter.map(x => x.value);
        for (const bundle of filteredBundles) {
            results = results.filter(row => row[bundle] && row[bundle] == "1");
        }
    }

    return results;
};
// results: discoveryResultsForTerm(state.search, ownProps.searchTerm),

//let fixedResult = transformDataDiscoveryResult(action.result);
const transformDataDiscoveryResult = result => {
    if (!result || !Array.isArray(result)) {
        return result;
    }
    return result.map(x => transformDataDiscoverySingleResult(x));
};
/*
Result looks like this:
    [
        { "Key": "Enrichment_Number", "Value": "0100" },
        { "Key": "Enrichment_Field_Name", "Value": "Date Of Birth / Exact Age" },
        ...lotsmore...
    ]
We want to change it to:
{
    "Enrichment_Number": "0100",
    "Enrichment_Field_Name": "Date Of Birth / Exact Age",
}
*/
const transformDataDiscoverySingleResult = result =>
    result.reduce((acc, val) => {
        acc[val.Key] = val.Value;
        return acc;
    }, {});

/* Convert:
  [ {"a": "b", "id": 1}, {"c": "d", "id": 2}]
  To
  { "1": {"a": "b", "id": 1}, "2": {"c": "d", "id": 2} }
*/
const keyFixedDataDiscoveryResult = fixedResult =>
    fixedResult.reduce((acc, val) => {
        acc[val.ID] = val;
        return acc;
    }, {});

// wants state = state.search
export const getBundlesFromState = state => {
    const { discoveryAllResults } = state;
    if (!discoveryAllResults || Object.keys(discoveryAllResults).length == 0) {
        return [];
    }
    const row = discoveryAllResults[Object.keys(discoveryAllResults)[0]];
    return getAllPossibleBundlesFromDataDiscoveryResultRow(row);
};
// Gets only the bundles that this row has
export const getActiveBundlesFromDataDiscoveryResultRow = resultRow =>
    Object.keys(resultRow)
        .filter(x => x.includes("Bundle") && x != "Bundle_Flag")
        .filter(x => resultRow[x] && resultRow[x] == "1")
        .sort();
// Gets ALL bundles that exist, not just the ones this row has - it's the only way for us to get the list of bundles since they're not normalized
export const getAllPossibleBundlesFromDataDiscoveryResultRow = resultRow =>
    Object.keys(resultRow)
        .filter(x => x.includes("Bundle") && x != "Bundle_Flag")
        .sort();

// --

export const expandWordVecs: string | Array<string> = (
    searchText: string,
    wordVecs: { [searchText: string]: Array<string> }
) => {
    if (wordVecs[searchText]) {
        // Any conceptual search is surrounded in quotes, which means 'whole word match only'
        return [searchText].concat(wordVecs[searchText].map(x => '"' + x + '"'));
    }
    return searchText;
};

export const expandModalWordVecs: string | Array<string> = (
    searchText: string,
    modalWordVecs: { [searchText: string]: Array<string> }
) => {
    if (modalWordVecs[searchText]) {
        // Any conceptual search is surrounded in quotes, which means 'whole word match only'
        return [searchText].concat(modalWordVecs[searchText].map(x => '"' + x + '"'));
    }
    return searchText;
};

/* Wants the full state */
export const isConceptualSearchTurnedOn = (fullState, isModal) =>
    isModal ? fullState.search.modalConceptualTurnedOn : fullState.search.conceptualTurnedOn;

export const getTreeSearch: string | Array<string> = createSelector(
    state => state.search.treeSearch,
    state => state.search.wordVecs,
    state => state.search.conceptualTurnedOn,
    (treeSearch, wordVecs, conceptualTurnedOn) => {
        if (!conceptualTurnedOn) {
            return treeSearch;
        }
        return expandWordVecs(treeSearch, wordVecs);
    }
);

export const getModalTreeSearch: string | Array<string> = createSelector(
    state => state.search.modalTreeSearch,
    state => state.search.modalWordVecs,
    state => state.search.modalConceptualTurnedOn,
    (modalTreeSearch, modalWordVecs, modalConceptualTurnedOn) => {
        if (!modalConceptualTurnedOn) {
            return modalTreeSearch;
        }
        return expandModalWordVecs(modalTreeSearch, modalWordVecs);
    }
);

// SearchObject --> string or array of strings
// If array of strings, we implictly join them with an "OR"
// If any string is surrounded in double quotes, it will be treated as a whole word only match
const _haystackMatchesSearchObject = (haystack, searchString) => {
    // eslint-disable-line no-underscore-dangle
    if (!haystack) {
        return false;
    }

    //let words = [searchString, "expanded1", "expanded2"];
    let words = [searchString];
    if (Array.isArray(searchString)) {
        words = searchString;
    }

    const wholeWordSearches = [];
    const substringSearches = [];

    for (let word of words) {
        const quoteRegex = /^"([^"]*)"$/;
        const matches = quoteRegex.exec(word);
        if (matches) {
            // Word was surrounded in quotes - use whole word match
            word = matches[1];
            wholeWordSearches.push(word);
        } else {
            substringSearches.push(word);
        }
    }

    for (const word of substringSearches) {
        if (haystack.toLowerCase().includes(word.toLowerCase())) {
            return true;
        }
    }
    if (wholeWordMatchSingularOrPlural(haystack, wholeWordSearches)) {
        return true;
    }
    return false;
};

import memoize from "../helpers/memoize";
export const haystackMatchesSearchObject = memoize(_haystackMatchesSearchObject);

// wholeWordMatchSingularOrPlural
// search for 'omen' inside "women above 30" -> fail
// search for 'women' inside "women above 30" -> pass
// search for 'woman' inside "women above 30" -> pass
const wholeWordMatchSingularOrPlural = (haystack, needles) => {
    if (!haystack || !needles || (Array.isArray(needles) && needles.length == 0)) {
        return false;
    }

    if (!Array.isArray(needles)) {
        needles = [needles];
    }

    let regex = "\\b(";
    regex += needles.map(x => escapeRegExp(singular(x))).join("|"); // Use singular() against both sides, so "women" matches "woman"
    regex += ")\\b";

    const singularizedHaystack = haystack
        .split(/\b/)
        .map(x => singular(x))
        .join(""); // Convert all words to singular form

    return new RegExp(regex, "i").test(singularizedHaystack);
};
export { wholeWordMatchSingularOrPlural }; // For unit testing only, shouldn't need to use this directly, use haystackMatchesSearchObject

const escapeRegExp = string => string.replace(/([.*+?^=!:${}()|\[\]\/\\])/g, "\\$1");

export default search;
