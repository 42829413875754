import update from "immutability-helper";
import { IUserAdminStore, UserAdminActions } from "../types/stores/userAdmin";

export const SET_IS_LOADING_USERS = "SET_IS_LOADING_USERS";
export const SET_IS_SAVING_USER = "SET_IS_SAVING_USER";
export const UPDATE_USER_ADMIN_SHOW_INTERNAL = "UPDATE_USER_ADMIN_SHOW_INTERNAL";
export const UPDATE_USER_ADMIN_COMPANYID_FILTER = "UPDATE_USER_ADMIN_COMPANYID_FILTER";
export const UPDATE_USER_LIST_FILTER = "UPDATE_USER_LIST_FILTER";
export const UPDATE_ROLE_LIST_FILTER = "UPDATE_ROLE_LIST_FILTER";
export const GET_USER_ROLES = "GET_USER_ROLES";

const initialState: IUserAdminStore = {
    isLoadingUsers: false,
    isSaving: false,
    showInternal: true,
    companyIdFilter: 0,
    roleListFilter: "All",
    userListFilter: [],
    userRoles: [],
};

function userAdmin(state = initialState, action: UserAdminActions): IUserAdminStore {
    switch (action.type) {
        case SET_IS_LOADING_USERS: {
            return update(state, {
                isLoadingUsers: { $set: action.isLoading },
            });
        }
        case SET_IS_SAVING_USER: {
            return update(state, {
                isSaving: { $set: action.isSaving },
            });
        }
        case UPDATE_USER_ADMIN_SHOW_INTERNAL: {
            return update(state, {
                showInternal: { $set: action.showInternal },
            });
        }
        case UPDATE_USER_ADMIN_COMPANYID_FILTER: {
            return update(state, {
                companyIdFilter: { $set: action.companyId },
            });
        }
        case UPDATE_USER_LIST_FILTER: {
            return update(state, {
                userListFilter: { $set: action.filter },
            });
        }
        case UPDATE_ROLE_LIST_FILTER: {
            return update(state, {
                roleListFilter: { $set: action.role },
            });
        }
        case GET_USER_ROLES: {
            return update(state, {
                userRoles: { $set: action.userRoles },
            });
        }
        default:
            return state;
    }
}
export default userAdmin;
